import { checkStatus, host } from '../api';
import { PageItem, Patchnote } from './_type';

import qs from 'qs';

export interface PatchnotePostParams extends Partial<Omit<Patchnote, 'id' | 'scheduledAt'>> {}

const users = {
  getPatchnote: (id: number): Promise<Patchnote> => {
    return fetch(`${host}/api/patchnotes/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getPatchnotes: ({ page }: { page: number }): Promise<PageItem<Patchnote>> => {
    return fetch(`${host}/api/patchnotes?${qs.stringify({ page })}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAllPatchnotes: (): Promise<Patchnote[]> => {
    return fetch(`${host}/api/patchnotes/no-filters`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  createPatchnote: (body: PatchnotePostParams): Promise<Patchnote> => {
    return fetch(`${host}/api/patchnotes`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updatePatchnote: ({ id, body }: { id: number; body: PatchnotePostParams }): Promise<Patchnote> => {
    return fetch(`${host}/api/patchnotes/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deletePatchnote: (id: number): Promise<void> => {
    return fetch(`${host}/api/patchnotes/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ hidden: true }),
    })
      .then(checkStatus)
      .then();
  },
};

export default users;
