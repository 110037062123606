import { default as Breadcrumb } from "./components/old/Breadcrumb";
import { default as Card } from "./components/old/Card";
import { default as Checkbox } from "./components/old/Checkbox";
import { default as ImportantNumber } from "./components/old/ImportantNumber";
import { default as Modal } from "./components/old/Modal";
import { default as ProgressBar } from "./components/old/ProgressBar";
import { default as Separator } from "./components/old/Separator";
import { default as SidePannel } from "./components/old/SidePannel";
import { default as SortMenu } from "./components/old/SortMenu";
import { default as SortMenuRow } from "./components/old/SortMenuRow";
import { default as TopBar } from "./components/old/TopBar";
//@ts-ignore
import { default as hexToHsl } from "hex-to-hsl";

// Tests dev
// @ts-ignore
import { default as React } from "react";
// @ts-ignore
import { default as ReactDOM } from "react-dom/client";
// @ts-ignore
import { default as App } from "./App";

import {
    DEFAULT_THEME as MANTINE_DEFAULT_THEME,
    MantineTheme,
    Tuple,
} from "@mantine/core";
// V2 Components
import { default as Avatar } from "./components/Avatar";
import { default as Button } from "./components/Button";
import { default as FileButton } from "./components/FileButton";
import { default as InputLabel } from "./components/InputLabel";
import { default as PasswordInput } from "./components/PasswordInput";
import { default as RichText } from "./components/RichText";
import { default as Select } from "./components/Select";
import { default as Switch } from "./components/Switch";
import { default as TextArea } from "./components/TextArea";
import { default as TextInput } from "./components/TextInput";

// Tiptap
import { default as Highlight } from "@tiptap/extension-highlight";
import { default as Link } from "@tiptap/extension-link";
import { default as SubScript } from "@tiptap/extension-subscript";
import { default as Superscript } from "@tiptap/extension-superscript";
import { default as TextAlign } from "@tiptap/extension-text-align";
import { default as Underline } from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import { default as StarterKit } from "@tiptap/starter-kit";

const Winylo = {
    Avatar,
    Button,
    FileButton,
    InputLabel,
    PasswordInput,
    Select,
    Switch,
    TextArea,
    TextInput,
    RichText,
    ////////////////////
    Card,
    Modal,
    TopBar,
    SortMenu,
    SortMenuRow,
    Checkbox,
    ProgressBar,
    ImportantNumber,
    SidePannel,
    Separator,
    Breadcrumb,
};

export default Winylo;

/**
 * Renvoie à partir d'une couleur hexadécimale un tableau de 10 variantes de couleurs au format hsl
 */
export function getMantineColors(colorHex: string): Tuple<string, 10> {
    let hsl = hexToHsl(colorHex);
    let offset = 3;
    if (hsl[2] + 24 > 100 || hsl[3] + 24 > 100) offset = (100 - hsl[2]) / 7;
    let colors = [hsl, hsl, hsl, hsl, hsl, hsl, hsl]
        .map((c, index) => [
            c[0],
            c[1] - offset * (index - 7),
            c[2] - offset * (index - 7),
        ])
        .concat(
            [hsl, hsl, hsl].map((c, index) => [
                c[0],
                c[1] - offset * (index + 1),
                c[2] - offset * (index + 1),
            ])
        );

    return colors.map((c) => `hsl(${c[0]}, ${c[1]}%, ${c[2]}%)`) as Tuple<
        string,
        10
    >;
}

export const WINYLO_THEME: MantineTheme = {
    ...MANTINE_DEFAULT_THEME,
    fontFamily: "Montserrat, sans-serif",
    colors: {
        ...MANTINE_DEFAULT_THEME.colors,
        blue: getMantineColors("#19323C"),
        red: getMantineColors("#EB5757"),
        green: getMantineColors("#00953B"),
        yellow: getMantineColors("#FFDF08"),
        gray: getMantineColors("#CBCBCB"),
        dark: getMantineColors("#323232"),
    },
    primaryColor: "blue",
};

export enum ControlsEnum {
    bold = "bold",
    italic = "italic",
    underline = "underline",
    strikethrough = "strikethrough",
    clearformatting = "clearformatting",
    highlight = "highlight",
    code = "code",
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
    blockquote = "blockquote",
    hr = "hr",
    bulletlist = "bulletlist",
    orderedlist = "orderedlist",
    subscript = "subscript",
    superscript = "superscript",
    link = "link",
    unlink = "unlink",
    alignleft = "alignleft",
    aligncenter = "aligncenter",
    alignjustify = "alignjustify",
    alignright = "alignright",
}

// Tiptap
export {
    Highlight,
    Link,
    StarterKit,
    SubScript,
    Superscript,
    TextAlign,
    Underline,
    useEditor,
};

/**
 * Décommenter uniquement pour tester les composants
 */
// const root = ReactDOM.createRoot(
//     document.getElementById("root") as HTMLElement
// );
// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );
