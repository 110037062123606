import React from "react";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";

import style from "../../css/old/TopBar.module.css";

interface Action {
    text: string;
    onClick: () => void;
}

interface Props {
    username?: string;
    userRole?: string;
    logout?: () => void;
    actions?: Action[];
    brandLogoSrc: string;
    brandLogoClick?: () => void;
}

export default function TopBar(props: Props) {
    const menu = useRef<any>(null);
    const menuButton = useRef<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (e: any) => {
        if (
            menu.current &&
            !menu.current.contains(e.target) &&
            menuButton.current &&
            !menuButton.current.contains(e.target)
        ) {
            toggleMenu();
        }
    };

    function toggleMenu() {
        setIsMenuOpen((before) => !before);
    }

    return (
        <>
            <div className={style.topbar}>
                <img
                    className={style.brandLogo}
                    src={props.brandLogoSrc}
                    alt="Logo"
                    onClick={props.brandLogoClick}
                />
                <div className={style.rightItems}>
                    <div className={style.userInfoContainer}>
                        <span className={style.userName}>{props.username}</span>
                        <span className={style.userRole}>{props.userRole}</span>
                    </div>
                    <div
                        className={style.collapseButton}
                        ref={menuButton}
                        onClick={toggleMenu}
                    >
                        <ReactSVG
                            src={"../../svg/user-circle.svg"}
                            className={classNames(style.icon, style.userCircle)}
                        />
                        <ReactSVG
                            src={"../../svg/caret-down.svg"}
                            className={classNames(style.icon, style.caretDown, {
                                [style.reverseCaretDown]: isMenuOpen === true,
                            })}
                        />
                    </div>
                    {isMenuOpen && (
                        <div className={style.menu} ref={menu}>
                            {props.actions?.map((action) => (
                                <p onClick={action.onClick}>{action.text}</p>
                            ))}
                            {/* <div className={style.separator}></div> */}
                            {props.logout && (
                                <p
                                    className={style.disconnect}
                                    onClick={props.logout}
                                >
                                    <ReactSVG src={"../../svg/power-off.svg"} />
                                    Déconnexion
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={style.fakeTopbar}></div>
        </>
    );
}
