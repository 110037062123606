import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './SortMenu.module.css';

interface Props {
  title: string;
  children: React.ReactNode;
  clear?: () => void;
}

export default function SortMenu({ title, children, clear }: Props) {
  return (
    <div className={style.sortMenu}>
      <div className={style.title}>
        <p>{title}</p>
        {clear !== undefined && (
          <FontAwesomeIcon icon={faEraser} onClick={clear} />
        )}
      </div>
      {children}
    </div>
  );
}
