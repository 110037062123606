import React, { useState, useEffect, useRef } from 'react';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { SortDown, SortUp, FilterLeft } from 'react-bootstrap-icons';

import style from './Table.module.css';

export interface Column {
  key: string;
  name: string;
  render?: (value: any, r: Row) => React.ReactElement | string;
  filter?: (value: any, filterState: string) => boolean;
  sort?: (a: Row, b: Row, direction: 'ASC' | 'DESC') => -1 | 0 | 1;
  style?: React.CSSProperties;
  filterSort?: boolean;
}

export interface Row {
  [key: string]: any;
  id: number;
}

interface Props {
  columns: Column[];
  rows: Row[];
  setQueryFilter: (value: React.SetStateAction<any>) => void;
  isLoading?: boolean;
}

interface Sort {
  column: string;
  by: 'ASC' | 'DESC';
}

export default function TableFilter({
  rows,
  columns,
  isLoading,
  setQueryFilter,
}: Props): React.ReactElement {
  // Tri
  const [filterState, setFilterState] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    let obj: { [key: string]: string } = {};
    let insideKeyToColumn: { [key: string]: Column } = {};

    columns.forEach((c) => {
      obj[c.key] = '';
      insideKeyToColumn[c.key] = c;
    });

    setFilterState(obj);
  }, [columns]);

  const inputTimeout = useRef<undefined | NodeJS.Timeout>(undefined);

  function handleFilterState(e: any) {
    let column = e.target.getAttribute('name');
    setFilterState((before) => {
      if (inputTimeout.current) {
        clearInterval(inputTimeout.current);
      }

      inputTimeout.current = setTimeout(
        () =>
          setQueryFilter((b: any) => ({
            ...b,
            [column]: { value: e.target.value },
          })),
        1000
      );

      return {
        ...before,
        [column]: e.target.value,
      };
    });
  }

  const [sortBy, setSortBy] = useState<Sort | undefined>(undefined);

  function SorterComponent({ column }: { column: string }): React.ReactElement {
    function changeSortBy() {
      setSortBy((before) => {
        if (before === undefined || before.column !== column) {
          return { column: column, by: 'ASC' };
        } else if (before.by === 'ASC') {
          return { column: column, by: 'DESC' };
        } else {
          return undefined;
        }
      });
    }

    if (sortBy === undefined)
      return (
        <FilterLeft
          size={25}
          style={{ marginLeft: '5px' }}
          className={style.hoverable}
          onClick={changeSortBy}
        />
      );

    if (sortBy.column !== column)
      return (
        <FilterLeft
          size={25}
          style={{ marginLeft: '5px' }}
          className={style.hoverable}
          onClick={changeSortBy}
        />
      );

    if (sortBy.by === 'ASC') {
      return (
        <SortUp
          size={25}
          style={{ marginLeft: '5px' }}
          className={style.hoverable}
          onClick={changeSortBy}
        />
      );
    } else {
      return (
        <SortDown
          size={25}
          style={{ marginLeft: '5px' }}
          className={style.hoverable}
          onClick={changeSortBy}
        />
      );
    }
  }

  return (
    <Table className={style.table} responsive>
      <thead>
        <tr>
          {columns.map((c) => (
            <th style={c.style} key={c.key} className={style.head}>
              {c.filterSort === undefined || c.filterSort === true ? (
                <>
                  <Form.Control
                    type="text"
                    placeholder={c.name}
                    value={filterState[c.key]}
                    name={c.key}
                    onChange={handleFilterState}
                  />
                  <SorterComponent column={c.key} />
                </>
              ) : (
                c.name
              )}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {isLoading === true ? (
          <Spinner animation="border" />
        ) : (
          rows.map((r) => (
            <tr key={r.id}>
              {columns.map((c) => (
                <td style={c.style} key={c.key}>
                  {c.render !== undefined ? c.render(r[c.key], r) : r[c.key]}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}
