import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import Toastify from 'toastify-js';
import style from './InputMultiple.module.css';
import * as Yup from 'yup';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function InputMultiple(props: Props) {
  const { inputProps, values, setValues, ...containerProps } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<string>('');

  function addValue() {
    if (value.length === 0 || !inputRef.current) return;
    if (!Yup.string().email().validateSync(value)) {
      Toastify({
        text: "L'adresse email entrée est invalide.",
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
      }).showToast();
      return;
    }

    setValues((values) => [...values, value]);
    setValue('');
  }

  function removeValue(index: number) {
    setValues((values) => values.filter((v, i) => i !== index));
  }

  function onClickRemove(event: React.MouseEvent, index: number) {
    event.stopPropagation();
    removeValue(index);
  }

  function onClickAdd(event: React.MouseEvent) {
    event.stopPropagation();
    addValue();
  }

  function onKeyUp(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      addValue();
    }
  }

  function onClickInContainer(event: React.MouseEvent) {
    if (inputRef.current) inputRef.current.focus();
  }

  return (
    <>
      <div
        {...containerProps}
        className={classNames(style.container, containerProps.className)}
        onClick={(e) => {
          onClickInContainer(e);
          if (containerProps.onClick) containerProps.onClick(e);
        }}
      >
        {values.map((value, index) => (
          <div className={style.choice} key={index}>
            {value}{' '}
            <span className={style.btnRemove} onClick={(e) => onClickRemove(e, index)}>
              X
            </span>
          </div>
        ))}
        <input
          {...inputProps}
          ref={inputRef}
          className={classNames(style.input, inputProps?.className)}
          onKeyUp={onKeyUp}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={addValue}
          autoComplete={'off'}
          type={'email'}
        />
        {value.length > 0 && <FontAwesomeIcon icon={faPlus} className={style.btnAdd} onClick={onClickAdd} />}
      </div>
    </>
  );
}
