import { useEffect, useState } from 'react';
import Utils from '../../../components/Utils';

import style from './Storage.module.css';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Winylo, { WINYLO_THEME } from '../../../react_components';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import api from '../../../utils/api';
import { TITLES } from '../../../utils/constantes';
import { BasketAddon, BasketContextType, useBasket } from '../../../utils/context/Basket';
import { isFilter, numberToPrice, stringToSize } from '../../../utils/utils';
import { MidApplication } from '../../../utils/api/_type';

export default function Storage() {
  const [applications, setApplications] = useState<
    {
      used_storage: number;
      application: MidApplication;
    }[]
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const [pieData, setPieData] = useState([
    { value: 0, name: 'Utilisé' },
    { value: 100, name: 'Libre' },
  ]);
  const navigate = useNavigate();
  const [, setBasket] = useBasket() as BasketContextType;

  // Récupération des données
  const { data: storage, isLoading } = useQuery('remainingStorage', api.companies.getStorage, {
    onSuccess: (data) => {
      setPieData([
        {
          value: data.used_storage,
          name: 'Utilisé',
        },
        { value: data.remaining_storage, name: 'Libre' },
      ]);
      filterApplications();
    },
  });

  const { data: storageExtension, isLoading: isLoadingStorageExtension } = useQuery('storageExtensions', api.addons.getStorages);

  function handleAddMoreSpare() {
    if (storageExtension === undefined) return;

    setBasket((before) => {
      if (before.filter((app) => app.application.id === storageExtension[0].id).length !== 0) {
        return before.map((item: any) =>
          item.application.id === storageExtension[0].id
            ? {
                ...item,
                application: {
                  ...item.application,
                  quantity: item.application.quantity + 1,
                },
              }
            : item
        );
      } else {
        return [
          ...before,
          {
            application: {
              id: storageExtension[0].id,
              name: storageExtension[0].name,
              fileUri: process.env.PUBLIC_URL + '/img/storage-space.svg',
              price: storageExtension[0].price,
              quantity: before.filter((item: any) => item.application.quantity !== undefined).length + 1,
            } as BasketAddon,
            addedDate: new Date(),
          },
        ];
      }
    });

    navigate('/payment/basket');
  }

  function filterApplications() {
    setApplications(storage?.applications.filter((application) => isFilter(application.application.name, searchValue)) || []);
  }

  useEffect(() => {
    document.title = TITLES.STORAGE;
  }, []);

  useEffect(() => {
    if (!storage) return;
    filterApplications();
  }, [searchValue]);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Gestion du stockage" width="100%" isLoading={isLoading || isLoadingStorageExtension}>
        <>
          <div className={style.tabTop}>
            <Winylo.TextInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              icon={<FontAwesomeIcon icon={faSearch} />}
              placeholder="Rechercher"
              width="200px"
            />

            <Utils.ImportantNumber word="Application" number={applications.length} style={{ marginLeft: '20px' }} />
          </div>

          <div className={classNames(style.twoColumns, 'onboarding-storagemanagement-2nd')}>
            <Utils.Table>
              <thead>
                <th>Nom de l'application</th>
                <th>Consommation</th>
              </thead>
              <tbody>
                {applications.map((application) => (
                  <tr key={application.application.id}>
                    <td className={style.firstColumn}>
                      {application.application.fileUri !== null ? (
                        <img src={application.application.fileUri} alt={'Logo de ' + application.application.name} />
                      ) : null}{' '}
                      {application.application.name}
                    </td>
                    <td className={style.consoDiv}>
                      <p>{stringToSize(application.used_storage)}</p>
                      <div className={style.sizeBackground}>
                        {storage && (
                          <div
                            className={style.sizeProgress}
                            style={{
                              width: (application.used_storage * 100) / storage.available_storage + '%',
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
                {storage?.applications.length === 0 && (
                  <tr>
                    <td colSpan={2}>Aucune des applications auxquelles vous avez souscrites ne nécéssitent du stockage.</td>
                  </tr>
                )}
              </tbody>
            </Utils.Table>
            <div className={style.rightPart}>
              <ResponsiveContainer width="49%" height={400}>
                <PieChart>
                  <Pie data={pieData} dataKey="value" outerRadius={100} innerRadius={95}>
                    <Label position="center">{`${Math.trunc((pieData[0].value * 100) / (storage?.available_storage || 1))} %`}</Label>
                    <Cell fill="#19323C" />
                    <Cell fill="#f2f4f9" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className={style.rightChartDiv}>
                <p className={style.sizeUsage}>{stringToSize(pieData[0].value)}</p>
                <p>
                  Utilisés sur un total de <span className={style.bold}>{stringToSize(storage?.available_storage || 0)}</span>
                </p>
                <p>
                  <span className={style.bold}>{stringToSize(pieData[1].value)}</span> libres
                </p>
                <Winylo.Button
                  id="onboarding-storagemanagement-next-3rd"
                  className={classNames(style.addMoreSpace, 'onboarding-storagemanagement-3rd')}
                  format="square"
                  color={'green'}
                  size="lg"
                  onClick={handleAddMoreSpare}
                >
                  Ajouter plus d'espace ({storageExtension && numberToPrice(storageExtension[0].price)})
                </Winylo.Button>
              </div>
            </div>
          </div>
        </>
      </Utils.Card>
    </Utils.Container>
  );
}
