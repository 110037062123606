import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { ROLE, User } from '../../../utils/api/_type';
import { UserContextType, useUser } from '../../../utils/context/User';
import Pagination from '../../../components/Pagination';
import TableFilter, { Column } from '../../../components/TableFilter';
import Utils from '../../../components/Utils';

interface CustomUser {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
  company: string;
  roles: ROLE[];
}

interface Filter {
  [key: string]: {
    value: string;
  };
  id: {
    value: string;
  };
  firstname: {
    value: string;
  };
  lastname: {
    value: string;
  };
  username: {
    value: string;
  };
  email: {
    value: string;
  };
  role: {
    value: string;
  };
  company: {
    value: string;
  };
}

export default function UserList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [user, setUser] = useUser() as UserContextType;

  const [users, setUsers] = useState<CustomUser[]>([]);
  const [page, setPage] = useState(1);

  const [hiddenFilter, setHiddenFilter] = useState(false);
  const [queryFilter, setQueryFilter] = useState<Filter>({
    id: {
      value: '',
    },
    firstname: {
      value: '',
    },
    lastname: {
      value: '',
    },
    username: {
      value: '',
    },
    email: {
      value: '',
    },
    role: {
      value: '',
    },
    company: {
      value: '',
    },
  });

  const { mutate: deleteUser } = useMutation(api.users.deleteUser, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries(['users', page, JSON.stringify(queryFilter)]);
    },
  });

  const { data: pageItem, isLoading } = useQuery(
    ['users', page, JSON.stringify(queryFilter), hiddenFilter],
    () => api.users.getUsers({ page, filters: queryFilter, hidden: hiddenFilter }),
    {
      onSuccess: (data) => {
        setUsers(
          data.items.map((u) => ({
            id: u.id,
            firstname: u.firstname,
            lastname: u.lastname,
            email: u.email,
            username: u.username,
            company: u.company !== null ? u.company.name : '',
            roles: u.roles,
          }))
        );
      },
    }
  );

  const { mutate: toggleAdmin } = useMutation(api.users.toggleAdmin, {
    onSuccess: (data) => {
      queryClient.setQueryData<User[] | undefined>('users_company', (before) =>
        before === undefined ? undefined : before.map((u) => (u.id === data.id ? data : u))
      );
    },
  });

  const { mutate: getJWT } = useMutation(api.users.getJWT, {
    onSuccess: (res) => {
      localStorage.setItem('jwt', res.jwt);

      api.loginApi.getMe().then((data) => {
        localStorage.setItem('jwt', data.jwt);
        setUser(data.user);
      });
    },
  });

  const [columns] = useState<Column[]>([
    { key: 'id', name: 'ID' },
    { key: 'firstname', name: 'Prénom' },
    { key: 'lastname', name: 'Nom' },
    { key: 'email', name: 'Email' },
    { key: 'username', name: "Nom d'utilisateur" },
    { key: 'company', name: 'Entreprise' },
    {
      key: 'roles',
      name: 'Administrateur',
      filterSort: false,
      render: (val, r) => {
        return <Form.Check disabled={r.id === user?.id} checked={r.roles.includes(ROLE.ROLE_ADMIN)} onClick={() => toggleAdmin(r.id)} />;
      },
    },
    {
      key: 'roles',
      name: 'Connexion',
      filterSort: false,
      render: (val, r) => {
        return (
          <Button onClick={() => getJWT(r.id)} variant="danger">
            Connecter
          </Button>
        );
      },
    },
    {
      key: 'edit',
      name: 'Modifier',
      style: { width: '10px' },
      render: (val, r) => {
        return (
          <>
            <Button onClick={() => navigate('/a/user/edit/' + r.id)}>Modifier</Button>
            <Button onClick={() => setUserToDelete(r as User)} variant="danger">
              Supprimer
            </Button>
          </>
        );
      },
      filterSort: false,
    },
  ]);

  // Gestion du tri par type
  const [typeFilter, setTypeFilter] = useState('');

  function handleChangeTypeFilter(e: any) {
    setTypeFilter(e.target.value);
    if (e.target.value === 'hidden') {
      setHiddenFilter(true);
      setQueryFilter((before) => ({
        ...before,
        role: { value: '' },
      }));
    } else {
      setHiddenFilter(false);
      setQueryFilter((before) => ({
        ...before,
        role: { value: e.target.value },
      }));
    }
  }

  const [userToDelete, setUserToDelete] = useState<User | undefined>(undefined);

  return (
    <>
      <h1 className="center">Liste des utilisateurs</h1>

      <Form.Group
        style={{
          display: 'bloc',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '30%',
          minWidth: '200px',
        }}
      >
        <Form.Label>Filtrer par type d'utilisateur</Form.Label>
        <Form.Control as="select" value={typeFilter} onChange={handleChangeTypeFilter}>
          <option value="">Pas de filtre</option>
          <option value="hidden">Utilisateurs supprimés</option>
          <option value="ROLE_ADMIN">Administrateur</option>
          <option value="ROLE_COMPANY_ADMIN">Administrateur d'entreprise</option>
          <option value="ROLE_CONSULTANT">Consultant</option>
        </Form.Control>
      </Form.Group>

      <p style={{ textAlign: 'center' }}>Nombre d'utilisateurs: {pageItem?.pagination.totalCount}</p>

      <Pagination page={page} setPage={setPage} pagination={pageItem?.pagination} />
      <TableFilter columns={columns} rows={users || []} isLoading={isLoading || pageItem === undefined} setQueryFilter={setQueryFilter} />
      <Pagination page={page} setPage={setPage} pagination={pageItem?.pagination} />
      {userToDelete && (
        <Utils.Modal title={"Supprimer l'utilisateur"} isOpen={true} onClose={() => setUserToDelete(undefined)}>
          <>
            Voulez-vous vraiment supprimer l'utilisateur{' '}
            <b>
              {userToDelete.firstname} {userToDelete.lastname}
            </b>
            ?
          </>
          <div style={{ display: 'flex' }}>
            <Utils.Button fullWidth={false} format="square" onClick={() => setUserToDelete(undefined)}>
              Annuler
            </Utils.Button>
            <Utils.Button
              fullWidth={false}
              style={{ marginLeft: '.625rem' }}
              variant="red"
              format="square"
              onClick={() => {
                deleteUser(userToDelete.id);
                setUserToDelete(undefined);
              }}
            >
              Supprimer
            </Utils.Button>
          </div>
        </Utils.Modal>
      )}
    </>
  );
}
