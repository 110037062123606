import classNames from "classnames";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";

import style from "../../css/old/SortMenuRow.module.css";

interface Props {
    title: string;
    children: React.ReactNode;
    open?: boolean;
}

export default function SortMenuRow({ title, children, open }: Props) {
    const [isOpen, setIsOpen] = useState(open || false);

    return (
        <div className={classNames(style.row, { [style.open]: isOpen })}>
            <div className={style.title} onClick={() => setIsOpen((b) => !b)}>
                <p>{title}</p>
                {isOpen ? (
                    <ReactSVG src={"../../svg/minus.svg"} title="Réduire" />
                ) : (
                    <ReactSVG src={"../../svg/plus.svg"} title="Ouvrir" />
                )}
            </div>
            <div className={style.content}>{children}</div>
        </div>
    );
}
