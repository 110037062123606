import { checkStatus, host } from '../api';

interface SendBugInterface {
  userId?: number;
  message: string;
}

const bugs = {
  sendBug: (body: SendBugInterface): Promise<string> => {
    return fetch(`${host}/api/issue`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        origin: window.location.href,
        application: 'Core',
        createdBy: body.userId,
        description: body.message,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default bugs;
