import QueryString from 'qs';
import { FILTER_OPTION } from '../../components/Utils/Sort/Sort';
import { checkStatus, host } from '../api';
import { TrialRequest, TRIAL_REQUEST_STATUS, PageItem } from './_type';

const trialrequest = {
  addTrialRequest: (body: { comment: string; application: number }) => {
    return fetch(`${host}/api/trialrequests`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getTrialRequest: ({
    page,
    filters,
  }: {
    page: number;
    filters?: {
      global: string;
      applicationName: {
        value: string[];
        sort: FILTER_OPTION;
      };
      status: {
        value: string[];
        sort: FILTER_OPTION;
      };
      createdBy: {
        value: string;
        sort: FILTER_OPTION;
      };
      createdAt: {
        value: string;
        sort: FILTER_OPTION;
      };
    };
  }): Promise<PageItem<TrialRequest>> => {
    return fetch(
      `${host}/api/trialrequests?${QueryString.stringify({
        page,
        ...filters,
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        },
      }
    )
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateTrialRequest: ({
    id,
    body,
  }: {
    id: number;
    body: { status: TRIAL_REQUEST_STATUS; response: string };
  }): Promise<TrialRequest> => {
    return fetch(`${host}/api/trialrequests/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default trialrequest;
