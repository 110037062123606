import React from 'react';
import { CompanyProduct, MidApplication, PRODUCT_STATUS } from '../../../utils/api/_type';
import { GridMode } from '../utils/types';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../../components/Utils';
import Winylo from '../../../react_components';
import { isAdmin, numberToPrice } from '../../../utils/utils';
import { UserContextType, useUser } from '../../../utils/context/User';
import classNames from 'classnames';
import { BasketContextType, useBasket } from '../../../utils/context/Basket';
import ConditionalPortal from './ConditionalPortal';

import style from '../css/ApplicationItem.module.css';

interface ApplicationItemProps {
  application: MidApplication;
  gridMode: GridMode;
  companyApplication: CompanyProduct | undefined;
  handleTryApplication: (application: MidApplication) => void;
  toggleAppInBasket: (application: MidApplication) => void;
  setSelectedApplication: React.Dispatch<React.SetStateAction<MidApplication | undefined>>;
}

export default function ApplicationItem({
  application,
  gridMode,
  companyApplication,
  handleTryApplication,
  toggleAppInBasket,
  setSelectedApplication,
}: ApplicationItemProps) {
  const trialEndingDateGridRef = React.createRef<HTMLDivElement>();
  const trialEndingDateRowRef = React.createRef<HTMLDivElement>();

  const [user] = useUser() as UserContextType;
  const [basket] = useBasket() as BasketContextType;

  /**
   * Transforme la date de fin d'essai en un texte lisible et coloré selon le nombre de jours restants
   * @param inputDate
   * @returns string
   */
  function parseEndingDate(inputDate: string | null | undefined) {
    if (inputDate === undefined || inputDate === null) return '';

    const endDate = new Date(inputDate);
    const today = new Date();

    const difference = endDate.getTime() - today.getTime();
    const differenceDay = Math.trunc(difference / (1000 * 60 * 60 * 24));

    const color = 1 <= differenceDay && differenceDay <= 4 ? 'red' : 5 <= differenceDay && differenceDay <= 10 ? 'yellow' : 'green';

    return (
      <span className={classNames(style.tryTime)} style={{ color: `var(--${color})` }}>
        <FontAwesomeIcon icon={faHourglassHalf} />
        {differenceDay + ` jour${differenceDay > 1 ? 's' : ''} ${gridMode === GridMode.ROW ? 'restants' : ''}`}
      </span>
    );
  }

  return (
    <>
      <div className={style.container}>
        {application?.fileUri ? <Image className={style.appLogo} src={application.fileUri} alt="Logo de l'app" /> : null}
        <div className={style.appContent}>
          <div className={style.appContentTop}>
            <span className={style.appName}>
              {application.name} {application.isBeta && <span className={style.beta}>VERSION BETA</span>}
              <div ref={trialEndingDateRowRef} className={style.trialEndingDateRow} />
            </span>
            {isAdmin(user) && (
              <span className={style.appPrice}>{application.price !== null ? numberToPrice(application.price) + ' HT / mois' : null}</span>
            )}
            {application.urlIos !== null ||
              (application.urlAndroid !== null && (
                <FontAwesomeIcon icon={faEllipsisV} className={style.dots} onClick={(e) => {} /*handleOpenModal(e, app)*/} />
              ))}
          </div>

          <div className={gridMode === GridMode.GRID ? style.gridContent : style.rowContent}>
            <div>
              <div className={style.appDescription}>{application.description}</div>

              <Utils.LinkButton
                onClick={() => {
                  setSelectedApplication(application);
                }}
                className={style.appMoreInfo}
              >
                Plus d'infos
              </Utils.LinkButton>
            </div>

            <div className={classNames(style.buttonsContainer, gridMode === GridMode.ROW && style.buttonsRow)}>
              {isAdmin(user) && companyApplication && companyApplication.active && companyApplication?.status !== PRODUCT_STATUS.TRIAL && (
                <div className={classNames(style.subscribed, gridMode === GridMode.ROW && style.fullWidth)}>
                  {/* Code svg de check.svg car le loader de svg marche pas et pas le temps pour le moment */}
                  <svg
                    style={{ width: '1.25rem', height: '1.25rem', marginRight: '4px' }}
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.41159 0H8.64066C8.53258 0 8.43001 0.0489244 8.36384 0.13264L3.81659 5.81114L1.63616 3.08768C1.60318 3.04639 1.56114 3.01301 1.51319 2.99004C1.46525 2.96707 1.41264 2.9551 1.35934 2.95504H0.58841C0.514516 2.95504 0.473709 3.03875 0.518928 3.09529L3.53976 6.8679C3.68093 7.04403 3.95225 7.04403 4.09452 6.8679L9.48107 0.139163C9.52629 0.0837152 9.48548 0 9.41159 0Z"
                      fill="var(--primary-color)"
                    />
                  </svg>
                  Abonné
                </div>
              )}

              {!user || isAdmin(user) ? (
                !application.comingSoon &&
                (!companyApplication || !companyApplication?.active || companyApplication?.status === PRODUCT_STATUS.TRIAL) ? (
                  basket.map((basketItem) => basketItem.application.id).includes(application.id) ? (
                    <Winylo.Button
                      format="square"
                      fullWidth={gridMode !== GridMode.GRID}
                      onClick={() => toggleAppInBasket(application)}
                      color="green"
                      size="md"
                    >
                      Retirer du panier
                    </Winylo.Button>
                  ) : (
                    <Winylo.Button
                      className="onboarding-discovercategories-3rd"
                      format="square"
                      fullWidth={gridMode !== GridMode.GRID}
                      onClick={() => toggleAppInBasket(application)}
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: '14px',
                        fontWeight: 700,
                      }}
                      color="green"
                      size="md"
                    >
                      Ajouter au panier
                    </Winylo.Button>
                  )
                ) : null
              ) : null}

              {!!companyApplication?.active ? (
                <Winylo.Button
                  format="square"
                  fullWidth={gridMode !== GridMode.GRID}
                  onClick={() => window.open(application.urlWeb)}
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    fontWeight: 700,
                  }}
                  size="md"
                >
                  Ouvrir
                </Winylo.Button>
              ) : companyApplication?.status !== PRODUCT_STATUS.TRIAL && application.trialDuration !== null ? (
                <Winylo.Button
                  format="square"
                  fullWidth={gridMode !== GridMode.GRID}
                  onClick={() => handleTryApplication(application)}
                  className={style.trialButton}
                  size="md"
                >
                  Tester l'application
                </Winylo.Button>
              ) : null}

              <div ref={trialEndingDateGridRef} />
            </div>
          </div>
        </div>
      </div>

      {/* Affichage du temps restant et changement de la localisation de la div selon l'affichage */}
      {companyApplication?.active && (
        <ConditionalPortal condition={gridMode === GridMode.GRID} refA={trialEndingDateGridRef} refB={trialEndingDateRowRef}>
          <span className={style.trialTimeIndicator}>
            {parseEndingDate(
              companyApplication?.status === PRODUCT_STATUS.TRIAL
                ? companyApplication?.trialEndedAt
                : companyApplication.status === PRODUCT_STATUS.SUBSCRIBED
                ? companyApplication.subscriptionEnd
                : null
            )}
          </span>
        </ConditionalPortal>
      )}
    </>
  );
}
