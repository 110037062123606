import React from "react";
import * as Mantine from "@mantine/core";
import { WINYLO_THEME } from "..";

interface ITextAreaProps extends Mantine.TextareaProps {
    txtAreaRef?: React.Ref<HTMLTextAreaElement>;
    color?: Mantine.DefaultMantineColor;
    format?: "round" | "square";
    customColor?: Record<"grape", Mantine.Tuple<string, 10>>;
}

export default function TextArea({
    txtAreaRef,
    color = "blue",
    format = "square",
    customColor,
    ...props
}: ITextAreaProps) {
    return (
        <Mantine.MantineProvider
            theme={{
                ...WINYLO_THEME,
                colors: {
                    ...WINYLO_THEME.colors,
                    ...customColor,
                },
                primaryColor: color,
            }}
        >
            <Mantine.Textarea
                ref={txtAreaRef}
                radius={format === "round" ? "2.5rem" : "0.25rem"}
                {...props}
            />
        </Mantine.MantineProvider>
    );
}
