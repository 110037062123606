import React from "react";
import * as Mantine from "@mantine/core";
import { WINYLO_THEME } from "..";

interface ISwitchProps extends Mantine.SwitchProps {
  switchRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  color?: Mantine.DefaultMantineColor | "custom";
  format?: "round" | "square";
  customColor?: Record<"custom", Mantine.Tuple<string, 10>>;
}

export default function Switch({
  switchRef,
  color = "blue",
  format = "round",
  customColor,
  children,
  ...props
}: ISwitchProps) {
  return (
    <Mantine.MantineProvider
      theme={{
        ...WINYLO_THEME,
        colors: {
          ...WINYLO_THEME.colors,
          ...customColor,
        },
      }}
    >
      <Mantine.Switch
        ref={switchRef}
        color={color}
        radius={format === "round" ? "xl" : "xs"}
        {...props}
      />
    </Mantine.MantineProvider>
  );
}
