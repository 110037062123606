import style from './Radio.module.css';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  click?: React.MouseEventHandler<HTMLLabelElement>;
}

export default function Radio(props: Props) {
  return (
    <label className={style.radio} onClick={props.click}>
      <input type="radio" {...props} />
      {props.label}
    </label>
  );
}
