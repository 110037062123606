import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import icons from '../../../utils/icons';

import style from './StatusModal.module.css';
import { ReactElement } from 'react';

interface Props {
  title: string;
  content: ReactElement;
  check: boolean;
  isOpen: boolean;
  onClose: () => void;
  errorCode?: number;
}

export default function StatusModal(props: Props) {
  return (
    <Modal
      title={props.title}
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      closable
    >
      <FontAwesomeIcon
        icon={props.check ? faCheckCircle : icons.v6_faXmarkCircle}
        className={classNames(style.icon, props.check ? style.checkmark : style.xmark)}
      />

      <div className={style.submitText}>{props.content}</div>
      {props.errorCode && <p className={style.submitText}>{`Code d'erreur : ${props.errorCode}`}</p>}
    </Modal>
  );
}
