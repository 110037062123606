import React from 'react';
import style from './InfoText.module.css';

interface Props {
  text: string | number;
  description: string | number;
}

export default function InfoText({ text, description }: Props) {
  return (
    <div className={style.infoText}>
      <p className={style.infoTextTitle}>{text}</p>
      <p className={style.infoTextSub}>{description}</p>
    </div>
  );
}

export function InfoTextContainer({ children }: { children: React.ReactNode }) {
  return <div className={style.infoTextContainer}>{children}</div>;
}
