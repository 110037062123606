import { checkStatus, host } from '../api';
import { CompanyProduct } from './_type';

const companyApplications = {
  updateCompanyApplication: ({
    id,
    idCompany,
    body,
  }: {
    id: number;
    idCompany: number;
    body: {
      trialEndedAt?: string;
      status?: CompanyProduct['status'];
      internalReference?: string;
    };
  }): Promise<CompanyProduct> => {
    return fetch(`${host}/api/company-application/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateCompanyApplicationInternalReference: ({
    body,
  }: {
    body: {
      productId: number;
      internalReference: string;
    };
  }): Promise<CompanyProduct> => {
    return fetch(`${host}/api/company-application/internalReference`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default companyApplications;
