import React from "react";
import * as Mantine from "@mantine/core";
import { WINYLO_THEME } from "..";

interface ISelectItemProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    value: string;
    description?: string;
    color?: Mantine.DefaultMantineColor;
    selected: boolean;
}

const SelectItem = React.forwardRef<HTMLDivElement, ISelectItemProps>(
    (
        {
            label,
            value,
            description,
            color,
            selected,
            ...others
        }: ISelectItemProps,
        ref
    ) => (
        <div ref={ref} {...others}>
            <Mantine.Group noWrap>
                <div>
                    <Mantine.Text
                        weight={500}
                        size="md"
                        style={{
                            color: selected
                                ? WINYLO_THEME.colors.gray[0]
                                : WINYLO_THEME.colors.dark[6],
                        }}
                    >
                        {label}
                    </Mantine.Text>
                    <Mantine.Text
                        size="sm"
                        style={{
                            color: selected
                                ? WINYLO_THEME.colors.gray[0]
                                : WINYLO_THEME.colors.dark[6],
                        }}
                        opacity={0.65}
                    >
                        {description}
                    </Mantine.Text>
                </div>
            </Mantine.Group>
        </div>
    )
);

interface ISelectProps extends Mantine.SelectProps {
    selectRef?: React.Ref<HTMLInputElement>;
    color?: Mantine.DefaultMantineColor;
    format?: "round" | "square";
    customColor?: Record<"grape", Mantine.Tuple<string, 10>>;
}

export default function Select({
    selectRef,
    color = "blue",
    format = "square",
    customColor,
    ...props
}: ISelectProps) {
    return (
        <Mantine.MantineProvider
            theme={{
                ...WINYLO_THEME,
                colors: {
                    ...WINYLO_THEME.colors,
                    ...customColor,
                },
                primaryColor: color,
            }}
        >
            <Mantine.Select
                ref={selectRef}
                radius={format === "round" ? "2.5rem" : "0.25rem"}
                itemComponent={SelectItem}
                {...props}
            />
        </Mantine.MantineProvider>
    );
}
