import React from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from '../../../utils/api';
import Winylo from '../../../react_components';

import { Column } from '../../../components/Table';

import TableComponent from '../../../components/Table';

export function ApplicationsList() {
  const navigate = useNavigate();

  // Récupération des données
  const { data: applications, isLoading } = useQuery('applications', api.applications.getAllApplications);

  const columns: Column[] = [
    { key: 'name', name: 'Nom' },
    { key: 'urlApi', name: 'Url API' },
    { key: 'urlAndroid', name: 'Url Android' },
    { key: 'urlIos', name: 'Url iOS' },
    { key: 'urlWeb', name: 'Url WEB' },
    {
      key: 'edit',
      name: 'Modifier',
      style: { width: '10px' },
      render: (val, r) => {
        return <Button onClick={() => navigate('/a/app/' + r.id)}>Modifier</Button>;
      },
      filterSort: false,
    },
  ];

  return (
    <>
      <Container>
        <h1>
          Liste des <span style={{ color: 'blue' }}>applications</span>
        </h1>
        <Winylo.Button onClick={() => navigate('/a/app/create')}>Créer une nouvelle application</Winylo.Button>
        <br />
      </Container>

      {isLoading || applications === undefined ? <Spinner animation="border" /> : <TableComponent columns={columns} rows={applications} />}
    </>
  );
}
