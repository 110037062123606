import { host, checkStatus, checkStatusQuiet } from '../api';
import {
  Application,
  CompanyProduct,
  CreateApplication,
  MidApplication,
} from './_type';

const applications = {
  createApplication: (body: CreateApplication): Promise<Application> => {
    return fetch(`${host}/api/applications`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAllApplications: (): Promise<MidApplication[]> => {
    return fetch(`${host}/api/applications`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getOneApplication: (id: number): Promise<Application> => {
    return fetch(`${host}/api/applications/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateApplication: ({
    id,
    body,
  }: {
    id: number;
    body: CreateApplication;
  }): Promise<Application> => {
    return fetch(`${host}/api/applications/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  tryApplication: (idApp: number): Promise<CompanyProduct> => {
    return fetch(`${host}/api/applications/${idApp}/try`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAppByClientId: (clientId: string): Promise<Application> => {
    return fetch(`${host}/api/applications/${clientId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatusQuiet)
      .then((res) => res.json());
  },
  addApplicationImage: ({
    id,
    file,
  }: {
    id: number;
    file: File;
  }): Promise<Application> => {
    let body = new FormData();
    body.append('file', file);

    return fetch(`${host}/api/applications/${id}/image`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: body,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  removeApplicationImage: (id: number): Promise<any> => {
    return fetch(`${host}/api/applications/${id}/image`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default applications;
