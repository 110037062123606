import React from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from '../../../utils/api';

import TableComponent, { Column } from '../../../components/Table';

export default function CompanyList() {
  const navigate = useNavigate();

  // Récupération des données
  const { data: companies, isLoading } = useQuery('companies', api.companies.getAllCompanies);

  const columns: Column[] = [
    { key: 'id', name: 'ID' },
    { key: 'name', name: 'Nom' },
    { key: 'invoicingEmail', name: 'Email' },
    { key: 'phone', name: 'Téléphone' },
    {
      key: 'edit',
      name: 'Modifier',
      style: { width: '10px' },
      render: (val, r) => {
        return <Button onClick={() => navigate('/a/company/edit/' + r.id)}>Modifier</Button>;
      },
      filterSort: false,
    },
  ];

  return (
    <>
      <Container>
        <h1>Liste des entreprises</h1>
        <Button onClick={() => navigate('/a/company/create')}>Créer une nouvelle entreprise</Button>
        <p>Nombre d'entreprises: {companies?.length}</p>
      </Container>

      {isLoading || companies === undefined ? <Spinner animation="border" /> : <TableComponent columns={columns} rows={companies} />}
    </>
  );
}
