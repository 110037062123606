import React from 'react';

import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import { isBrowserBad } from '../utils/utils';

interface Props {
  children: any;
  returnEmpty?: boolean;
}

export default function IsBrowserOk({
  children,
  returnEmpty,
}: Props): React.ReactElement {
  if (isBrowserBad()) {
    if (returnEmpty) {
      return <></>;
    }
    return (
      <Container>
        <Alert variant="danger">
          Cette fonction n'est pas supportée sur votre navigateur, vous pouvez
          passer à{' '}
          <a href="https://www.google.com/intl/fr_fr/chrome/">Chrome</a>,{' '}
          <a href="https://www.mozilla.org/fr/firefox/new/">Firefox</a>, ou{' '}
          <a href="https://www.microsoft.com/fr-fr/edge">Edge</a>
        </Alert>
      </Container>
    );
  } else {
    return children;
  }
}
