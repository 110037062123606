import classNames from 'classnames';
import { useEffect, useState } from 'react';

import style from './TabsContainer.module.css';

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

export default function TabsContainer({ children }: Props) {
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    if (Array.isArray(children)) {
      setSelectedTab(children[0].props.title);
    } else {
      setSelectedTab(children.props.title);
    }
  }, [children]);

  return (
    <div className={style.tabContainer}>
      <div className={style.top}>
        {Array.isArray(children) ? (
          children.map((child) => (
            <div
              className={classNames(style.tab, {
                [style.selectedTab]: selectedTab === child.props.title,
              })}
              onClick={() => setSelectedTab(child.props.title)}
            >
              <p>{child.props.title}</p>
            </div>
          ))
        ) : (
          <div
            className={classNames(style.tab, {
              [style.selectedTab]: selectedTab === children.props.title,
            })}
          >
            <p>{children.props.title}</p>
          </div>
        )}
      </div>

      <div className={style.childrenContainer}>
        {Array.isArray(children)
          ? children.find((child) => child.props.title === selectedTab)
          : children}
      </div>
    </div>
  );
}
