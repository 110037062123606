// @ts-nocheck
import Bowser from 'bowser';
import { useState } from 'react';
import { ShepherdOptionsWithType, Tour } from 'react-shepherd';
import { CompanyProduct, CompanyProductApplication, ROLE, User } from './api/_type';
import { UserContextType } from './context/User';

function zero(int: number): string {
  return int < 10 ? '0' + int : '' + int;
}

export function dateToString(d: string | Date): string {
  if (d === '') return '';

  let date: Date;

  if (d instanceof Date) {
    date = d;
  } else {
    date = new Date(d);
  }

  return `${zero(date.getDate())}/${zero(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function isFilter(baseString: string, filter: string) {
  return baseString.toLowerCase().trim().includes(filter.toLowerCase().trim());
}

export function parseHash() {
  return new URLSearchParams(window.location.hash.substr(1));
}

export function parseQuery() {
  return new URLSearchParams(window.location.search);
}

export function focusElement(element: React.MutableRefObject<HTMLElement | null>) {
  setTimeout(() => {
    element.current?.focus();
  }, 100);
}

export function minimumLoadingTime<T>(func: Promise<T>, time: number = 700): Promise<T> {
  let current = Date.now();

  return new Promise((resolve, reject) => {
    func
      .then((data: any) => {
        if (Date.now() - current <= time) {
          setTimeout(() => {
            resolve(data);
          }, time - (Date.now() - current));
        } else {
          resolve(data);
        }
      })
      .catch(reject);
  });
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      logger(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      logger(error);
    }
  };
  return [storedValue, setValue] as const;
}

export function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      logger(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      logger(error);
    }
  };
  return [storedValue, setValue] as const;
}

export function compare<T>(a: T | undefined, b: T | undefined): -1 | 0 | 1 {
  if (a === undefined) return -1;
  if (b === undefined) return 1;

  // Comparaison sans prendre en compte les maj/min
  if (typeof a === 'string' && typeof b === 'string') {
    let otherA = a.toLowerCase().trim();
    let otherB = b.toLowerCase().trim();

    if (otherA > otherB) {
      return 1;
    } else if (otherA === otherB) {
      return 0;
    } else {
      return -1;
    }
  }

  if (a > b) {
    return 1;
  } else if (a === b) {
    return 0;
  } else {
    return -1;
  }
}

export function copyStringToClipboard(str: string) {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(str);
  } else {
    var el = document.createElement('textarea');

    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export const MY_DOMAIN = window.location.protocol + '//' + window.location.hostname + (window.location.port !== '' ? `:${window.location.port}` : '');

export function useFormState<T>(initialValue: T) {
  const [internalState, setInternalState] = useState(initialValue);

  function handleInput(e: any) {
    if (e.target.getAttribute('type') === 'file') {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute('name') || '']: e.target.files,
        };
      });
    } else if (e.target.getAttribute('type') === 'checkbox') {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute('name') || '']: e.target.checked,
        };
      });
    } else {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute('name') || '']: e.target.value,
        };
      });
    }
  }

  function updateState(newValue?: T | ((val: T) => T)) {
    if (newValue === undefined) {
      setInternalState(initialValue);
    } else {
      const valueToStore = newValue instanceof Function ? newValue(internalState) : newValue;
      setInternalState(valueToStore);
    }
  }

  return [internalState, handleInput, updateState] as const;
}

export function useHashState<T extends { [key: string]: string }>(initialValue: T): readonly [T, (value: T | ((val: T) => T)) => void] {
  const [internalState, setInternalState] = useState<T>((): T => {
    // Si il n'y a rien dans l'URL
    if (window.location.hash === '') {
      // On prépare un hash avec les valeurs par défaut
      let searchParam = new URLSearchParams();

      Object.keys(initialValue).forEach((k) => {
        searchParam.set(k, initialValue[k]);
      });

      window.location.hash = searchParam.toString().replace('?', '#');

      return initialValue;
    } else {
      // Sinon on prend un searchParam avec les vrai valeurs
      let searchParam = new URLSearchParams(window.location.hash.replace('#', '?'));

      let objetKeys = Object.keys(initialValue);

      // Suppression des valeurs inutiles
      for (var key of searchParam.keys()) {
        if (!objetKeys.includes(key)) searchParam.delete(key);
      }

      let returnedObj: { [key: string]: string } = {};

      objetKeys.forEach((k) => {
        returnedObj[k] = searchParam.get(k) || initialValue[k];
      });

      objetKeys.forEach((k) => {
        searchParam.set(k, returnedObj[k]);
      });

      window.location.hash = searchParam.toString().replace('?', '#');

      return returnedObj as T;
    }
  });

  const updateState = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(internalState) : value;
      setInternalState(valueToStore);
      let searchParam = new URLSearchParams();

      Object.keys(valueToStore).forEach((k) => {
        searchParam.set(k, valueToStore[k]);
      });

      window.location.hash = searchParam.toString().replace('?', '#');
    } catch (error) {
      logger(error);
    }
  };

  return [internalState, updateState] as const;
}

export function numberToPrice(num: number) {
  return `${Math.round(num) / 100} €`;
}

export function stringToSize(size: string | number) {
  let initial;

  if (typeof size === 'string') {
    initial = parseInt(size);
  } else {
    initial = size;
  }

  let reste = initial;

  let extensions = ['octets', 'Ko', 'Mo', 'Go', 'To'];
  let currentExtension = 0;

  while (reste >= 1000) {
    currentExtension++;
    reste = Math.trunc(reste / 10) / 100;
  }

  return reste + ' ' + extensions[currentExtension];
}

export function logger(...content: any) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...content);
  }
}

export function isBrowserBad() {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return browser.getBrowserName() === 'Internet Explorer';
}

export function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

export function getStripeStatus(status: string) {
  switch (status) {
    case 'open':
      return 'Impayée';
    case 'void':
      return 'Indisponible';
    case 'draft':
      return 'En attente';
    case 'paid':
      return 'Réglée';
    case 'uncollectible':
      return 'Impayée';
    default:
      return 'Inconnu';
  }
}

/**
 * Format le tableau des roles d'un utilisateur dans son role le plus haut
 * @param roles
 * @returns
 */
export function formatUserRoleToString(user: User) {
  let res = user.company ? user.company.name + ' - ' : '';

  if (user.roles.includes(ROLE.ROLE_ADMIN)) {
    return res + 'Admin. Global';
  }

  if (user.roles.includes(ROLE.ROLE_COMPANY_ADMIN)) {
    return res + 'Admin.';
  }

  if (user.roles.includes(ROLE.ROLE_COMPANY_ACCOUNTANT)) {
    return res + 'Compta.';
  }

  if (user.roles.includes(ROLE.ROLE_CONSULTANT)) {
    return res + 'Consultant';
  }

  return res + 'Utilisateur';
}

/**
 * Retourne la chaine base64 du fichier passé en paramètres
 * @param file
 */
export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      reject('Error: ' + error);
    };
  });
}

/**
 * Retourne uniquement les produits qui sont des applications
 * @param companyProducts
 * @returns Seulement les companyProductApplication
 */
export function getOnlyApplication(companyProducts: CompanyProduct[]) {
  return companyProducts.filter((cP) => cP.type === 'application') as CompanyProductApplication[];
}

/**
 * Execute la fonction func seulement si la valeure du state n'a pas été modifiée depuis time temps
 * @param initialValue La valeure initiale du state
 * @param funcToSend Nouvelle valeure du state / fonction de mise à jour (reçoit en paramètre le state de ce moment)
 * @param time Temps de debounce
 * @returns [state, setState]
 */
export function useDebounce<T>(initialValue: T, funcToSend: (arg: T) => void, time: number = 500) {
  // Création du state local
  const [localState, setLocalState] = useState<T>(initialValue);
  const [currentTimout, setCurrentTimout] = useState<undefined | NodeJS.Timeout>(undefined);

  // Récupération du changement
  function handleChange(value: T | ((val: T) => T)): void {
    // Change le state local
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);

    // Si il y a déjà un timout, on l'annule
    if (currentTimout) {
      clearInterval(currentTimout);
    }

    // On crée un nouveau timout
    setCurrentTimout(setTimeout(() => funcToSend(valueToStore), time));
  }

  return [localState, handleChange] as const;
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

export function simulateMouseClick(element: Element | null) {
  if (element === null) return;

  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}

export function startOnBoardingTour(tour: Tour | null, steps: ShepherdOptionsWithType[], start: boolean = true, startingStep?: string) {
  if (tour) {
    tour.isActive() && tour.cancel();
    tour.steps = [];
    tour.addSteps(steps);
  }

  if (start) {
    tour?.start();
  } else if (startingStep) {
    tour?.show(startingStep);
  }
}

export function isAdmin(user: User | undefined): boolean {
  return user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLE.ROLE_ADMIN);
}
