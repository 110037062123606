import { ErrorMessage, FieldArray, Form, Formik, FormikErrors, getIn, useFormik, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { CreateUserInterface, User } from '../../utils/api/_type';
import Utils from '../Utils';
import style from './CreateUserForm.module.css';
import * as Yup from 'yup';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faQuestionCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

interface UserFormProps {
  user: CreateUserInterface;
  index: number;
  errors: FormikErrors<{
    users: CreateUserInterface[];
  }>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  emailRequired?: boolean;
  isEditing?: boolean;
}

function UserForm(props: UserFormProps) {
  const [userNameChanged, setUserNameChanged] = useState<boolean>(false);

  useEffect(() => {
    if (props.isEditing) {
      setUserNameChanged(true);
    }
  }, [props.isEditing]);

  return (
    <div className="onboarding-createuserform-1st" style={{ minWidth: '30rem', marginBottom: '1rem' }}>
      <div>
        <Utils.Input
          id={`users.${props.index}.firstname`}
          name={`users.${props.index}.firstname`}
          label={
            !!getIn(props.errors, `users.${props.index}.firstname`) ? (
              <>
                <span>Prénom </span>

                <span className={style.errorText}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> {getIn(props.errors, `users.${props.index}.firstname`)}
                </span>
              </>
            ) : (
              'Prénom'
            )
          }
          placeholder="Obligatoire"
          value={props.user.firstname}
          onChange={(e) => {
            props.handleChange(e);
            if (!userNameChanged) props.setFieldValue(`users.${props.index}.username`, e.target.value + props.user.lastname.substring(0, 1));
          }}
          isInvalid={!!getIn(props.errors, `users.${props.index}.firstname`)}
        />
      </div>
      <Utils.Input
        id={`users.${props.index}.lastname`}
        name={`users.${props.index}.lastname`}
        label={
          !!getIn(props.errors, `users.${props.index}.lastname`) ? (
            <>
              <span>Nom </span>

              <span className={style.errorText}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {getIn(props.errors, `users.${props.index}.lastname`)}
              </span>
            </>
          ) : (
            'Nom'
          )
        }
        placeholder="Obligatoire"
        value={props.user.lastname}
        onChange={(e) => {
          props.handleChange(e);
          if (!userNameChanged) props.setFieldValue(`users.${props.index}.username`, props.user.firstname + e.target.value.substring(0, 1));
        }}
        isInvalid={!!getIn(props.errors, `users.${props.index}.lastname`)}
      />

      <Utils.Input
        label={
          !!getIn(props.errors, `users.${props.index}.email`) ? (
            <>
              <span>Email </span>

              <span className={style.errorText}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {getIn(props.errors, `users.${props.index}.email`)}
              </span>
            </>
          ) : (
            'Email'
          )
        }
        placeholder={props.emailRequired ? 'Obligatoire' : 'Optionnel'}
        value={props.user.email}
        name={`users.${props.index}.email`}
        type="email"
        onChange={props.handleChange}
        isInvalid={!!getIn(props.errors, `users.${props.index}.email`)}
      />

      <Utils.Input
        label={
          !!getIn(props.errors, `users.${props.index}.username`) ? (
            <>
              <span>Identifiant </span>

              <span className={style.errorText}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {getIn(props.errors, `users.${props.index}.username`)}
              </span>
            </>
          ) : (
            'Identifiant'
          )
        }
        placeholder="Obligatoire"
        value={props.user.username}
        name={`users.${props.index}.username`}
        onChange={(e) => {
          props.handleChange(e);
          if (!userNameChanged) {
            setUserNameChanged(true);
            props.setFieldValue(`users.${props.index}.isUsernameUpdated`, true);
          }
        }}
        isInvalid={!!getIn(props.errors, `users.${props.index}.username`)}
      />
      <Utils.Input
        label={
          !!getIn(props.errors, `users.${props.index}.plainPassword`) ? (
            <>
              <span>Mot de passe </span>

              <span className={style.errorText}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {getIn(props.errors, `users.${props.index}.plainPassword`)}
              </span>
            </>
          ) : (
            'Mot de passe'
          )
        }
        placeholder={!props.isEditing && !props.user.email && !props.emailRequired ? 'Obligatoire' : 'Optionnel'}
        value={props.user.plainPassword}
        name={`users.${props.index}.plainPassword`}
        hidable={true}
        onChange={props.handleChange}
        isInvalid={!!getIn(props.errors, `users.${props.index}.plainPassword`)}
        helpIcon={faQuestionCircle}
        helpContent={`Si vous ne renseignez pas le champ mot de passe, un mot de passe par défaut sera envoyé à l'utilisateur.`}
        helpStyle={{ width: '15rem' }}
      />
      <Utils.Input
        label={
          !!getIn(props.errors, `users.${props.index}.confirmPassword`) ? (
            <>
              <span>Confirmation </span>

              <span className={style.errorText}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {getIn(props.errors, `users.${props.index}.confirmPassword`)}
              </span>
            </>
          ) : (
            'Confirmation'
          )
        }
        placeholder={(!props.isEditing && !props.user.email && !props.emailRequired) || props.user.plainPassword ? 'Obligatoire' : 'Optionnel'}
        value={props.user.confirmPassword}
        name={`users.${props.index}.confirmPassword`}
        hidable={true}
        onChange={props.handleChange}
        isInvalid={!!getIn(props.errors, `users.${props.index}.confirmPassword`)}
      />
    </div>
  );
}
//props.onSubmit(values.users, () => setInitialValues(clearValues));
interface Props {
  values?: User;
  isLoading?: boolean;
  onSubmit: (user: CreateUserInterface[], resetValues: () => void) => void;
  multiple?: boolean;
  className?: string;
  emailRequired?: boolean;
}

function CreateUserForm(props: Props) {
  const clearValues = {
    users: [
      {
        id: Math.random() + Date.now(),
        username: '',
        email: '',
        plainPassword: '',
        confirmPassword: '',
        firstname: '',
        lastname: '',
        isUsernameUpdated: false,
      },
    ],
  };

  const [initialValues, setInitialValues] = useState<{ users: CreateUserInterface[] }>(clearValues);

  const validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        username: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
        email: props.emailRequired
          ? Yup.string().email("L'email doit être valide.").required('Requis si aucun mot de passe défini.')
          : Yup.string().email("L'email doit être valide."),
        firstname: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
        lastname: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
        plainPassword: props.values
          ? Yup.string().min(6, 'Minimum 6 caractères')
          : Yup.mixed().when('email', {
              is: (email: string) => !email && !props.emailRequired,
              then: Yup.string().min(6, 'Minimum 6 caractères').required('Requis si aucune email définie.'),
              otherwise: Yup.string().test((value) => value === '' || value === undefined || value.length >= 6),
            }),
        confirmPassword: Yup.mixed().when('plainPassword', {
          is: (plainPassword: string) => plainPassword,
          then: Yup.string()
            .oneOf([Yup.ref('plainPassword'), null], 'Les champs doivent correspondre.')
            .min(6, 'Minimum 6 caractères')
            .required('Requis si aucune email définie.'),
        }),
      })
    ),
  });

  useEffect(() => {
    if (props.values) {
      setInitialValues({
        users: [
          {
            id: props.values.id,
            username: props.values.username,
            email: props.values.email || '',
            firstname: props.values.firstname,
            lastname: props.values.lastname,
            plainPassword: '',
            confirmPassword: '',
            isUsernameUpdated: true,
          },
        ],
      });
    } else {
      setInitialValues(clearValues);
    }
  }, [props.values]);

  return (
    <div className={classNames(props.className)}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          props.onSubmit(values.users, () => setInitialValues(clearValues));
        }}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, handleChange, setFieldValue, isValid }) => (
          <Form>
            {props.multiple ? (
              <div className={style.addedUserContainer}>
                <FieldArray name="users">
                  {({ insert, remove, push }) => (
                    <>
                      {values.users.length > 0 &&
                        values.users.map((user: CreateUserInterface, index: number) => (
                          <div className={style.user}>
                            {values.users.length !== 1 ? (
                              <FontAwesomeIcon icon={faTimesCircle} className={style.buttonDelete} onClick={() => remove(index)} />
                            ) : null}
                            <UserForm
                              user={user}
                              index={index}
                              errors={errors}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              emailRequired={props.emailRequired}
                            />
                          </div>
                        ))}
                      <div className={classNames(style.addUserButton, 'onboarding-createuserform-2nd')} onClick={() => push(initialValues)}>
                        <FontAwesomeIcon icon={faUsers} />
                        <p>Ajouter un autre utilisateur</p>
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
            ) : (
              <UserForm
                user={values.users[0]}
                index={0}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                emailRequired={props.emailRequired}
                isEditing={!!props.values}
              />
            )}

            <Utils.Button
              id="onboarding-createuserform-next-3rd"
              additionalClassName="onboarding-createuserform-3rd"
              type="submit"
              isLoading={props.isLoading}
            >
              {props.values ? (
                "Modifier l'utilisateur"
              ) : (
                <>
                  Ajouter {values.users.length} utilisateur
                  {values.users.length > 1 ? 's' : ''}
                </>
              )}
            </Utils.Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateUserForm;
