import Utils from '../../../components/Utils';
import { SubmitModalParams } from '../../../utils/api/_type';

interface getSuccessSubmitTypeProps {
  title: string;
  verb?: string;
  text?: string;
}

export function getSuccessSubmitType(props: getSuccessSubmitTypeProps): SubmitModalParams {
  return {
    title: props.title,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {props.verb ? <span>L'utiliateur a été {props.verb} avec succès !</span> : <span>{props.text}</span>}
      </div>
    ),
    checked: true,
    isOpen: true,
  };
}

interface getErrorSubmitTypeProps {
  title: string;
  verb?: string;
  text?: string;
  setState: (value: React.SetStateAction<SubmitModalParams>) => void;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}

export function getErrorSubmitType(props: getErrorSubmitTypeProps): SubmitModalParams {
  return {
    title: props.title,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {props.verb ? (
          <span>Il y a eu un problème lors de {props.verb} de votre utilisateur ! Vérifiez si le formulaire est correct et réessayez.</span>
        ) : (
          <span>{props.text}</span>
        )}
        <span style={{ marginTop: '2rem' }}>Si vous pensez être face à un bug, n'hésitez pas à nous le remonter !</span>
        <Utils.Button
          style={{ marginTop: '2rem' }}
          onClick={() => {
            props.setState({ title: '', content: <></>, checked: false, isOpen: false });
            props.setIsOpen(true);
          }}
        >
          Remonter un bug
        </Utils.Button>
      </div>
    ),
    checked: false,
    isOpen: true,
  };
}
