import { MY_DOMAIN } from '../../../utils/utils';
import Utils from '../../Utils';
import style from '../MoreInfoModal.module.css';

export default function app(openOtherApp: (denomination: string) => void) {
  return {
    name: 'Catalogue digital',
    fileUri: 'https://app.cktools.c-koya.tech/img/apps/big_catalogue.png?v1.0.0',
    description: (
      <>
        <p className={style.title}>Créez et diffusez votre catalogue en 3 clics !</p>
        <p>
          Grâce à Catalogue digital, digitalisez très facilement la présentation de vos produits. Vos nouveautés s’intègrent instantanément, n’ayez
          plus jamais de catalogue obsolète !
        </p>

        <div className={style.importantContainer}>
          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://app.cktools.c-koya.tech/img/apps/strength/accessibility.svg?v1.0.0" alt="Accessibility" />
              <p>Accessibilité</p>
            </div>
            <p>Vos catalogues seront disponibles sur tous les appareils (mobile, tablette, ordinateur).</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://app.cktools.c-koya.tech/img/apps/strength/cost.svg?v1.0.0" alt="Easy" />
              <p>Réduction des coûts</p>
            </div>
            <p>Les catalogues papier sont coûteux financièrement et écologiquement. Économisez votre argent et sauvez quelques arbres.</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://app.cktools.c-koya.tech/img/apps/strength/update.svg?v1.0.0" alt="Update" />
              <p>Mise à jour facile et instantanée</p>
            </div>
            <p>Catalogue digital permet très simplement d’ajouter, corriger, mettre à jour ou supprimer des produits.</p>
          </div>
        </div>

        <p className={style.bold}>
          Catalogue digital ne nécessite pas l’intervention d’un graphiste pour mettre en page votre catalogue : vous créez vos produits avec des
          photos, des vidéos, un prix et une description. Quelques clics plus tard, vous les présentez à vos clients.
        </p>

        <p>
          Catalogue digital vous donne la possibilité d’avoir un support de présentation de vos produits toujours à jour et interactif. Mettez vos
          collaborateurs dans les meilleures conditions pour vendre !
        </p>

        <p className={style.title}>Seulement en 3 étapes</p>
        <div className={style.stepsContainer}>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <span>1</span>
            </p>
            <p>Créez votre catalogue</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <span>2</span>
            </p>
            <p>Ajoutez vos produits</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <span>3</span>
            </p>
            <p>Partagez</p>
          </div>
        </div>
      </>
    ),
    associate: (
      <>
        <p className={style.title}>Les applications associées à Catalogue digital</p>
        <div className={style.associateWin}>
          <div className={style.associateLeft}>
            <img src="https://app.cktools.c-koya.tech/img/apps/big_media.png?v1.0.0" alt="Logo Media" />
          </div>

          <div className={style.associateRight}>
            <p className={style.associateRightTitle}>Photos & Vidéos</p>
            <p>
              Vous pouvez connecter Catalogue digital à Photos & Vidéos pour retrouver les photos et vidéos directement dans la galerie à la création
              des produits.
            </p>
            <span>
              <Utils.LinkButton onClick={() => openOtherApp('capture')}>Plus d'infos</Utils.LinkButton>
            </span>
          </div>
        </div>
      </>
    ),
    video: (
      <div className={style.contentVideo}>
        <div className={style.ecran}>
          <img src={`${MY_DOMAIN}/img/illu-video.svg`} alt="Ecran" />
        </div>
        <div className={style.video}>
          <iframe
            width="720"
            height="405"
            src="https://www.youtube.com/embed/ptlDuhup06o"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    ),
    compatibility: (
      <>
        <p className={style.title}>Application compatible avec tous les appareils</p>

        <div className={style.compatibilityContainer}>
          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Smartphone et Tablette</p>
              <p>Consultation</p>
            </div>
            <div className={style.compatBottomMultiple}>
              <div className={style.compatBottomRow}>
                <img src={process.env.PUBLIC_URL + '/img/mobile.png'} alt="Smartphone" />
                <img src={process.env.PUBLIC_URL + '/img/tablette.png'} alt="Tablette" />
              </div>

              <div className={style.compatBottomRow}>
                <img
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                  alt="Play Store"
                  className={style.playStoreLogo}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.winylo.catalogue&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                    )
                  }
                />
                <img
                  src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/fr-fr.svg"
                  alt="App Store"
                  className={style.appStoreLogo}
                  onClick={() => window.open('https://apps.apple.com/fr/app/catalogue-winylo/id1589031585?itsct=apps_box_badge&itscg=30200')}
                />
              </div>
            </div>
          </div>

          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Ordinateur</p>
              <p>Gestion et Consultation</p>
            </div>
            <div className={style.compatBottom}>
              <img src={process.env.PUBLIC_URL + '/img/ordinateur.png'} alt="Ordinateur" />
            </div>
          </div>
        </div>
      </>
    ),
  };
}
