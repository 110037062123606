export const USERFORM_FIELDS = {
  LABELS: {
    FIRSTNAME: 'Prénom',
    LASTNAME: 'Nom',
    EMAIL: 'Email',
    USERNAME: 'Identifiant',
    PASSWORD: 'Mot de passe',
    PASSWORD_CONFIRM: 'Confirmation',
  },
  ACCESSORS: {
    FIRSTNAME: 'firstname',
    LASTNAME: 'lastname',
    EMAIL: 'email',
    USERNAME: 'username',
    PASSWORD: 'password',
    PASSWORD_CONFIRM: 'password_confirm',
    APPLICATION_ROLES: 'application_roles',
  },
  PLACEHOLDERS: {
    REQUIRED: 'Obligatoire',
    OPTIONAL: 'Optionnel',
  },
};

export const SHARE_FIELDS = {
  LABELS: {
    EMAILS: '',
    SUBJECT: 'Objet',
    MESSAGE: 'Votre message',
  },
  ACCESSORS: {
    EMAILS: 'addresses',
    SUBJECT: 'subject',
    MESSAGE: 'message',
  },
  PLACEHOLDERS: {
    EMAILS: 'Destinataires...',
    SUBJECT: 'Objet du mail',
    MESSAGE: 'Contenu de votre invitation',
  },
};
