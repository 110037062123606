import React from 'react';

export default class ErrorCatcher extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorContent: '' };
  }

  state = {
    hasError: false,
    errorContent: '',
  };

  componentDidCatch(error: any, info: any) {
    // Affiche une UI de repli
    this.setState({ hasError: true });
    this.setState({ errorContent: `${info}\n\n${error}` });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Quelque chose s'est mal passé.</h1>
          <p>Les développeurs ont étés prévenus</p>
        </>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}
