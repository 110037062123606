import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { useFormState } from '../../../utils/utils';
import { ApplicationInterface, ApplicationSchema } from '../../../utils/schemas';
import { useMutation, useQuery } from 'react-query';
import api from '../../../utils/api';
import ShowHideInput from '../../../components/ShowHideInput';
import Utils from '../../../components/Utils';

export default function ApplicationForm() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [formState, handleForm, setForm] = useFormState<ApplicationInterface>({
    name: '',
    description: '',
    denomination: '',
    urlApi: '',
    urlWeb: '',
    urlAndroid: '',
    urlIos: '',
    availableRoles: '[]',
    price: '',
    accessToken: '',
    trialDuration: '',
    clientId: '',
    // clientSecret: '',
    redirectUris: '[]',
    image: undefined,
    imageUrl: null,
    isBeta: false,
    comingSoon: false,
    hasStorage: false,
  });

  // Récupération des données
  const { isLoading } = useQuery(['application', id && parseInt(id)], () => api.applications.getOneApplication(parseInt(id || '-1')), {
    enabled: id !== undefined,
    onSuccess: (data) => {
      setForm({
        ...data,
        urlIos: data.urlIos !== null ? data.urlIos : '',
        urlAndroid: data.urlAndroid !== null ? data.urlAndroid : '',
        availableRoles: JSON.stringify(data.availableRoles),
        price: data.price === null ? '' : data.price / 100 + '€',
        accessToken: data.accessToken !== null ? data.accessToken : '',
        trialDuration: data.trialDuration === null ? '' : data.trialDuration + '',
        clientId: data.clientId !== null ? data.clientId : '',
        // clientSecret: data.clientSecret !== null ? data.clientSecret : '',
        redirectUris: data.redirectUris !== null ? JSON.stringify(data.redirectUris) : '[]',
        image: undefined,
        imageUrl: data.fileUri,
        isBeta: data.isBeta,
        comingSoon: data.comingSoon,
        hasStorage: data.hasStorage,
      });
    },
  });

  // Création de l'application
  const { mutate: createApplication } = useMutation(api.applications.createApplication, {
    onSuccess: (data, variable) => {
      if (variable.file !== undefined) {
        api.applications
          .addApplicationImage({ id: data.id, file: variable.file })
          .then(() => {
            navigate('/a/app');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        navigate('/a/app');
      }
    },
  });

  // Modification de l'application
  const { mutate: updateApplication } = useMutation(api.applications.updateApplication, {
    onSuccess: (data, variable) => {
      if (variable.body.file !== undefined) {
        api.applications
          .addApplicationImage({ id: data.id, file: variable.body.file })
          .then(() => {
            navigate('/a/app');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        navigate('/a/app');
      }
    },
  });

  function handleClick() {
    if (ApplicationSchema.isValidSync(formState)) {
      if (id === undefined) {
        createApplication({
          ...formState,
          urlIos: formState.urlIos !== '' ? formState.urlIos : null,
          urlAndroid: formState.urlAndroid !== '' ? formState.urlAndroid : null,
          // price: { price: parseFloat(formState.price) },
          availableRoles: JSON.parse(formState.availableRoles),
          accessToken: formState.accessToken !== '' ? formState.accessToken : null,
          trialDuration: formState.trialDuration === '' ? null : parseInt(formState.trialDuration),
          redirectUris: JSON.parse(formState.redirectUris),
          file: formState.image !== undefined && formState.image.length === 1 ? formState.image[0] : undefined,
        });
      } else {
        updateApplication({
          id: parseInt(id),
          body: {
            ...formState,
            urlIos: formState.urlIos !== '' ? formState.urlIos : null,
            urlAndroid: formState.urlAndroid !== '' ? formState.urlAndroid : null,
            // price: { price: parseFloat(formState.price) },
            availableRoles: JSON.parse(formState.availableRoles),
            accessToken: formState.accessToken !== '' ? formState.accessToken : null,
            trialDuration: formState.trialDuration === '' ? null : parseInt(formState.trialDuration),
            redirectUris: JSON.parse(formState.redirectUris),
            file: formState.image !== undefined && formState.image.length === 1 ? formState.image[0] : undefined,
          },
        });
      }
    } else {
      return;
    }
  }

  const { mutate: deleteImage } = useMutation(api.applications.removeApplicationImage, {
    onSuccess: () => {
      setForm((before) => ({
        ...before,
        image: undefined,
        imageUrl: null,
      }));
    },
  });

  return (
    <Container>
      <h1>{id === undefined ? 'Créer' : 'Modifier'} une application</h1>

      {id !== undefined && isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Form.Group>
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              value={formState.name}
              onChange={handleForm}
              name="name"
              isInvalid={!ApplicationSchema.fields.name.isValidSync(formState.name)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              value={formState.description || ''}
              onChange={handleForm}
              name="description"
              isInvalid={!ApplicationSchema.fields.description.isValidSync(formState.description)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Dénomination</Form.Label>
            <Form.Control
              type="text"
              value={formState.denomination}
              onChange={handleForm}
              name="denomination"
              isInvalid={!ApplicationSchema.fields.denomination.isValidSync(formState.denomination)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>URL Api</Form.Label>
            <Form.Control
              type="url"
              value={formState.urlApi}
              onChange={handleForm}
              name="urlApi"
              isInvalid={!ApplicationSchema.fields.urlApi.isValidSync(formState.urlApi)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>URL Web</Form.Label>
            <Form.Control
              type="url"
              value={formState.urlWeb}
              onChange={handleForm}
              name="urlWeb"
              isInvalid={!ApplicationSchema.fields.urlWeb.isValidSync(formState.urlWeb)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>URL Android</Form.Label>
            <Form.Control
              type="url"
              value={formState.urlAndroid}
              onChange={handleForm}
              name="urlAndroid"
              isInvalid={!ApplicationSchema.fields.urlAndroid.isValidSync(formState.urlAndroid)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>URL iOS</Form.Label>
            <Form.Control
              type="url"
              value={formState.urlIos}
              onChange={handleForm}
              name="urlIos"
              isInvalid={!ApplicationSchema.fields.urlIos.isValidSync(formState.urlIos)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Roles disponibles (tableau de string)</Form.Label>
            <Form.Control
              type="text"
              value={formState.availableRoles}
              onChange={handleForm}
              name="availableRoles"
              isInvalid={!ApplicationSchema.fields.availableRoles.isValidSync(formState.availableRoles)}
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Prix</Form.Label>
            <Form.Control
              type="text"
              value={formState.price}
              onChange={handleForm}
              name="price"
              isInvalid={
                !ApplicationSchema.fields.price.isValidSync(formState.price)
              }
            />
          </Form.Group> */}
          <p>
            <strong>Prix :</strong> {formState.price}
          </p>

          <Form.Group>
            <Form.Label>Token D'accès</Form.Label>
            <Form.Control
              type="text"
              value={formState.accessToken as string}
              onChange={handleForm}
              name="accessToken"
              isInvalid={!ApplicationSchema.fields.accessToken.isValidSync(formState.accessToken)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Durée de la période d'essais (en jours)</Form.Label>
            <Form.Control
              type="number"
              value={formState.trialDuration as string}
              onChange={handleForm}
              name="trialDuration"
              isInvalid={!ApplicationSchema.fields.trialDuration.isValidSync(formState.trialDuration)}
            />
          </Form.Group>

          <h2>Image</h2>
          {formState.imageUrl === null ? (
            <p>Il n'y a pas d'image dans cette application pour le moment</p>
          ) : (
            <>
              <img src={formState.imageUrl} alt="Logo de l'application" style={{ width: '100px' }} />
              <Button variant="danger" style={{ display: 'block' }} onClick={() => id && deleteImage(parseInt(id))}>
                Supprimer
              </Button>
            </>
          )}

          <input type="file" onChange={handleForm} name="image" style={{ display: 'block', marginTop: '5px' }} accept="image/png" />

          <h2>OAuth</h2>
          <Form.Group>
            <Form.Label>ClientID</Form.Label>
            <ShowHideInput value={formState.clientId} readOnly />
          </Form.Group>

          {/* <Form.Group>
            <Form.Label>ClientSecret</Form.Label>
            <ShowHideInput value={formState.clientSecret} readOnly />
          </Form.Group> */}

          <Form.Group>
            <Form.Label>Adresses de redirections</Form.Label>
            <Form.Control
              type="string"
              value={formState.redirectUris}
              onChange={handleForm}
              name="redirectUris"
              isInvalid={!ApplicationSchema.fields.redirectUris.isValidSync(formState.redirectUris)}
            />
          </Form.Group>

          <Utils.Checkbox label={'Beta'} checked={formState.isBeta || false} onChange={handleForm} name="isBeta" />
          <Utils.Checkbox label={'Arrive bientôt'} checked={formState.comingSoon || false} onChange={handleForm} name="comingSoon" />
          <Utils.Checkbox label={'Has Storage (laisser au dev)'} checked={formState.hasStorage || false} onChange={handleForm} name="hasStorage" />

          <Button onClick={handleClick} disabled={!ApplicationSchema.isValidSync(formState)}>
            {id === undefined ? 'Créer' : 'Modifier'}
          </Button>
        </>
      )}
    </Container>
  );
}
