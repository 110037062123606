import { useEffect, useState } from 'react';

import { UserContextType, useUser } from '../utils/context/User';
import { useMutation } from 'react-query';
import api from '../utils/api';
import { SmallUpdateUserBody } from '../utils/api/users';

import Utils from '../components/Utils';

import style from './MyProfile.module.css';
import CreateUserForm from '../components/CreateUserForm/CreateUserForm';
import { CreateUserInterface } from '../utils/api/_type';
import { TITLES } from '../utils/constantes';

export default function MyProfile() {
  const [user, setUser] = useUser() as UserContextType;
  const [isSaveDone, setIsSaveDone] = useState(false);

  const { mutate: updateUser, isLoading: isLoadingUpdate } = useMutation(api.users.updateMe, {
    onSuccess: (data, variable) => {
      setUser(data);
      setIsSaveDone(true);
    },
  });

  function handleSave(updatedUser: CreateUserInterface) {
    let body: SmallUpdateUserBody = {
      username: updatedUser.username,
      email: updatedUser.email,
      firstname: updatedUser.firstname,
      lastname: updatedUser.lastname,
    };

    if (updatedUser.plainPassword !== '') {
      body.plainPassword = updatedUser.plainPassword;
    }

    updateUser(body);
  }

  useEffect(() => {
    document.title = TITLES.MY_PROFILE;
  }, []);

  return (
    <Utils.Container verticalCenter={true}>
      <Utils.Card title="Mon profil" width="500px">
        <>
          <div className={style.twoColumn}>
            <div style={{ width: '100%' }}>
              <p className={style.infoText}>Tous les champs marqués d'une étoile sont obligatoires</p>

              <CreateUserForm values={user} onSubmit={(updatedUser) => handleSave(updatedUser[0])} isLoading={isLoadingUpdate} />
            </div>
          </div>
        </>
      </Utils.Card>

      <Utils.ConfirmModal
        isOpen={isSaveDone}
        closable={true}
        onClose={() => setIsSaveDone(false)}
        title="Utilisateur sauvegardé"
        text="L'utilisateur a bien été modifié"
      />
    </Utils.Container>
  );
}
