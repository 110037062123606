import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import { UserContextType, useUser } from '../../utils/context/User';
import { MY_DOMAIN, copyStringToClipboard } from '../../utils/utils';

import style from './Information.module.css';

import Toastify from 'toastify-js';

export default function Information() {
  const [user] = useUser() as UserContextType;

  function handleClickCopy() {
    copyStringToClipboard(MY_DOMAIN + '/register/' + user?.referralCode);

    Toastify({
      text: 'Adresse copiée dans le presse-papier!',
      duration: 3000,
      close: true,
      gravity: 'bottom', // `top` or `bottom`
      position: 'center', // `left`, `center` or `right`
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  }

  function handleSendInvite() {}

  return (
    <Container>
      <h1>Recommander CKTools</h1>

      <h2>1. Partagez CKTools</h2>

      <div className={style.horizontalDiv}>
        <Form.Group>
          <Form.Label>Lien de parrainage</Form.Label>
          <Form.Control defaultValue={MY_DOMAIN + '/register/' + user?.referralCode} readOnly={true} />
          <Form.Text>Utilisez ce lien dès que vous recommendez CKTools</Form.Text>
        </Form.Group>
        <Button variant="primary" onClick={handleClickCopy} style={{ marginBottom: '5px' }}>
          Copier le texte
        </Button>
      </div>

      <div className={style.horizontalDiv}>
        <Form.Group>
          <Form.Label>Invitez par mail</Form.Label>
          <Form.Control type="email" placeholder="Email" />
        </Form.Group>
        <Button variant="primary" onClick={handleSendInvite} style={{ marginTop: '15px' }}>
          Envoyer l'invitation
        </Button>
      </div>

      <h2>2. Recevez votre commission</h2>
      <p>Lorsque votre fillieul effectuera un achat, vous serez remercié par le biais d'une commission</p>
    </Container>
  );
}
