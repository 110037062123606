import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import api from '../../utils/api';
import { HasCheckLoginContextType, useHasCheckLogin } from '../../utils/context/HasCheckLogin';
import { UserContextType, useUser } from '../../utils/context/User';
import { logger, parseQuery } from '../../utils/utils';
import { useQuery } from 'react-query';
import Utils from '../../components/Utils';
import { Navigate } from 'react-router-dom';

import style from './Login.module.css';

export default function AppLogin() {
  const location = useLocation();

  const [user, setUser] = useUser() as UserContextType;
  const [hasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;
  const [hasSendRequest, setHasSendRequest] = useState(false);

  const [isError, setIsError] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');

  const hash = useMemo(() => parseQuery(), []);

  const { data: app, isLoading } = useQuery(
    ['appByClientId', hash.get('client_id')],
    () => api.applications.getAppByClientId(hash.get('client_id') || ''),
    {
      enabled: hash.get('client_id') !== null && user !== undefined,
      retry: 0,
      onError: () => setIsError("L'application que vous essayer de rejoindre n'existe pas..."),
    }
  );

  function launchConnection() {
    if (hasSendRequest) return;

    if (hash.get('client_id') !== null && hash.get('redirect_uri') !== null && hasCheckLogin && user !== undefined) {
      setHasSendRequest(true);

      api.auth
        .authorize(hash.get('client_id') as string, hash.get('redirect_uri') as string)
        .then((data) => {
          window.location.href = `${decodeURIComponent(hash.get('redirect_uri') as string)}?code=${data.code}&state=${hash.get(
            'state'
          )}&scope=openid&redirect_page=${decodeURIComponent(hash.get('redirect_page') as string)}`;
        })
        .catch((err) => {
          logger(err);
          setErrorConfirm(JSON.parse(err.message).message);
        });
    }
  }

  function changeAccount() {
    setUser(undefined);
    localStorage.removeItem('jwt');
  }

  function ModalConfirm() {
    return isError !== '' ? (
      <Utils.ErrorMessage>{isError}</Utils.ErrorMessage>
    ) : (
      <>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />
        <p className={style.title}>Confirmation de connexion</p>
        <p>
          Vous essayez actuellement de vous connecter à <strong>{app?.name}</strong>
        </p>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: 0,
          }}
        >
          Votre compte
        </p>
        <p style={{ textAlign: 'center', margin: 0 }}>
          {user?.firstname} {user?.lastname}
        </p>
        <p
          style={{
            textAlign: 'center',
            margin: 0,
            marginBottom: '10px',
          }}
        >
          {user?.email}
        </p>

        {errorConfirm !== '' ? <Utils.ErrorMessage>{errorConfirm}</Utils.ErrorMessage> : null}

        <div className={style.buttonContainer}>
          <Utils.Button
            onClick={() => {
              window.location.href = `${decodeURIComponent(hash.get('redirect_uri') as string)}?code=&state=${hash.get(
                'state'
              )}&scope=openid&redirect_page=${decodeURIComponent(hash.get('redirect_page') as string)}`;
            }}
            variant="gray"
          >
            Annuler
          </Utils.Button>
          <Utils.Button onClick={launchConnection} isLoading={hasSendRequest}>
            Confirmer
          </Utils.Button>
        </div>

        <Utils.Button variant="secondary" onClick={changeAccount}>
          Utiliser un autre compte
        </Utils.Button>
      </>
    );
  }

  return (
    <Utils.Container>
      <Utils.Card style={{ maxWidth: '450px', width: 'auto' }}>
        {hash.get('client_id') !== null && hash.get('redirect_uri') !== null ? (
          !hasCheckLogin ? (
            <Utils.Spinner center />
          ) : user !== undefined ? (
            isLoading || app === undefined ? (
              <Utils.Spinner center />
            ) : (
              <ModalConfirm />
            )
          ) : (
            <Navigate to={'/login#redirect=' + encodeURIComponent(location.pathname + location.search)} />
          )
        ) : (
          <p>Il y a un problème dans l'adresse de connexion...</p>
        )}
      </Utils.Card>
    </Utils.Container>
  );
}
