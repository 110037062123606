import classNames from 'classnames';
import React from 'react';

import style from './Button.module.css';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export default function LinkButton(props: Props) {
  return (
    <button {...props} className={classNames(style.linkButton, props.className)}>
      {props.children}
    </button>
  );
}
