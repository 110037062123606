import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FormikErrors, getIn } from "formik";

/**
 * Forme le label pour un champ en fonction de sa validité.
 * @param label Label affiché
 * @param accessor Accesseur du champs dans le formulaire formik
 * @param errors Erreurs du formulaire formik
 * @return Element React qui forme le label du champ
 */
interface InputLabelProps<T> {
    label: string;
    accessor: string;
    errors: FormikErrors<T>;
    icon?: IconProp;
    color?: string;
}

export default function InputLabel<T>({
    label,
    accessor,
    errors,
    icon,
    color = "var(--red)",
}: InputLabelProps<T>) {
    const validation = getIn(errors, accessor);

    return (
        <>
            {label}
            {!!validation && (
                <span style={{ color: color }}>
                     {icon && <FontAwesomeIcon icon={icon} />} {validation}
                </span>
            )}
        </>
    );
}
