import { useEffect } from 'react';

import { BasketContextType, BasketAddon, useBasket } from '../../../utils/context/Basket';
import { useQuery } from 'react-query';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';

export default function ScreenLicence() {
  const navigate = useNavigate();
  const [, setBasket] = useBasket() as BasketContextType;

  const { data: screenLicence } = useQuery('screenLicences', api.addons.getScreenLicences);

  function handleAddScreenLicence() {
    if (screenLicence === undefined) return;

    setBasket((before) => {
      if (before.filter((app) => app.application.id === screenLicence[0].id).length !== 0) {
        return before.map((item: any) =>
          item.application.id === screenLicence[0].id
            ? {
                ...item,
                application: {
                  ...item.application,
                  quantity: item.application.quantity + 1,
                },
              }
            : item
        );
      } else {
        return [
          ...before,
          {
            application: {
              id: screenLicence[0].id,
              name: screenLicence[0].name,
              fileUri: process.env.PUBLIC_URL + '/img/screen-licence.svg',
              price: screenLicence[0].price,
              quantity: before.filter((item: any) => item.application.quantity !== undefined).length + 1,
            } as BasketAddon,
            addedDate: new Date(),
          },
        ];
      }
    });

    navigate('/payment/basket');
  }

  useEffect(() => {
    handleAddScreenLicence();
  }, [screenLicence]);

  return <></>;
}
