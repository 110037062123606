import React, { useRef, useState } from 'react';
import api from '../../utils/api';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Utils from '../../components/Utils';
import Winylo from '../../react_components';
import InputLabel from '../../react_components/components/InputLabel';
import { Form, Formik, FormikProps } from 'formik';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import style from './Login.module.css';

interface ResetFormValue {
  password: string;
  passwordConfirm: string;
}

const initialValues: ResetFormValue = {
  password: '',
  passwordConfirm: '',
};

export default function FormResetPassword(): React.ReactElement {
  const { token } = useParams<{ token: string }>();
  const [isTokenNoOk, setIsTokenNoOk] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const formik = useRef<FormikProps<ResetFormValue>>(null);
  const navigate = useNavigate();

  const { isLoading } = useQuery(['resetToken', token], () => token && api.loginApi.checkResetToken(token), {
    onError: () => {
      setIsTokenNoOk(true);
    },
    retry: 1,
  });

  const UserSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Votre mot de passe doit faire au moins 6 caractères').required('Ce champ est obligatoire'),
    passwordConfirm: Yup.mixed().test({
      name: 'passwordTest',
      message: 'Doit correspondre au mot de passe',
      test: (value: string) => {
        return value === formik.current?.values.password;
      },
    }),
  });

  const { mutate: resetPassword } = useMutation(api.loginApi.resetPassword, {
    onSuccess: () => {
      setIsLoadingReset(false);
      navigate('/login');
    },
  });

  function onSubmit() {
    setIsLoadingReset(true);
    if (!token || !formik.current) return;
    resetPassword({ token, body: formik.current.values });
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Mot de passe oublié</h1>

      <Utils.Card style={{ marginTop: 0, marginBottom: 0, maxWidth: '450px', width: '100%' }}>
        {isLoading ? (
          <Utils.Spinner center={true} />
        ) : isTokenNoOk ? (
          <Utils.ErrorMessage>Le code de réinitialisation de mot de passe que vous avez utilisé n'est pas valide</Utils.ErrorMessage>
        ) : (
          <>
            <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />

            <Formik
              innerRef={formik}
              initialValues={initialValues}
              validationSchema={UserSchema}
              validateOnBlur={false}
              validateOnChange={false}
              validateOnMount={false}
              onSubmit={onSubmit}
            >
              {({ values, errors, handleChange, setFieldValue }) => (
                <Form id="resetpwd-formik" className={style.simpleForm}>
                  <Winylo.TextInput
                    label={<InputLabel label={''} accessor={'password'} errors={errors} icon={faExclamationCircle} />}
                    placeholder="Mot de passe"
                    error={!UserSchema.fields.password.isValidSync(values.password)}
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    style={{ width: '100%' }}
                    type={'password'}
                  />

                  <Winylo.TextInput
                    label={<InputLabel label={''} accessor={'passwordConfirm'} errors={errors} icon={faExclamationCircle} />}
                    placeholder="Répétez le mot de passe"
                    error={!UserSchema.fields.passwordConfirm.isValidSync(values.passwordConfirm)}
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    name="passwordConfirm"
                    style={{ width: '100%' }}
                    type={'password'}
                  />

                  <Winylo.Button
                    loading={isLoadingReset}
                    form="resetpwd-formik"
                    value="Connexion"
                    type="submit"
                    format="square"
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      fontWeight: 700,
                      marginTop: '10px',
                    }}
                    size="md"
                  >
                    Changer le mot de passe
                  </Winylo.Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Utils.Card>
    </Utils.Container>
  );
}
