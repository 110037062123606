import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { GridMode, TopBarProps, TopBarRef } from '../utils/types';
import Winylo from '../../../react_components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Utils from '../../../components/Utils';
import classNames from 'classnames';
import { faBars, faSearch, faTh } from '@fortawesome/free-solid-svg-icons';

import style from '../css/TopBar.module.css';

export const TopBar = forwardRef<TopBarRef, TopBarProps>(({ applicationsLength }: TopBarProps, ref) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [gridMode, setGridMode] = useState<GridMode>(GridMode.GRID);

  useImperativeHandle(
    ref,
    () => ({
      searchValue,
      gridMode,
    }),
    [searchValue, gridMode]
  );

  return (
    <div id="wynboarding-test" className={style.tabTop}>
      <Winylo.TextInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        icon={<FontAwesomeIcon icon={faSearch} />}
        placeholder="Rechercher"
        width="150px"
      />

      <Utils.ImportantNumber word="Application" number={applicationsLength || 0} style={{ marginLeft: '20px' }} />

      <div className={style.iconContainer}>
        <FontAwesomeIcon
          icon={faTh}
          className={classNames(style.icon, {
            [style.selectedIcon]: gridMode === GridMode.GRID,
          })}
          onClick={() => setGridMode(GridMode.GRID)}
        />
        <FontAwesomeIcon
          icon={faBars}
          className={classNames(style.icon, {
            [style.selectedIcon]: gridMode === GridMode.ROW,
          })}
          onClick={() => setGridMode(GridMode.ROW)}
        />
      </div>
    </div>
  );
});

export default TopBar;
