import React from "react";
import classNames from "classnames";
import * as Mantine from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { WINYLO_THEME } from "..";

interface IAvatarProps extends Mantine.AvatarProps {
  avatarRef?: React.RefObject<HTMLDivElement> | null | undefined;
  format?: "round" | "square";
}

export default function Avatar({
  avatarRef,
  format = "round",
  ...props
}: IAvatarProps) {
  return (
    <Mantine.MantineProvider
      theme={{
        ...WINYLO_THEME,
      }}
    >
      <Mantine.Avatar
        ref={avatarRef}
        radius={format === "round" ? "xl" : "xs"}
        {...props}
      />
    </Mantine.MantineProvider>
  );
}
