import { ShepherdOptionsWithType } from 'react-shepherd';
import { MY_DOMAIN, simulateMouseClick } from '../utils/utils';

export function storageManagementSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = 'Prochain tutoriel';
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Gestion du stockage</span>`;

  const classes: string = 'winylo-onboarding onboarding-storagemanagement';

  const defaultDelay: number = 500;

  const stepsLength = 4;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: 'storage-management-1st',
      attachTo: { element: '.onboarding-storagemanagement-1st', on: 'right' },
      advanceOn: { selector: '.onboarding-storagemanagement-1st', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          disabled: true,
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            navigate('/company/storage');
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur l\'onglet <span class="important-text">Gestion du stockage</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'storage-management-2nd',
      attachTo: { element: '.onboarding-storagemanagement-2nd', on: 'top' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            navigate('/');
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
         Ici vous avez l'<span class='important-text'>espace utilisé</span> par chacune de<br/>
         vos applications ainsi que ce qu'il vous reste au total.`,
      ],
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'storage-management-3rd',
      attachTo: { element: '.onboarding-storagemanagement-3rd', on: 'left' },
      advanceOn: { selector: '.onboarding-storagemanagement-3rd', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            var element = document.getElementById('onboarding-storagemanagement-next-3rd');
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
         Appuyez sur <span class="important-text-yellow">Ajouter plus d'espace</span> pour ajouter du stockage.`,
      ],
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'storage-management-4th',
      attachTo: { element: '.onboarding-storagemanagement-4th', on: 'top' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            navigate('/company/storage');
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        Vous n’avez plus qu’a suivre les étapes pour procéder au paiement.`,
      ],
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'storage-management-5th',
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-complete',
          text: completeText,
          action() {
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [`Vous avez terminé le tutoriel <span class="important-text">${title}</span> !`],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
