import login from './api/login';
import applications from './api/applications';
import users from './api/users';
import companies from './api/companies';
import consultants from './api/consultants';
import invoices from './api/invoices';
import auth from './api/auth';
import payment from './api/payment';
import companyApplications from './api/companyapplications';
import paymentmethod from './api/paymentmethod';
import invoiceaddress from './api/invoiceaddress';
import addons from './api/addons';
import trialrequest from './api/trialrequest';
import stats from './api/stats';
import bugs from './api/bugs';
import patchNotes from './api/patchnotes';

import Toastify from 'toastify-js';

export const host: string = process.env.REACT_APP_HOST_API || 'https://api.qualif.winylo.fr';

export interface ErrorMessage {
  code: number;
  message: any;
}

export const checkStatus = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      window.location.href = '/login#redirect=' + encodeURIComponent(window.location.pathname + window.location.hash);
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else {
      return res.json().then((data) => {
        Toastify({
          text: parseMessage(data.message),
          duration: 3000,
          close: true,
          gravity: 'bottom', // `top` or `bottom`
          position: 'right', // `left`, `center` or `right`
          backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
        }).showToast();

        throw new Error(JSON.stringify(data));
      });
    }
  }
};

function parseMessage(msg: string | { [key: string]: string }) {
  if (typeof msg === 'string') {
    return msg;
  } else {
    let str = '';

    Object.keys(msg).forEach((k) => {
      str = str + msg[k];
    });

    return str;
  }
}

export const checkStatusQuiet = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    return res.text().then((msg: string) => {
      throw new Error(msg);
    });
  }
};

export const parseFormData = (obj: { [key: string]: any }) => {
  let keys = Object.keys(obj);
  let bodyFormData = new FormData();

  keys.forEach((k) => {
    if (obj[k] === null) return;
    bodyFormData.set(k, obj[k]);
  });

  return bodyFormData;
};

const api = {
  loginApi: login,
  applications,
  users,
  companies,
  consultants,
  invoices,
  auth,
  payment,
  companyApplications,
  paymentmethod,
  invoiceaddress,
  addons,
  trialrequest,
  stats,
  bugs,
  patchNotes,
};

export default api;
