import { checkStatus, host } from '../api';
import { Company, InvoiceAddress } from './_type';

const invoiceaddress = {
  addInvoiceAddress: (body: {
    city: string;
    country: string;
    line1: string;
    postalCode: string;
  }): Promise<InvoiceAddress> => {
    return fetch(`${host}/api/invoice-addresses`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getInvoiceAddress: (): Promise<InvoiceAddress[]> => {
    return fetch(`${host}/api/invoice-addresses`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteInvoiceAddress: (id: number) => {
    return fetch(`${host}/api/invoice-addresses/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  updateInvoiceAddress: ({
    id,
    body,
  }: {
    id: number;
    body: { city: string; country: string; line1: string; postalCode: string };
  }): Promise<InvoiceAddress> => {
    return fetch(`${host}/api/invoice-addresses/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  changeDefaultAdress: (id: number): Promise<Company> => {
    return fetch(`${host}/api/invoice-addresses/${id}/default`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default invoiceaddress;
