import React from "react";
import classNames from "classnames";

import style from "../../css/old/Separator.module.css";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

export default function Separator(props: Props) {
  return (
    <div
      {...props}
      className={classNames(style.separator, props.className)}
    ></div>
  );
}
