import style from '../MoreInfoModal.module.css';

export default function app(openOtherApp: (denomination: string) => void) {
  return {
    name: 'Affichage dynamique',
    fileUri: 'https://app.cktools.c-koya.tech/img/apps/big_vizu.png?v1.0.0',
    description: (
      <>
        <p className={style.title}>Diffusez du contenu personnalisé sur vos écrans en toute simplicité !</p>
        <p>Optez pour la solution d'affichage dynamique la plus économique du marché.</p>

        <div className={style.importantContainer}>
          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://app.cktools.c-koya.tech/img/apps/strength/upgrade.svg?v1.0.0" alt="Upgrade" />
              <p>Améliorez vos performances</p>
            </div>
            <p>Diffusez des tableaux de bord et des indicateurs de performances en temps réel pour suivre vos progressions.</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://app.cktools.c-koya.tech/img/apps/strength/love.svg?v1.0.0" alt="Love" />
              <p>Informez, motivez & accompagnez vos équipes</p>
            </div>
            <p>Diffusez du contenu pertinent et souvent actualisé à destination des internes pour plus d'engagement et de motivation.</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://app.cktools.c-koya.tech/img/apps/strength/time.svg?v1.0.0" alt="Time" />
              <p>Accueillir</p>
            </div>
            <p>Diffusez des informations vous concernant à destination des visiteurs ou futures recrues.</p>
          </div>
        </div>

        <p className={style.bold}>Grâce aux applications</p>

        <p>
          Contrairement aux autres solutions du marché, vous ne payerez pas une licence par équipement. Si vous avez 5 écrans et que vous souhaitez
          diffuser la même chose sur les 5 écrans, vous ne payez qu'une seule fois. De plus, vous pourrez prochainement bénéficier de template
          personnalisable pour une communication optimales avec les équipes en seulement quelques clics (anniversaires, indicateurs de sécurité,
          référents 1er secours, visite client/fournisseurs)
        </p>

        <p className={style.title}>Seulement en 3 étapes</p>
        <div className={style.stepsContainer}>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <span>1</span>
            </p>
            <p>Créez un affichage à partir d'un template, d'un powerpoint ou d'un lien URL</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <span>2</span>
            </p>
            <p>Choisissez sur quel écran vous voulez diffuser le contenu ainsi que les plages horaires</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <span>3</span>
            </p>
            <p>Admirez le résultat d'une communication interne maitrisée et dynamique</p>
          </div>
        </div>
      </>
    ),
    associate: undefined,
    video: undefined,
    compatibility: (
      <>
        <p className={style.title}>Application compatible avec tous les appareils</p>

        <div className={style.compatibilityContainer}>
          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Ordinateur et écrans connectés</p>
              <p>Affichage du flux en temps réel</p>
            </div>
            <div className={style.compatBottom}>
              <img src={process.env.PUBLIC_URL + '/img/ordinateur.png'} alt="Ordinateur" />
            </div>
          </div>

          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Smartphone et Tablette</p>
              <p>Accès au flux depuis le navigateur</p>
            </div>
            <div className={style.compatBottomMultiple}>
              <div className={style.compatBottomRow}>
                <img src={process.env.PUBLIC_URL + '/img/mobile.png'} alt="Smartphone" />
                <img src={process.env.PUBLIC_URL + '/img/tablette.png'} alt="Tablette" />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };
}
