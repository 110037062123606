import { useEffect, useState } from 'react';
import style from './Select.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';

interface Props {
  children: (React.ReactElement | React.ReactElement[])[];
  value?: string | number;
  onChange?: (newValue: string) => void;
  label?: string;
  isInvalid?: boolean;
}

export default function Select(props: Props) {
  // SOIT si une valeur est passée, on cherche dans le tableau des children lequel a comme valeur la bonne et on prend son contenu
  // SOIT on prend le contenu du premier children
  const [value, setValue] = useState(
    props.children.flat().find((child) => child.props.value === props.value)
      ?.props.children || props.children.flat()[0].props.children
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setValue(
      props.children.flat().find((child) => child.props.value === props.value)
        ?.props.children || props.children.flat()[0].props.children
    );
  }, [props.children, props.value]);

  function handleClick() {
    setIsDropdownOpen((before) => !before);
  }

  function handleClickValue(e: any, value: string) {
    e.stopPropagation();

    // On cherche dans les children quel est celui qui a la bonne valeure et on prend son contenu
    setValue(
      props.children.flat().find((child) => child.props.value === value)?.props
        .children
    );
    setIsDropdownOpen(false);

    if (props.onChange !== undefined) props.onChange(value);
  }

  return (
    <div>
      {props.label !== undefined ? (
        <p className={style.label}>{props.label}</p>
      ) : null}

      <div
        className={classNames(style.select, {
          [style.isInvalid]: props.isInvalid,
        })}
        onClick={handleClick}
      >
        <p>{value}</p>
        <FontAwesomeIcon icon={faCaretDown} />
        <div
          className={classNames(style.dropDown, {
            [style.opened]: isDropdownOpen,
          })}
        >
          {props.children.flat().map((child) => (
            <p onClick={(e) => handleClickValue(e, child.props.value)}>
              {child.props.children}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
