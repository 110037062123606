import { useState } from 'react';

import * as Yup from 'yup';

import style from './Login.module.css';
import { useFormState } from '../../utils/utils';
import { useMutation, useQuery } from 'react-query';
import api from '../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';

import Utils from '../../components/Utils';

export default function Register({ consultants }: { consultants?: boolean }) {
  const { code, signupLink } = useParams<{ code: string | undefined; signupLink: string | undefined }>();

  const [isErrorEmail, setIsErrorEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  interface RegisterInterface {
    email: string;
    password: string;
    repeatPassword: string;
    firstname: string;
    lastname: string;
  }

  const RegisterSchema = Yup.object().shape({
    email: signupLink === undefined ? Yup.string().email().required() : Yup.string().email(),
    password: Yup.string().min(6).required(),
    repeatPassword: Yup.mixed().test({
      name: 'passwordTest',
      message: 'No two password match',
      test: (value: string) => {
        return value === registerFormState.password;
      },
    }),
    firstname: Yup.string().min(2).max(255).required(),
    lastname: Yup.string().min(2).max(255).required(),
  });

  const [registerFormState, handleRegisterInput] = useFormState<RegisterInterface>({
    email: '',
    password: '',
    repeatPassword: '',
    firstname: '',
    lastname: '',
  });

  const { data: company } = useQuery('company_signup_link', () => api.companies.getCompanyBySignupLink(signupLink!), {
    enabled: signupLink !== undefined,
    retry: 2,
  });

  // Requetes à l'API
  const { mutate: registerUser } = useMutation(api.loginApi.register, {
    onSuccess: (data, variable) => {
      setIsRegisterSuccess(true);
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onError: handleError,
  });

  const { mutate: registerWithCode } = useMutation(api.loginApi.registerWithCode, {
    onSuccess: (data, variable) => {
      setIsRegisterSuccess(true);
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onError: handleError,
  });

  const { mutate: registerWithSignupLink } = useMutation(api.loginApi.registerWithSignupLink, {
    onSuccess: (data, variable) => {
      setIsRegisterSuccess(true);
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onError: handleError,
  });

  const { mutate: registerConsultant } = useMutation(api.consultants.register, {
    onSuccess: (data, variable) => {
      // setIsRegisterConsultantSuccess(true);
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onError: handleError,
  });

  function handleError(error: any) {
    let msg = JSON.parse(error.message);

    if (Object.keys(msg.message).includes('email')) {
      setIsErrorEmail(msg.message.email);
    }
  }

  function handleRegister() {
    if (RegisterSchema.isValidSync(registerFormState)) {
      if (code !== undefined) {
        registerWithCode({
          code: code,
          body: {
            ...registerFormState,
            plainPassword: registerFormState.password,
          },
        });
      } else if (signupLink !== undefined) {
        registerWithSignupLink({
          signupLink: signupLink,
          body: {
            ...registerFormState,
            plainPassword: registerFormState.password,
          },
        });
      } else {
        if (consultants === undefined) {
          registerUser({
            ...registerFormState,
            plainPassword: registerFormState.password,
          });
        } else {
          registerConsultant({
            ...registerFormState,
            plainPassword: registerFormState.password,
          });
        }
      }
    }
  }

  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  // const [isRegisterConsultantSuccess, setIsRegisterConsultantSuccess] =
  //   useState(false);

  return (
    <Utils.Container>
      <h1 className={style.title}>Inscrivez vous</h1>

      <Utils.Card style={{ margin: 'auto', width: '25vw', maxWidth: '500px' }}>
        <img
          className={style.logoEntreprise}
          src={company && company.uri !== null ? company.uri : `${process.env.PUBLIC_URL}/logo_entreprise.png`}
          alt="Logo entreprise"
        />

        <Utils.Input
          value={registerFormState.firstname}
          onChange={handleRegisterInput}
          name="firstname"
          placeholder="Prénom"
          isInvalid={!RegisterSchema.fields.firstname.isValidSync(registerFormState.firstname)}
        />

        <Utils.Input
          value={registerFormState.lastname}
          onChange={handleRegisterInput}
          name="lastname"
          placeholder="Nom"
          isInvalid={!RegisterSchema.fields.lastname.isValidSync(registerFormState.lastname)}
        />

        <Utils.Input
          type="email"
          placeholder="Email"
          value={registerFormState.email}
          onChange={handleRegisterInput}
          name="email"
          isInvalid={!RegisterSchema.fields.email.isValidSync(registerFormState.email) || isErrorEmail !== ''}
          isInvalidMessage={
            isErrorEmail
              ? isErrorEmail
              : !RegisterSchema.fields.email.isValidSync(registerFormState.email)
              ? "Votre email n'est pas une adresse email valide"
              : undefined
          }
        />

        <Utils.Input
          placeholder="Mot de passe"
          hidable
          isInvalid={!RegisterSchema.fields.password.isValidSync(registerFormState.password)}
          isInvalidMessage={
            !RegisterSchema.fields.password.isValidSync(registerFormState.password)
              ? 'Votre mot de passe doit faire au moins 6 caractères'
              : undefined
          }
          value={registerFormState.password}
          onChange={handleRegisterInput}
          name="password"
        />

        <Utils.Input
          placeholder="Répétez le mot de passe"
          hidable
          isInvalid={!RegisterSchema.fields.repeatPassword.isValidSync(registerFormState.repeatPassword)}
          isInvalidMessage={
            !RegisterSchema.fields.repeatPassword.isValidSync(registerFormState.repeatPassword)
              ? 'Vos deux mots de passes ne sont pas identiques'
              : undefined
          }
          value={registerFormState.repeatPassword}
          onChange={handleRegisterInput}
          name="repeatPassword"
        />

        <Utils.Button disabled={!RegisterSchema.isValidSync(registerFormState)} isLoading={isLoading} onClick={handleRegister}>
          S'inscrire
        </Utils.Button>

        <Utils.Divider variant="secondary" />

        <Utils.Button onClick={() => navigate('/login')} variant="secondary">
          Se connecter
        </Utils.Button>
      </Utils.Card>

      <Utils.ConfirmModal
        closable={false}
        isOpen={isRegisterSuccess}
        title="Votre inscription est enregistrée"
        text={
          <>
            <span>Vous allez reçevoir un mail pour valider votre inscription.</span>
            <Utils.Button onClick={() => navigate('/login')}>Retour à l'accueil</Utils.Button>
          </>
        }
      />
    </Utils.Container>
  );
}
