import cssStyle from './ImportantNumber.module.css';

interface Props {
  number: number;
  word: string;
  style?: React.CSSProperties;
}

export default function ImportantNumber({ number, word, style }: Props) {
  return (
    <p className={cssStyle.applicationNumber} style={style}>
      <span className={cssStyle.applicationNumberColor}>{number}</span>{' '}
      <span className={cssStyle.word}>
        {word}
        {number > 1 ? 's' : ''}
      </span>
    </p>
  );
}
