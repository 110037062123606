import { ShepherdOptionsWithType } from 'react-shepherd';
import { MY_DOMAIN, simulateMouseClick } from '../utils/utils';

export function discoverApplicationsSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = 'Prochain tutoriel';
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Découvrir et tester une application</span>`;

  const classes: string = 'winylo-onboarding onboarding-discovercategories';

  const defaultDelay: number = 500;

  const stepsLength = 2;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: 'discover-categories-1st',
      attachTo: { element: '.onboarding-discovercategories-1st', on: 'right' },
      advanceOn: { selector: '.onboarding-discovercategories-1st', event: 'click' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          disabled: true,
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            navigate('/apps');
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur l\'onglet <span class="important-text">Applications</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'discover-categories-2nd',
      attachTo: { element: '.onboarding-discovercategories-2nd', on: 'left' },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-back',
          text: backText,
          action() {
            navigate('/');
            this.back();
          },
        },
        {
          classes: 'shepherd-button shepherd-button-next',
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
        <span class="important-text">Passez à l'action !</span><br/>
        <br/>
        Lancez un test de <span class="important-text">14 jours</span> gratuitement sur les<br/>
         applications de votre choix en cliquant sur<br/>
          <span class="important-text">Tester l'application</span>.<br/>`,
        //et cliquez sur le bouton <span class="important-text-black">suivant</span> <img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="14" height="14"/> une fois votre choix fait.
      ],
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate('/');
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: 'discover-categories-3rd',
    //   attachTo: { element: '.onboarding-discovercategories-3rd', on: 'right' },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     // {
    //     //   classes: "shepherd-button shepherd-button-skip",
    //     //   text: cancelText,
    //     //   action() {
    //     //     navigate("/");
    //     //     this.cancel();
    //     //   },
    //     // },
    //     {
    //       classes: 'shepherd-button shepherd-button-back',
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: 'shepherd-button shepherd-button-next',
    //       text: nextText,
    //       action() {
    //         this.next();
    //       },
    //     },
    //   ],
    //   classes,
    //   highlightClass: 'highlight',
    //   scrollTo: false,
    //   title,
    //   text: [
    //     `<span class="important-text">3 sur ${stepsLength}</span><br/>
    //      Cliquez sur <span class="important-text-yellow">Ajouter au panier</span> sur l’application de votre choix.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       navigate('/');
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: 'discover-categories-4th',
    //   attachTo: { element: '.onboarding-discovercategories-4th', on: 'bottom' },
    //   advanceOn: { selector: '.onboarding-discovercategories-4th', event: 'click' },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: 'shepherd-button shepherd-button-back',
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: 'shepherd-button shepherd-button-next onboarding-discovercategories-button-next-4th',
    //       text: nextText,
    //       action() {
    //         var element = document.getElementById('topbar-basket-button') as HTMLElement;
    //         simulateMouseClick(element);
    //       },
    //     },
    //   ],
    //   classes,
    //   highlightClass: 'highlight',
    //   scrollTo: false,
    //   title,
    //   text: [
    //     `<span class="important-text">3 sur ${stepsLength}</span><br/>
    //      Cliquez sur l’icone <span class="important-text">panier</span> <img src="${MY_DOMAIN}/onboarding/cart-shopping-solid.svg" width="14px" height="14px" /> afin de voir les applications dans votre panier.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding / 2,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       navigate('/');
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: 'discover-categories-5th',
    //   attachTo: { element: '.onboarding-discovercategories-5th', on: 'right' },
    //   advanceOn: { selector: '.onboarding-discovercategories-5th', event: 'click' },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: 'shepherd-button shepherd-button-back',
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: 'shepherd-button shepherd-button-next',
    //       text: nextText,
    //       action() {
    //         var element = document.getElementById('onboarding-discovercategories-next-5th');
    //         simulateMouseClick(element);
    //       },
    //     },
    //   ],
    //   classes,
    //   highlightClass: 'highlight',
    //   scrollTo: false,
    //   title,
    //   text: [
    //     `<span class="important-text">4 sur ${stepsLength}</span><br/>
    //     Voici un résumé de votre panier, vous pouvez procéder au paiement en cliquant sur <span class="important-text">Voir le Panier</span>.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       navigate('/');
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: 'discover-categories-6th',
    //   attachTo: { element: '.onboarding-discovercategories-6th', on: 'bottom' },
    //   canClickTarget: false,
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: 'shepherd-button shepherd-button-back',
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: 'shepherd-button shepherd-button-next',
    //       text: nextText,
    //       action() {
    //         this.next();
    //       },
    //     },
    //   ],
    //   classes,
    //   highlightClass: 'highlight',
    //   scrollTo: false,
    //   title,
    //   text: [
    //     `<span class="important-text">5 sur ${stepsLength}</span><br/>
    //     Vous n’avez plus qu’a suivre les étapes pour procéder au paiement.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       navigate('/');
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: 'discover-categories-7th',
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button shepherd-button-nexttutorial',
          text: nextTutoText,
          action() {
            navigate('/?onBoardingStart=2');
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: 'highlight',
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/><br/>
        Vous pouvez vous arrêter là ou bien continuer<br/>
        en lançant le tutoriel <span class="important-text">"Ajouter un utilisateur"</span>.`,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
