import { useState } from 'react';
import { useMutation } from 'react-query';
import api from '../../utils/api';
import { UserContextType, useUser } from '../../utils/context/User';
import Utils from '../Utils';

import style from './BugsModal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function BugsModal(props: Props) {
  const [user] = useUser() as UserContextType;

  const [isLoading, setIsLoading] = useState(false);
  const [bugFormValue, setBugFormValue] = useState<string>('');

  const { mutate: sendBug } = useMutation(api.bugs.sendBug, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setBugFormValue('');
      setIsLoading(false);
      props.onClose();
    },
  });

  function handleCancelBugForm() {
    setBugFormValue('');
    props.onClose();
  }

  function handleSubmitBugForm() {
    sendBug({ userId: user?.id, message: bugFormValue });
  }

  return (
    <Utils.Modal title="Remonter un bug" isOpen={props.isOpen} onClose={props.onClose}>
      <div className={style.bugFormContainer}>
        <Utils.Textarea label="Description du problème" value={bugFormValue} onChange={(e) => setBugFormValue(e.target.value)}></Utils.Textarea>
        <div style={{ display: 'flex', marginTop: '10px', marginBottom: 0, justifyContent: 'space-between' }}>
          <Utils.Button variant="gray" fullWidth={false} format="square" onClick={() => handleCancelBugForm()}>
            Annuler
          </Utils.Button>
          <Utils.Button isLoading={isLoading} format="square" fullWidth={false} onClick={() => handleSubmitBugForm()} style={{ marginLeft: 'auto' }}>
            Envoyer
          </Utils.Button>
        </div>
      </div>
    </Utils.Modal>
  );
}
