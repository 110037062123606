import { useState } from 'react';
import Utils from '../../../components/Utils';
import Winylo from '../../../react_components';
import { MidApplication } from '../../../utils/api/_type';
import api from '../../../utils/api';
import { useMutation, useQueryClient } from 'react-query';

import style from '../css/AskTrialModal.module.css';

interface AskTrialModalProps {
  isOpen: boolean;
  onClose: () => void;
  application: MidApplication | undefined;
}

export default function AskTrialModal({ isOpen, onClose, application }: AskTrialModalProps) {
  const [modalAskTextarea, setModalAskTextarea] = useState<string>('');

  const queryClient = useQueryClient();

  const { mutate: addTrialRequest, isLoading: isLoadingAddTrialRequest } = useMutation(api.trialrequest.addTrialRequest, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries('trialRequests');
    },
  });

  function handleAskTryApplication() {
    if (modalAskTextarea === '') return;

    application &&
      addTrialRequest({
        comment: modalAskTextarea,
        application: application.id,
      });
  }

  return (
    <Winylo.Modal isOpen={isOpen} closable onClose={onClose} title="Demander un test">
      <span className={style.modalTryName}>Nom de l'application</span>
      {application && <Utils.AppPresentation application={application} />}
      <Winylo.TextArea
        label="Motif de la demande"
        rows={5}
        value={modalAskTextarea}
        onChange={(e) => setModalAskTextarea(e.target.value)}
        size="md"
      />

      <div className={style.button}>
        <Winylo.Button
          format="square"
          fullWidth={false}
          onClick={handleAskTryApplication}
          disabled={modalAskTextarea === ''}
          loading={isLoadingAddTrialRequest}
          size="md"
        >
          Demander
        </Winylo.Button>
      </div>
    </Winylo.Modal>
  );
}
