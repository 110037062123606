import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Utils from '../Utils';
import { CompanyProduct, MidApplication, PRODUCT_STATUS } from '../../utils/api/_type';
import { APPLICATIONS_DENOMINATION, InfoModalObject, MORE_INFO_MODAL_TABS } from '../../pages/applications/utils/types';
import capture from './Modal/Capture';
import kaizen from './Modal/Kaizen';
import catalogue from './Modal/Catalogue';
import vizu from './Modal/Vizu';
import Winylo from '../../react_components';
import { UserContextType, useUser } from '../../utils/context/User';
import { isAdmin } from '../../utils/utils';
import { BasketContextType, useBasket } from '../../utils/context/Basket';

import style from './MoreInfoModal.module.css';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  application: MidApplication | undefined;
  setAppOpened: (denomination: string) => void;
  companyApplication: CompanyProduct | undefined;
  handleTryApplication: (application: MidApplication) => void;
  toggleAppInBasket: (application: MidApplication) => void;
}

export default function MoreInfoModal({
  isOpen,
  onClose,
  application,
  setAppOpened,
  companyApplication,
  handleTryApplication,
  toggleAppInBasket,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<MORE_INFO_MODAL_TABS>(MORE_INFO_MODAL_TABS.DESCRIPTION);

  const [basket] = useBasket() as BasketContextType;
  const [user] = useUser() as UserContextType;

  const APPLICATIONS_CONTENT: { [x in APPLICATIONS_DENOMINATION]: InfoModalObject } = {
    [APPLICATIONS_DENOMINATION.capture]: capture(setAppOpened),
    [APPLICATIONS_DENOMINATION.kaizen]: kaizen(setAppOpened),
    [APPLICATIONS_DENOMINATION.catalogue]: catalogue(setAppOpened),
    [APPLICATIONS_DENOMINATION.vizu]: vizu(setAppOpened),
  };

  useEffect(() => {
    setSelectedTab(MORE_INFO_MODAL_TABS.DESCRIPTION);
  }, [application]);

  return (
    <Utils.Modal isOpen={isOpen} width="80%" verticalCenter={false} closable onClose={onClose}>
      {application && (
        <div className={style.twoColumns}>
          <div className={style.leftColumn}>
            <img src={application.fileUri || ''} alt={`Logo de ${application?.name}`} className={style.iconLogo} />
            <p className={style.titleModal}>{application.name}</p>
            <div className={style.divider}></div>

            <div className={style.menuContainer}>
              <span
                className={classNames(style.menuItem, {
                  [style.selected]: selectedTab === MORE_INFO_MODAL_TABS.DESCRIPTION,
                })}
                onClick={() => setSelectedTab(MORE_INFO_MODAL_TABS.DESCRIPTION)}
              >
                Description
              </span>

              {APPLICATIONS_CONTENT[application.denomination as APPLICATIONS_DENOMINATION].associate === undefined ? null : (
                <span
                  className={classNames(style.menuItem, {
                    [style.selected]: selectedTab === MORE_INFO_MODAL_TABS.ASSOCIATE,
                  })}
                  onClick={() => setSelectedTab(MORE_INFO_MODAL_TABS.ASSOCIATE)}
                >
                  Les applications associées
                </span>
              )}

              <span
                className={classNames(style.menuItem, {
                  [style.selected]: selectedTab === MORE_INFO_MODAL_TABS.COMPATIBILITY,
                })}
                onClick={() => setSelectedTab(MORE_INFO_MODAL_TABS.COMPATIBILITY)}
              >
                Compatibilité
              </span>

              {APPLICATIONS_CONTENT[application.denomination as APPLICATIONS_DENOMINATION].video !== undefined && (
                <span
                  className={classNames(style.menuItem, {
                    [style.selected]: selectedTab === MORE_INFO_MODAL_TABS.VIDEO,
                  })}
                  onClick={() => setSelectedTab(MORE_INFO_MODAL_TABS.VIDEO)}
                >
                  Vidéo
                </span>
              )}
            </div>

            <div className={style.bottomButtons}>
              {!user || isAdmin(user) ? (
                !application.comingSoon &&
                (!companyApplication || !companyApplication?.active || companyApplication?.status === PRODUCT_STATUS.TRIAL) ? (
                  basket.map((basketItem) => basketItem.application.id).includes(application.id) ? (
                    <Winylo.Button format="square" fullWidth={true} onClick={() => toggleAppInBasket(application)} color="green" size="md">
                      Retirer du panier
                    </Winylo.Button>
                  ) : (
                    <Winylo.Button
                      className="onboarding-discovercategories-3rd"
                      format="square"
                      fullWidth={true}
                      onClick={() => toggleAppInBasket(application)}
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: '14px',
                        fontWeight: 700,
                      }}
                      color="green"
                      size="md"
                    >
                      Ajouter au panier
                    </Winylo.Button>
                  )
                ) : null
              ) : null}

              {!!companyApplication?.active ? (
                <Winylo.Button
                  format="square"
                  fullWidth={true}
                  onClick={() => window.open(application.urlWeb)}
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    fontWeight: 700,
                  }}
                  size="md"
                >
                  Ouvrir
                </Winylo.Button>
              ) : companyApplication?.status !== PRODUCT_STATUS.TRIAL && application.trialDuration !== null ? (
                <Winylo.Button
                  format="square"
                  fullWidth={true}
                  onClick={() => {
                    onClose();
                    handleTryApplication(application);
                  }}
                  className={style.trialButton}
                  size="md"
                >
                  Tester l'application
                </Winylo.Button>
              ) : null}
            </div>
          </div>

          <div className={style.rightColumn}>
            {selectedTab === MORE_INFO_MODAL_TABS.DESCRIPTION
              ? APPLICATIONS_CONTENT[application.denomination as APPLICATIONS_DENOMINATION].description
              : selectedTab === MORE_INFO_MODAL_TABS.ASSOCIATE
              ? APPLICATIONS_CONTENT[application.denomination as APPLICATIONS_DENOMINATION].associate
              : selectedTab === MORE_INFO_MODAL_TABS.VIDEO
              ? APPLICATIONS_CONTENT[application.denomination as APPLICATIONS_DENOMINATION].video
              : APPLICATIONS_CONTENT[application.denomination as APPLICATIONS_DENOMINATION].compatibility}
          </div>
        </div>
      )}
    </Utils.Modal>
  );
}
