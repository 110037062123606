import { useState, useEffect } from 'react';
import Utils from '../../components/Utils';
import Modal from '../../components/Utils/Modal/Modal';
import { Company, CompanyProduct, MidApplication } from '../../utils/api/_type';
import CSVTab from './CreateUserTabs/CSVTab';
import FormTab from './CreateUserTabs/FormTab';
import ShareTab from './CreateUserTabs/ShareTab';

interface UserCreateModalProps {
  company?: Company;
  applications?: MidApplication[];
  isOpen: boolean;
  onClose: () => void;
}

export default function UserCreateModal(props: UserCreateModalProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <Modal width="80%" verticalCenter title={"Création d'utilisateurs"} isOpen={props.isOpen} onClose={() => props.onClose()} closable>
      <Utils.Tabs
        selectedItem={selectedTab}
        updateSelectedItem={(index) => setSelectedTab(index)}
        items={[
          {
            title: 'Inviter votre équipe',
            renderFunction: (onClose: () => void, company?: Company) => {
              return (
                <>
                  <div
                    id="onboarding-createuser-next-4th"
                    style={{ display: 'none' }}
                    onClick={() => {
                      setSelectedTab(1);
                    }}
                  />
                  <ShareTab company={company} />
                </>
              );
            },
            key: 'via_share',
          },
          {
            title: 'Depuis un formulaire',
            renderFunction: (onClose: () => void, applications?: MidApplication[], companyApplications?: CompanyProduct[]) => (
              <>
                <div
                  id="onboarding-createuser-previous-5th"
                  style={{ display: 'none' }}
                  onClick={() => {
                    setSelectedTab(0);
                  }}
                />
                <div
                  id="onboarding-createuser-next-5th"
                  style={{ display: 'none' }}
                  onClick={() => {
                    setSelectedTab(2);
                  }}
                />
                <FormTab applications={applications} companyApplications={companyApplications} closeModal={onClose} />
              </>
            ),
            key: 'via_form',
          },
          {
            title: 'Depuis un CSV',
            renderFunction: (onClose: () => void, company?: Company) => (
              <>
                <div
                  id="onboarding-createuser-previous-6th"
                  style={{ display: 'none' }}
                  onClick={() => {
                    setSelectedTab(1);
                  }}
                />
                <CSVTab></CSVTab>
              </>
            ),
            key: 'via_csv',
          },
        ]}
        itemsDependencies={{
          via_share: [props.onClose, props.company],
          via_form: [props.onClose, props.applications, props.company?.companyProducts.filter((cp) => cp.type === 'application')],
          via_csv: [props.onClose, props.company],
        }}
      />
    </Modal>
  );
}
