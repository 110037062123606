import { checkStatus, checkStatusQuiet, host } from '../api';
import { User } from './_type';

export interface MeResponse {
  user: User;
  jwt: string;
}

const login = {
  startConnection: ({ login, password }: { login: string; password: string }): Promise<MeResponse> => {
    return fetch(`${host}/api/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ login, password }),
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return res.json().then((msg) => {
            if (msg.message !== undefined) {
              throw new Error(msg.message);
            } else {
              throw new Error('Email ou mot de passe invalide');
            }
          });
        }
      })
      .then((res) => res.json());
  },
  getMe: (): Promise<MeResponse> => {
    return fetch(`${host}/api/me`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return res.text().then((msg: string) => {
            throw new Error(msg);
          });
        }
      })
      .then((res) => res?.json());
  },
  register: (body: { email: string; plainPassword: string; firstname: string; lastname: string }): Promise<any> => {
    return fetch(`${host}/api/register`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  registerWithCode: ({
    code,
    body,
  }: {
    code: string;
    body: {
      email: string;
      plainPassword: string;
      firstname: string;
      lastname: string;
    };
  }): Promise<any> => {
    return fetch(`${host}/api/register/${code}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  registerWithSignupLink: ({
    signupLink,
    body,
  }: {
    signupLink: string;
    body: {
      email?: string;
      plainPassword: string;
      firstname: string;
      lastname: string;
    };
  }): Promise<any> => {
    return fetch(`${host}/api/register/companies/${signupLink}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        email: body.email || undefined,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  forgetPassword: (login: string): Promise<any> => {
    return fetch(`${host}/api/forgetPassword`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ login }),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  checkResetToken: (token: string): Promise<any> => {
    return fetch(`${host}/api/resetPassword/${token}`, {
      method: 'HEAD',
    })
      .then(checkStatusQuiet)
      .then((res) => res.text());
  },
  resetPassword: ({ token, body }: { token: string; body: { password: string; passwordConfirm: string } }): Promise<any> => {
    return fetch(`${host}/api/resetPassword/${token}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  confirmEmail: (token: string): Promise<any> => {
    return fetch(`${host}/api/registration/confirm/${token}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(checkStatusQuiet)
      .then((res) => res.text());
  },
  contact: (body: { from: string; subject: string; message: string }): Promise<any> => {
    return fetch(`${host}/api/contact`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  sendConfirmEmail: (email: string): Promise<any> => {
    return fetch(`${host}/api/send-confirmation-mail`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  sendInvitations: (body: { emails: string[]; subject: string; message: string }): Promise<any> => {
    return fetch(`${host}/api/send-invitation`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default login;
