import classNames from 'classnames';

import style from './Divider.module.css';

interface Props {
  variant: 'primary' | 'secondary' | 'gray';
}

export default function Divider({ variant }: Props) {
  return <div className={classNames(style.divider, style[variant])}></div>;
}
