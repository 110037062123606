import { useState } from 'react';
import { useMutation } from 'react-query';
import api from '../../../utils/api';
import { UserContextType, useUser } from '../../../utils/context/User';
import Utils from '../../../components/Utils';
import { Loader } from '@mantine/core';

import style from './CheckoutLoadingModal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Oui y'a duplication de code tant pis mais malheureusement la vie est faite de compromis
export default function CheckoutLoadingModal(props: Props) {
  return (
    <Utils.Modal title="Accès à votre panier" isOpen={props.isOpen} onClose={props.onClose}>
      <div className={style.content}>
        <div className={style.text}>Chargement de votre session sécurisée en cours...</div>
        <Loader size="xl" />
      </div>
    </Utils.Modal>
  );
}
