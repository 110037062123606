import style from './Checkbox.module.css';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  click?: React.MouseEventHandler<HTMLLabelElement>;
}

export default function Checkbox(props: Props) {
  return (
    <label className={style.checkbox} onClick={props.click}>
      <input type="checkbox" {...props} />
      {props.label}
    </label>
  );
}
