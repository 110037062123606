import React from 'react';

import style from './ErrorMessage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: string;
}

export default function ErrorMessage(props: Props) {
  return (
    <div {...props} className={style.errorMessageContainer}>
      <FontAwesomeIcon className={style.icon} icon={faExclamationTriangle} />
      <p>{props.children}</p>
    </div>
  );
}
