import React, { useState, useEffect } from 'react';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { SortDown, SortUp, FilterLeft } from 'react-bootstrap-icons';

import style from './Table.module.css';

import { isFilter, compare } from '../utils/utils';

export interface Column {
  key: string;
  name: string;
  render?: (value: any, r: Row) => React.ReactElement | string;
  filter?: (value: any, filterState: string) => boolean;
  sort?: (a: Row, b: Row, direction: 'ASC' | 'DESC') => -1 | 0 | 1;
  style?: React.CSSProperties;
  filterSort?: boolean;
}

export interface Row {
  [key: string]: any;
  id: number;
}

interface Props {
  columns: Column[];
  rows: Row[];
  isLoading?: boolean;
}

interface Sort {
  column: string;
  by: 'ASC' | 'DESC';
}

export default function TableComponent({ rows, columns, isLoading }: Props): React.ReactElement {
  // Tri
  const [filterState, setFilterState] = useState<{ [key: string]: string }>({});
  const [keyToColumn, setKeyToColumn] = useState<{ [key: string]: Column }>({});

  useEffect(() => {
    let obj: { [key: string]: string } = {};
    let insideKeyToColumn: { [key: string]: Column } = {};

    columns.forEach((c) => {
      obj[c.key] = '';
      insideKeyToColumn[c.key] = c;
    });

    setKeyToColumn(insideKeyToColumn);
    setFilterState(obj);
  }, [columns]);

  function handleFilterState(e: any) {
    setFilterState((before) => ({
      ...before,
      [e.target.getAttribute('name')]: e.target.value,
    }));
  }

  const [sortBy, setSortBy] = useState<Sort | undefined>(undefined);

  function SorterComponent({ column }: { column: string }): React.ReactElement {
    function changeSortBy() {
      setSortBy((before) => {
        if (before === undefined || before.column !== column) {
          return { column: column, by: 'ASC' };
        } else if (before.by === 'ASC') {
          return { column: column, by: 'DESC' };
        } else {
          return undefined;
        }
      });
    }

    if (sortBy === undefined) return <FilterLeft size={25} style={{ marginLeft: '5px' }} className={style.hoverable} onClick={changeSortBy} />;

    if (sortBy.column !== column) return <FilterLeft size={25} style={{ marginLeft: '5px' }} className={style.hoverable} onClick={changeSortBy} />;

    if (sortBy.by === 'ASC') {
      return <SortUp size={25} style={{ marginLeft: '5px' }} className={style.hoverable} onClick={changeSortBy} />;
    } else {
      return <SortDown size={25} style={{ marginLeft: '5px' }} className={style.hoverable} onClick={changeSortBy} />;
    }
  }

  // Mise en place des lignes affichées
  const [displayedRow, setDisplayedRow] = useState<Row[]>([]);

  useEffect(() => {
    setDisplayedRow(
      rows
        .filter((r) => {
          let displayMe = true;

          columns.forEach((c) => {
            if (c.filter !== undefined) {
              if (!c.filter(r[c.key], filterState[c.key] + '')) {
                displayMe = false;
              }
            } else {
              if (!isFilter('' + r[c.key], filterState[c.key] + '')) {
                displayMe = false;
              }
            }
          });

          return displayMe;
        })
        .sort((a, b) => {
          if (sortBy === undefined) {
            return 0;
          } else {
            let order = sortBy.by === 'ASC' ? 1 : -1;

            if (keyToColumn[sortBy.column].sort !== undefined) {
              return order * (keyToColumn[sortBy.column].sort as Function)(a, b, sortBy.by);
            } else {
              return order * compare(a[sortBy.column], b[sortBy.column]);
            }
          }
        })
    );
  }, [rows, filterState, sortBy, columns, keyToColumn]);

  // Affichage de la réponse
  return (
    <Table className={style.table} responsive>
      <thead>
        <tr>
          {columns.map((c) => (
            <th style={c.style} key={c.key} className={style.head}>
              {c.filterSort === undefined || c.filterSort === true ? (
                <>
                  <Form.Control type="text" placeholder={c.name} value={filterState[c.key]} name={c.key} onChange={handleFilterState} />
                  <SorterComponent column={c.key} />
                </>
              ) : (
                c.name
              )}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {isLoading === true ? (
          <Spinner animation="border" />
        ) : (
          displayedRow.map((r) => (
            <tr key={r.id}>
              {columns.map((c) => (
                <td style={c.style} key={c.key}>
                  {c.render !== undefined ? c.render(r[c.key], r) : r[c.key]}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}
