import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './Sort.module.css';

export type FILTER_OPTION = 'ASC' | 'DESC' | null;

interface Props {
  children?: React.ReactNode;
  order: FILTER_OPTION;
  onChangeOrder: (newOption: FILTER_OPTION) => void;
}

export default function Sort({ children, order, onChangeOrder }: Props) {
  function handleClick() {
    if (order === null) {
      onChangeOrder('ASC');
    } else if (order === 'ASC') {
      onChangeOrder('DESC');
    } else {
      onChangeOrder(null);
    }
  }

  return (
    <div className={style.sort} onClick={handleClick}>
      <FontAwesomeIcon
        icon={order === null ? faSort : order === 'ASC' ? faSortUp : faSortDown}
        className={style.icon}
      />
      {children}
    </div>
  );
}
