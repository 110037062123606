import React, { useRef, useState } from 'react';
import api from '../../utils/api';
import { useMutation } from 'react-query';
import Utils from '../../components/Utils';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Winylo from '../../react_components';
import { Form, Formik, FormikProps, getIn } from 'formik';

import style from './Login.module.css';
import InputLabel from '../../react_components/components/InputLabel';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const initialValues = {
  login: '',
};

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Ce champ est obligatoire'),
});

export default function ForgetPassword(): React.ReactElement {
  const [resetAsked, setResetAsked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useRef<FormikProps<{ login: string }>>(null);
  const navigate = useNavigate();

  const { mutate: resetPassword } = useMutation(api.loginApi.forgetPassword, {
    onSuccess: () => {
      setIsLoading(false);
      setResetAsked(true);
    },
  });

  function onSubmit() {
    if (!formik.current) return;
    setIsLoading(true);
    resetPassword(formik.current.values.login);
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Mot de passe oublié</h1>

      <Utils.Card style={{ marginTop: 0, marginBottom: 0, maxWidth: '450px', width: '100%' }}>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />

        <Formik
          innerRef={formik}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form id="forgetpwd-formik" className={style.simpleForm}>
              <Winylo.TextInput
                label={<InputLabel label="Nom d'utilisateur ou E-mail" accessor="login" errors={errors} icon={faExclamationCircle} />}
                name="login"
                placeholder="Nom d'utilisateur ou E-mail"
                value={values.login}
                onChange={handleChange}
                error={!!getIn(errors, 'login')}
                style={{ width: '100%' }}
              />
            </Form>
          )}
        </Formik>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
          <Winylo.Button color="green" onClick={() => navigate('/login')}>
            Se connecter
          </Winylo.Button>

          <Winylo.Button form={'forgetpwd-formik'} loading={isLoading} type="submit">
            Réinitialiser le mot de passe
          </Winylo.Button>
        </div>
      </Utils.Card>

      <Utils.ConfirmModal
        closable
        isOpen={resetAsked}
        title="Demande envoyée avec succès!"
        text="Vérifiez votre boite mail."
        onClose={() => navigate('/login')}
      />
    </Utils.Container>
  );
}
