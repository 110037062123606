import React from 'react';

import classNames from 'classnames';

import style from './Button.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'green' | 'gray' | 'red';
  fullWidth?: boolean;
  format?: 'round' | 'square';
  additionalClassName?: string;
  containerClassName?: string;
}

export default function Button(props: Props) {
  const { isLoading, ...domProps } = props;

  return (
    <div
      className={classNames(
        style.buttonContainer,
        {
          [style.notFullWidth]: props.fullWidth === false,
        },
        props.containerClassName
      )}
    >
      <button
        {...domProps}
        id={props.id}
        disabled={props.isLoading === true ? true : props.disabled}
        className={classNames(
          style.button,
          {
            [style.isLoading]: props.isLoading === true,
          },
          style[props.variant || 'secondary'],
          {
            [style.square]: props.format === 'square',
          },
          props.additionalClassName
        )}
      >
        {props.children}
      </button>
      {props.isLoading ? <FontAwesomeIcon className={classNames(style.icon, style[props.variant || 'primary'])} icon={faCircleNotch} /> : null}
    </div>
  );
}
