import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';

import { CompanySchema, CompanyUpdateSchema } from '../../../utils/schemas';

import { useFormState } from '../../../utils/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';

import Toastify from 'toastify-js';
import { useParams } from 'react-router-dom';

import style from './CompanyForm.module.css';
import { XCircleFill } from 'react-bootstrap-icons';
import { Company } from '../../../utils/api/_type';

export default function CompanyForm() {
  const { id } = useParams<{ id: string | undefined }>();
  const queryClient = useQueryClient();

  // Récupération des données de la company si édition
  const { data: company, isLoading: isLoadingCompany } = useQuery(
    ['company', parseInt(id === undefined ? '-1' : id)],
    () => api.companies.getOneCompany(parseInt(id === undefined ? '-1' : id)),
    {
      enabled: id !== undefined,
      onSuccess: (data) => {
        setFormField({
          name: data.name,
          code: data.code,
          siret: data.siret + '',
          invoicingEmail: data.invoicingEmail,
          phone: data.phone,
        });
      },
    }
  );
  const { data: applications, isLoading: isLoadingApps } = useQuery('applications', api.applications.getAllApplications, {
    enabled: id !== undefined,
  });

  const [formField, handleForm, setFormField] = useFormState({
    name: '',
    code: '',
    siret: '',
    invoicingEmail: '',
    phone: '',
  });

  useEffect(() => {
    setFormField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { mutate: createCompany } = useMutation(api.companies.createCompany, {
    onSuccess: (data, variable) => {
      setFormField();
      Toastify({
        text: 'Entreprise crée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  function handleAddCompany() {
    createCompany({ ...formField });
  }

  // Partie update d'entreprise
  const { mutate: updateCompany } = useMutation(api.companies.updateCompany, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('companies');
      Toastify({
        text: 'Entreprise crée/modifiée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  function handleUpdate() {
    updateCompany({
      id: parseInt(id === undefined ? '-1' : id),
      body: {
        name: formField.name,
        // code: formField.code,
        invoicingEmail: formField.invoicingEmail,
        phone: formField.phone,
      },
    });
  }

  // Grant/Revoke d'application
  const [currentAddApp, setCurrentAddApp] = useState('-1');

  const { mutate: revokeApp } = useMutation(api.companies.revokeCompanyApplication, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<Company | undefined>(['company', parseInt(id === undefined ? '-1' : id)], (before) =>
        before === undefined
          ? undefined
          : {
              ...before,
              companyApplications: before.companyProducts.filter((ca) => ca.product.id !== variable.idApp),
            }
      );
    },
  });

  const { mutate: grantApp } = useMutation(api.companies.grantCompanyApplication, {
    onSuccess: (data) => {
      // queryClient.setQueryData<Company | undefined>(
      //   ['company', parseInt(id === undefined ? '-1' : id)],
      //   data
      // );
      queryClient.invalidateQueries(['company', parseInt(id === undefined ? '-1' : id)]);
    },
  });

  return (
    <Container>
      <h1 className="center">{id === undefined ? 'Créer' : 'Modifier'} une entreprise</h1>

      {id !== undefined && isLoadingCompany ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Form.Group>
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!CompanySchema.fields.name.isValidSync(formField.name)}
              value={formField.name}
              onChange={handleForm}
              name="name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Code</Form.Label>
            {/* <Form.Control
              type="text"
              isInvalid={!CompanySchema.fields.code.isValidSync(formField.code)}
              value={formField.code}
              onChange={handleForm}
              name="code"
            /> */}
          </Form.Group>

          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!CompanySchema.fields.invoicingEmail.isValidSync(formField.invoicingEmail)}
              value={formField.invoicingEmail}
              onChange={handleForm}
              name="invoicingEmail"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Numéro de téléphone</Form.Label>
            <Form.Control
              type="phone"
              name="phone"
              value={formField.phone}
              onChange={handleForm}
              isInvalid={!CompanySchema.fields.phone.isValidSync(formField.phone)}
            />
          </Form.Group>

          {id !== undefined ? null : (
            <Form.Group>
              <Form.Label>Siren</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!CompanySchema.fields.siret.isValidSync(formField.siret)}
                value={formField.siret}
                onChange={handleForm}
                name="siret"
              />
            </Form.Group>
          )}

          {id === undefined || company === undefined ? (
            <Button disabled={!CompanySchema.isValidSync(formField)} onClick={handleAddCompany}>
              Créer
            </Button>
          ) : (
            <>
              <Button disabled={!CompanyUpdateSchema.isValidSync(formField)} onClick={handleUpdate}>
                Modifier
              </Button>

              <h2>Applications</h2>
              {company.companyProducts
                .filter((cP) => cP.type === 'application')
                .filter((ca) => ca.active)
                .map((ca) => (
                  <div className={style.line}>
                    <XCircleFill
                      size={25}
                      onClick={() =>
                        revokeApp({
                          idCompany: company.id,
                          idApp: ca.product.id,
                        })
                      }
                    />
                    <p>{ca.product.name}</p>
                  </div>
                ))}
              <InputGroup>
                <Form.Control value={currentAddApp} onChange={(e) => setCurrentAddApp(e.target.value)} as="select">
                  {isLoadingApps || applications === undefined ? (
                    <option value="-1">Chargement...</option>
                  ) : (
                    <>
                      <option value="-1">Pas de sélection</option>
                      {applications
                        .filter(
                          (a) =>
                            company.companyProducts.filter((cP) => cP.type === 'application').find((ca) => ca.product.id === a.id) === undefined ||
                            company.companyProducts.filter((cP) => cP.type === 'application').find((ca) => ca.product.id === a.id)?.active === false
                        )
                        .map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.name}
                          </option>
                        ))}
                    </>
                  )}
                </Form.Control>
                <InputGroup.Append>
                  <Button
                    disabled={currentAddApp === '-1'}
                    onClick={() =>
                      grantApp({
                        idApp: parseInt(currentAddApp),
                        idCompany: company.id,
                      })
                    }
                  >
                    Ajouter
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </>
          )}
        </>
      )}
    </Container>
  );
}
