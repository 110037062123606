import { checkStatus, checkStatusQuiet, host } from '../api';
import { dateToString } from '../utils';
import { User } from './_type';

import qs from 'qs';

export interface StatsResponse {
  nbUsers: number;
  nbCompanies: number;
  nbInTest: number;
  nbTestExpired: number;
  nbSubscribed: number;
  nbUnsubscribed: number;
  txSubscribedAfterTest: number;
}

const stats = {
  getStats: ({ dateStart, dateEnd }: { dateStart?: Date; dateEnd?: Date }): Promise<StatsResponse> => {
    let period = ``;

    if (dateStart !== undefined && dateEnd !== undefined) {
      period = `?${qs.stringify({ dateStart: dateToString(dateStart), dateEnd: dateToString(dateEnd) })}`;
    }
    return fetch(`${host}/api/stats${period}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then((res) => checkStatus(res))
      .then((res) => {
        return res.json();
      });
  },
};

export default stats;
