import { checkStatus, host, parseFormData } from '../api';
import {
  Company,
  ParsedCreateUserInterface,
  User,
  CompanyUser,
  CompanyProduct,
  ApplicationStats,
  Subscription,
  MidCompany,
  PageItem,
  MidApplication,
  CompanySignupLink,
  ReferedUser,
  TestingCompany,
  UserFilter,
  ShareFormValue,
  CreateCompanyUserBody,
  ApplicationRole,
} from './_type';

import qs from 'qs';
import { dateToString } from '../utils';

type FILTER_OPTION = 'ASC' | 'DESC' | null;

export interface UserUpdateBody {
  username?: string;
  email?: string | null;
  firstname?: string;
  lastname?: string;
  plainPassword?: string;
  canConnect?: boolean;
}

interface ReferedResponse {
  id: number;
  firstname: string;
  lastname: string;
  company: Company;
}

const companies = {
  registerCompany: (body: { name: string; siret: string; numFiscal: string }): Promise<Company> => {
    return fetch(`${host}/api/companies/register`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  addUserToCompany: ({ body, applicationRoles }: { body: CreateCompanyUserBody; applicationRoles?: ApplicationRole[] }): Promise<User> => {
    return fetch(`${host}/api/companies/users`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify({
        ...body,
        email: body.email || undefined,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  importUsers: ({ format, file }: { format: string; file?: File }): Promise<User[]> => {
    return fetch(`${host}/api/companies/import-users`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: parseFormData({ format, file }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyUsers: ({ page, filters }: { page: number; filters?: UserFilter }): Promise<PageItem<CompanyUser>> => {
    let f: { [key: string]: string } = {};

    if (filters !== undefined) {
      Object.keys(filters).forEach((key) => {
        if (filters[key] !== null) {
          f[key] = filters[key] as string;
        }
      });
    }

    return fetch(`${host}/api/companies/users?${qs.stringify({ page, ...f })}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyUsersWaitingValidationCount: (): Promise<{ count: number }> => {
    return fetch(`${host}/api/companies/users-waiting-validation/count`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },

  updateCompanyUser: ({ id, body, applicationRoles }: { id: number; body: UserUpdateBody; applicationRoles?: ApplicationRole[] }): Promise<User> => {
    return fetch(`${host}/api/companies/users/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteCompanyUser: (id: number) => {
    return fetch(`${host}/api/companies/users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    }).then(checkStatus);
  },
  confirmCompanyUserRegistration: (id: number): Promise<User> => {
    return fetch(`${host}/api/companies/users/${id}/confirm`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  denyCompanyUserRegistration: (id: number): Promise<string> => {
    return fetch(`${host}/api/companies/users/${id}/deny`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  getOneCompany: (id: number): Promise<Company> => {
    return fetch(`${host}/api/companies/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyBySignupLink: (signupLink: string): Promise<CompanySignupLink> => {
    return fetch(`${host}/api/companies/signup-link/${signupLink}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  generateSignupLink: (id: number): Promise<Company> => {
    return fetch(`${host}/api/companies/${id}/signup-link`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteSignupLink: (id: number): Promise<string> => {
    return fetch(`${host}/api/companies/${id}/signup-link`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  getAllCompanies: (): Promise<MidCompany[]> => {
    return fetch(`${host}/api/companies`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  createCompany: (body: { name: string; code: string; siret: string; phone: string }): Promise<Company> => {
    return fetch(`${host}/api/companies`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateCompany: ({
    id,
    body,
  }: {
    id: number;
    body: { numFiscal?: string; name?: string; invoicingEmail?: string; phone?: string };
  }): Promise<Company> => {
    return fetch(`${host}/api/companies/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  companyApplications: (): Promise<CompanyProduct[]> => {
    return fetch(`${host}/api/companies/applications`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  specificCompanyApplication: (id: number): Promise<CompanyProduct[]> => {
    return fetch(`${host}/api/companies/${id}/applications`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyStats: (): Promise<{
    userCount: number;
    activeUserCount: string;
    activeLastWeek: string;
    applications: ApplicationStats[];
  }> => {
    return fetch(`${host}/api/companies/stats`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  grantCompanyApplication: ({ idCompany, idApp }: { idCompany: number; idApp: number }): Promise<Company> => {
    return fetch(`${host}/api/companies/${idCompany}/application/${idApp}/grant`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  revokeCompanyApplication: ({ idCompany, idApp }: { idCompany: number; idApp: number }): Promise<Company> => {
    return fetch(`${host}/api/companies/${idCompany}/application/${idApp}/revoke`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  postBrand: (file: File): Promise<Company> => {
    let data = new FormData();
    data.append('file', file);

    return fetch(`${host}/api/companies/brand`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteBrand: (): Promise<string> => {
    return fetch(`${host}/api/companies/brand`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  getMyCompanySubscription: (): Promise<Subscription | null> => {
    return fetch(`${host}/api/companies/subscription`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  testing: ({ dateStart, dateEnd }: { dateStart?: Date; dateEnd?: Date }): Promise<TestingCompany[]> => {
    let period = ``;

    if (dateStart !== undefined && dateEnd !== undefined) {
      period = `?${qs.stringify({ dateStart: dateToString(dateStart), dateEnd: dateToString(dateEnd) })}`;
    }
    return fetch(`${host}/api/companies/testing${period}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getStorage: (): Promise<{
    available_storage: number;
    used_storage: number;
    remaining_storage: number;
    applications: { used_storage: number; application: MidApplication }[];
  }> => {
    return fetch(`${host}/api/companies/remaining-storage`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyRefered: (): Promise<ReferedUser[]> => {
    return fetch(`${host}/api/companies/refered`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  shareCompanySignupLink: (body: ShareFormValue): Promise<Company> => {
    return fetch(`${host}/api/companies/share-link`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default companies;
