import qs from 'qs';
import { checkStatus, host } from '../api';
import { Invoice, ManualInvoice } from './_type';

export type INVOICE_PERIOD = 'LAST_3_MONTHS' | 'LAST_6_MONTHS' | 'LAST_YEAR' | 'SPECIFIC_YEAR';

interface InvoiceResponse {
  data: Invoice[];
}

const invoices = {
  getInvoices: (invoicePeriod: INVOICE_PERIOD, year: string): Promise<InvoiceResponse> => {
    let url = `${host}/api/invoices?invoicePeriod=${invoicePeriod}`;

    if (year.length === 4) url = url + '&year=' + year;

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAdminInvoices: (startAfter?: string): Promise<InvoiceResponse> => {
    let url = `${host}/api/admin/invoices?${qs.stringify({ startAfter })}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getInvoice: (invoiceId: string): any => {
    let url = `${host}/api/invoices/${invoiceId}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  downloadInvoice: (invoice: string): Promise<Blob> => {
    return fetch(invoice, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.blob());
  },
  getManualInvoices: (companyId: number): Promise<ManualInvoice[]> => {
    return fetch(`${host}/api/invoices/manual/${companyId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default invoices;
