import { useEffect, useState } from 'react';

import api from '../../utils/api';
import { useNavigate, useParams } from 'react-router';
import Utils from '../../components/Utils';

import style from './Login.module.css';

export default function RegisterConfirm() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [isRequestFinished, setIsRequestFinished] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!token) return;

    api.loginApi
      .confirmEmail(token)
      .then(() => {
        setIsRequestFinished(true);

        setTimeout(() => {
          navigate('/login');
        }, 5000);
      })
      .catch((err) => {
        setErrorMessage('Lien de confirmation invalide.');
      });
  }, [token, navigate]);

  return (
    <Utils.Container>
      <Utils.Card>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />
        {errorMessage !== '' ? (
          <>
            <Utils.ErrorMessage>{errorMessage}</Utils.ErrorMessage>
            <Utils.Button
              style={{ marginTop: '40px' }}
              variant="secondary"
              onClick={() => {
                navigate('/login');
              }}
            >
              Retour à l'accueil
            </Utils.Button>
          </>
        ) : isRequestFinished ? (
          <>
            <p className={style.valideMessage}>Votre email est bien vérifié! Vous allez être redirigé sur la page de connexion</p>
            <Utils.Button
              style={{ marginTop: '40px' }}
              variant="secondary"
              onClick={() => {
                navigate('/login');
              }}
            >
              Se connecter
            </Utils.Button>
          </>
        ) : (
          <Utils.Spinner center size="50px" />
        )}
      </Utils.Card>
    </Utils.Container>
  );
}
