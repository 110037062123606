import { useEffect, useState } from 'react';

import style from '../css/QRCodeSignupLink.module.css';
import QRCode from 'react-qr-code';

interface Props {
  link: string;
  text?: string;
  qrRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function QRCodeSignupLink({ link, text, qrRef }: Props) {
  // const [qrOptions, setQrOptions] = useState<{ [key: string]: any }>({});

  // useEffect(() => {
  //   setQrOptions({
  //     text: link,
  //     colorLight: '#ffffff',
  //     dotScale: 1,
  //     logoScale: 0.3,
  //     logoCornerRadius: 50,
  //     margin: 0,
  //   });
  // }, [link]);

  return (
    <div className={style.container}>
      <p
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: 0,
        }}
      >
        {text}
      </p>
      <div ref={qrRef} className={style.qr}>
        <QRCode value={link} />
        {/* {qrOptions === undefined ? null : <AwesomeQRCode options={qrOptions} />} */}
      </div>
    </div>
  );
}
