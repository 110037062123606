import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import './form.css';
import ContextProvider from './utils/context/ContextProvider';
import ErrorCatcher from './components/ErrorCatcher';
import { STRIPE_KEY } from './utils/stripe';
import * as Sentry from '@sentry/react';
import './react_components/css/index.css';
import { createRoot } from 'react-dom/client';

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};

  // Initialize Sentry
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAY_RATE ?? '0.1'),
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: process.env.REACT_APP_VERSION ?? '0.0.0',
  });
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Create Stripe
const stripePromise = loadStripe(STRIPE_KEY);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorCatcher>
      <Elements stripe={stripePromise}>
        <ContextProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ContextProvider>
      </Elements>
    </ErrorCatcher>
  </React.StrictMode>
);
