import { useState } from 'react';
import api from '../../utils/api';

import Utils from '../../components/Utils';

import style from './Login.module.css';
import { useNavigate } from 'react-router-dom';

export default function ResendEmail() {
  const [isResendSuccess, setIsResendSuccess] = useState(false);

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleConfirmEmail() {
    setIsLoading(true);
    api.loginApi
      .sendConfirmEmail(email)
      .then(() => {
        setIsLoading(false);
        setIsResendSuccess(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Renvoyer mon email</h1>
      <Utils.Card>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />
        <p>Vous n'avez pas reçus votre email de validation? Utilisez cette page pour en renvoyer un </p>
        <p>
          Vous pouvez vérifiez dans vos spam. Vous pouvez aussi ajouter le domaine <b>c-koya.tech</b> en liste blanche.
        </p>
        <Utils.Input placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} isInvalid={email === ''} />

        <Utils.Button onClick={handleConfirmEmail} disabled={email === ''} isLoading={isLoading}>
          Renvoyer la validation
        </Utils.Button>
        <Utils.Divider variant="secondary" />

        <Utils.Button variant="secondary" onClick={() => navigate('/login')}>
          Se connecter
        </Utils.Button>
      </Utils.Card>

      <Utils.ConfirmModal isOpen={isResendSuccess} closable={false} title="E-mail renvoyé avec succès" text="Vérifiez votre boite mail." />
    </Utils.Container>
  );
}
