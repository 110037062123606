import React from "react";
import classNames from "classnames";
import ReactModal from "react-modal";
import { ReactSVG } from "react-svg";

import style from "../../css/old/Modal.module.css";

interface Props extends ReactModal.Props {
    title?: string;
    onClose?: () => void;
    closable?: boolean;
}

export default function Modal({ title, onClose, closable, ...props }: Props) {
    return (
        <ReactModal
            {...props}
            className={classNames(style.modal, props.className)}
            overlayClassName={classNames(style.overlay, props.overlayClassName)}
            isOpen={props.isOpen}
            onRequestClose={closable !== false ? onClose : undefined}
        >
            {title && (
                <>
                    <div className={style.titleContainer}>
                        <div></div>
                        <div className={style.title}>{title}</div>
                        {closable !== false && (
                            <ReactSVG
                                src={"../../svg/xmark-solid.svg"}
                                className={style.close}
                                onClick={onClose}
                            />
                        )}
                    </div>
                    <div className={style.separator}></div>
                </>
            )}
            <div>{props.children}</div>
        </ReactModal>
    );
}
