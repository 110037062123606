import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import Utils from '../../../components/Utils';
import { FILTER_OPTION } from '../../../components/Utils/Sort/Sort';
import api from '../../../utils/api';
import { MidApplication, TrialRequest as TrialRequestType } from '../../../utils/api/_type';
import { TITLES } from '../../../utils/constantes';
import { dateToString, useDebounce } from '../../../utils/utils';

import style from './TrialRequest.module.css';

export default function TrialRequest() {
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useDebounce('', (val) => {
    setFilters((b) => ({ ...b, global: val }));
  });

  const initialFiltersValue = {
    global: '',
    applicationName: {
      value: [],
      sort: null,
    },
    status: {
      value: [],
      sort: null,
    },
    createdBy: {
      value: '',
      sort: null,
    },
    createdAt: {
      value: '',
      sort: null,
    },
  };

  const [filters, setFilters] = useState<{
    global: string;
    applicationName: {
      value: string[];
      sort: FILTER_OPTION;
    };
    status: {
      value: string[];
      sort: FILTER_OPTION;
    };
    createdBy: {
      value: string;
      sort: FILTER_OPTION;
    };
    createdAt: {
      value: string;
      sort: FILTER_OPTION;
    };
  }>(initialFiltersValue);

  const {
    data: trialRequests,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(['trialRequests', filters], ({ pageParam = 1 }) => api.trialrequest.getTrialRequest({ page: pageParam, filters }), {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.current < lastPage.pagination.endPage) {
        return lastPage.pagination.current + 1;
      } else {
        return undefined;
      }
    },
    keepPreviousData: true,
  });

  function resetFilters() {
    setFilters(initialFiltersValue);
    setUserSearch('');
  }

  function getTrialRequest(): TrialRequestType[] {
    if (trialRequests === undefined || trialRequests.pages === undefined) return [];

    return trialRequests.pages.map((page) => page.items).flat();
  }

  useEffect(() => {
    window.addEventListener('scroll', eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      window.removeEventListener('scroll', eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  // Partie modification des trialRequest
  const [modalConfirmOpen, setModalConfirmOpen] = useState<undefined | { type: 'granted' | 'refused'; request: TrialRequestType }>();
  const [textareaConfirm, setTextareaConfirm] = useState('');

  const { mutate: updateTrialRequest, isLoading: isLoadingUpdate } = useMutation(api.trialrequest.updateTrialRequest, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('trialRequests');
      setModalConfirmOpen(undefined);
    },
  });

  function handleConfirmModal() {
    if (modalConfirmOpen === undefined) return;

    updateTrialRequest({
      id: modalConfirmOpen.request.id,
      body: {
        status: modalConfirmOpen.type,
        response: textareaConfirm,
      },
    });
  }

  // Affichage des détails
  const [detailsModalOpen, setDetailModalOpen] = useState<undefined | TrialRequestType>(undefined);

  function getNumberOfTrial() {
    if (trialRequests === undefined || trialRequests.pages === undefined) {
      return 0;
    }

    return trialRequests?.pages[0]?.pagination.totalCount;
  }

  // Récupération du nom des applications
  const { data: applications, isLoading: isLoadingApp } = useQuery('applications', api.applications.getAllApplications);

  function handleClickName(e: any, app: MidApplication) {
    if (e.target.checked) {
      setFilters((b) => ({
        ...b,
        applicationName: {
          ...b.applicationName,
          value: [...b.applicationName.value, app.name],
        },
      }));
    } else {
      setFilters((b) => ({
        ...b,
        applicationName: {
          ...b.applicationName,
          value: b.applicationName.value.filter((a) => a !== app.name),
        },
      }));
    }
  }

  function handleStatus(e: any, status: string) {
    if (e.target.checked) {
      setFilters((b) => ({
        ...b,
        status: {
          ...b.status,
          value: [...b.status.value, status],
        },
      }));
    } else {
      setFilters((b) => ({
        ...b,
        status: {
          ...b.status,
          value: b.status.value.filter((s) => s !== status),
        },
      }));
    }
  }

  const [userSearch, setUserSearch] = useDebounce('', (val) => {
    setFilters((b) => ({ ...b, createdBy: { ...b.createdBy, value: val } }));
  });

  function isGlobalFilterDisabled() {
    return filters.applicationName.value.length !== 0 || filters.status.value.length !== 0 || filters.createdBy.value !== '';
  }

  useEffect(() => {
    document.title = TITLES.TRIALS_LIST;
  }, []);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Demandes de tests" width="100%" isLoading={isLoading}>
        <>
          <div className={style.tabTop}>
            <Utils.Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              inverted={true}
              icon={faSearch}
              placeholder="Rechercher"
              width="250px"
              disabled={isGlobalFilterDisabled()}
            />

            <Utils.ImportantNumber word="Demande" number={getNumberOfTrial()} style={{ marginLeft: '20px' }} />
          </div>

          <div className={style.twoColumns}>
            <Utils.SortMenu title="Affiner la recherche" clear={() => resetFilters()}>
              <>
                <Utils.SortMenuRow title="Nom de l'application">
                  {isLoadingApp || applications === undefined ? (
                    <Utils.Spinner center />
                  ) : (
                    applications.map((app) => (
                      <Utils.Checkbox
                        key={app.id}
                        label={app.name}
                        checked={filters.applicationName.value.includes(app.name)}
                        onClick={(e) => handleClickName(e, app)}
                      />
                    ))
                  )}
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Status">
                  <Utils.Checkbox label="En attente" checked={filters.status.value.includes('pending')} onClick={(e) => handleStatus(e, 'pending')} />
                  <Utils.Checkbox label="Validé" checked={filters.status.value.includes('granted')} onClick={(e) => handleStatus(e, 'granted')} />
                  <Utils.Checkbox label="Refusé" checked={filters.status.value.includes('refused')} onClick={(e) => handleStatus(e, 'refused')} />
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Auteur">
                  <Utils.Input
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                    colorReverse
                    placeholder="Rechercher"
                    clear={() => {
                      setUserSearch('');
                    }}
                  />
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Date de la demande">
                  <Utils.Input
                    type="date"
                    colorReverse
                    value={filters.createdAt.value}
                    onChange={(e) =>
                      setFilters((b) => ({
                        ...b,
                        createdAt: { ...b.createdAt, value: e.target.value },
                      }))
                    }
                  />
                </Utils.SortMenuRow>
              </>
            </Utils.SortMenu>
            <div className={style.rightPart}>
              <Utils.Table>
                <thead>
                  <th>
                    <Utils.Sort
                      order={filters.applicationName.sort}
                      onChangeOrder={(order) =>
                        setFilters((b) => ({
                          ...b,
                          applicationName: {
                            ...b.applicationName,
                            sort: order,
                          },
                        }))
                      }
                    >
                      Nom de l'application
                    </Utils.Sort>
                  </th>
                  <th>
                    <Utils.Sort
                      order={filters.createdBy.sort}
                      onChangeOrder={(order) =>
                        setFilters((b) => ({
                          ...b,
                          createdBy: {
                            ...b.createdBy,
                            sort: order,
                          },
                        }))
                      }
                    >
                      Auteur
                    </Utils.Sort>
                  </th>
                  <th>
                    <Utils.Sort
                      order={filters.createdAt.sort}
                      onChangeOrder={(order) =>
                        setFilters((b) => ({
                          ...b,
                          createdAt: {
                            ...b.createdAt,
                            sort: order,
                          },
                        }))
                      }
                    >
                      Date de la demande
                    </Utils.Sort>
                  </th>
                  <th>Motif de la demande</th>
                  <th>
                    <Utils.Sort
                      order={filters.status.sort}
                      onChangeOrder={(order) =>
                        setFilters((b) => ({
                          ...b,
                          status: {
                            ...b.status,
                            sort: order,
                          },
                        }))
                      }
                    ></Utils.Sort>
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  {getTrialRequest().map((trial) => (
                    <tr key={trial.id}>
                      <td>
                        <Utils.AppPresentation application={trial.application} />
                      </td>
                      <td>{trial.createdBy.username}</td>
                      <td>{dateToString(trial.createdAt)}</td>
                      <td>{trial.comment}</td>
                      {trial.status === 'pending' ? (
                        <>
                          <td>
                            <Utils.Button
                              format="square"
                              onClick={() => {
                                setTextareaConfirm('');
                                setModalConfirmOpen({
                                  type: 'refused',
                                  request: trial,
                                });
                              }}
                            >
                              Refuser
                            </Utils.Button>
                          </td>
                          <td>
                            <Utils.Button
                              format="square"
                              onClick={() => {
                                setTextareaConfirm('');
                                setModalConfirmOpen({
                                  type: 'granted',
                                  request: trial,
                                });
                              }}
                            >
                              Valider
                            </Utils.Button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td className={style.status}>
                            {trial.status === 'granted' ? <span className={style.green}>Acceptée</span> : <span className={style.red}>Refusée</span>}
                          </td>
                          <td>
                            <Utils.Button format="square" variant="secondary" onClick={() => setDetailModalOpen(trial)} style={{ fontWeight: 700 }}>
                              Détails
                            </Utils.Button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Utils.Table>
              {isFetching ? <Utils.Spinner center size="52px" /> : null}
            </div>
          </div>
        </>
      </Utils.Card>

      <Utils.Modal
        isOpen={modalConfirmOpen !== undefined}
        closable
        onClose={() => setModalConfirmOpen(undefined)}
        title={`${modalConfirmOpen?.type === 'granted' ? 'Validation' : 'Refus'} de la demande de test`}
        width="900px"
      >
        <>
          <Utils.Table>
            <thead>
              <th>Nom de l'application</th>
              <th>Auteur</th>
              <th>Date de la demande</th>
              <th>Motif de la demande</th>
            </thead>
            <tbody>
              <tr>
                <td>{modalConfirmOpen !== undefined ? <Utils.AppPresentation application={modalConfirmOpen?.request.application} /> : null}</td>
                <td>{modalConfirmOpen?.request.createdBy.username}</td>
                <td>{dateToString(modalConfirmOpen?.request.createdAt || new Date())}</td>
                <td>{modalConfirmOpen?.request.comment}</td>
              </tr>
            </tbody>
          </Utils.Table>

          <p className={style.detailsTitle}>Motif de la demande</p>
          <p>{modalConfirmOpen?.request.comment}</p>

          <Utils.Textarea
            value={textareaConfirm}
            onChange={(e) => setTextareaConfirm(e.target.value)}
            label={`Motif ${modalConfirmOpen?.type === 'granted' ? 'de la validation' : 'du refus'}`}
            rows={4}
          />

          <Utils.Button format="square" fullWidth={false} onClick={handleConfirmModal} isLoading={isLoadingUpdate}>
            {modalConfirmOpen?.type === 'granted' ? 'Valider' : 'Refuser'}
          </Utils.Button>
        </>
      </Utils.Modal>

      <Utils.Modal
        isOpen={detailsModalOpen !== undefined}
        closable
        onClose={() => setDetailModalOpen(undefined)}
        title="Détails de la demande de test"
        width="900px"
      >
        <>
          <Utils.Table>
            <thead>
              <th>Nom de l'application</th>
              <th>Auteur</th>
              <th>Date de la demande</th>
              <th>Date de {detailsModalOpen?.status === 'granted' ? 'validation' : 'refus'}</th>
            </thead>
            <tbody>
              <tr>
                <td>{detailsModalOpen !== undefined ? <Utils.AppPresentation application={detailsModalOpen?.application} /> : null}</td>
                <td>{detailsModalOpen?.createdBy.username}</td>
                <td>{dateToString(detailsModalOpen?.createdAt || new Date())}</td>
                <td>{detailsModalOpen?.comment}</td>
              </tr>
            </tbody>
          </Utils.Table>

          <p className={style.detailsTitle}>Motif de la demande</p>
          <p>{detailsModalOpen?.comment}</p>

          <p className={style.detailsTitle}>Motif de {detailsModalOpen?.status === 'granted' ? 'validation' : 'refus'}</p>
          <p>{detailsModalOpen?.response}</p>
        </>
      </Utils.Modal>
    </Utils.Container>
  );
}
