import React from "react";
import classNames from "classnames";

import style from "../../css/old/ProgressBar.module.css";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  progress?: number;
}

export default function ProgressBar(props: Props) {
  function progress(): string {
    let progress = "0px";
    if (props.progress !== undefined) {
      progress = props.progress > 100 ? "100%" : props.progress + "%";
    }
    return progress;
  }

  return (
    <div {...props} className={classNames(style.container, props.className)}>
      <div
        className={style.progress}
        style={{
          width: progress(),
        }}
      ></div>
    </div>
  );
}
