import React, { useEffect, useState } from 'react';

import style from './CompanyForm.module.css';

import { getBase64, useFormState } from '../../utils/utils';
import { CompanyInterface, CompanySchema, CompanySchemaWithoutSiret } from '../../utils/schemas';
import { useMutation, useQuery } from 'react-query';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { UserContextType, useUser } from '../../utils/context/User';
import { Company, ROLE } from '../../utils/api/_type';
import Utils from '../../components/Utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPen } from '@fortawesome/free-solid-svg-icons';
import { TITLES } from '../../utils/constantes';
import Winylo from '../../react_components';
import { vatVerifiedEnum } from '../../utils/enums/vatVerifiedEnum';

interface Props {
  update: boolean;
}

export default function CreateCompany({ update }: Props) {
  const [user, setUser] = useUser() as UserContextType;
  const navigate = useNavigate();

  const [company, setCompany] = useState<Company>();
  const [vatVerified, setVatVerified] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  let schema = update ? CompanySchemaWithoutSiret : CompanySchema;

  useEffect(() => {
    if (user?.company !== null && !update) {
      navigate('/company');
    }
  }, [user, navigate, update]);

  useEffect(() => {
    document.title = TITLES.COMPANY_PROFILE;
  }, []);

  const [formState, handleInput, setFormState] = useFormState<CompanyInterface>({
    siret: '',
    numFiscal: '',
    name: '',
    invoicingEmail: update ? '' : user?.email || '',
    phone: '',
  });

  function handleInputName(e: any) {
    setFormState((b) => ({ ...b, name: e.target.value }));
  }

  const [newImage, setNewImage] = useState<undefined | File>(undefined);
  const [beforeImageURI, setBeforeImageURI] = useState<string | null>(null);

  const [isSaveComplete, setIsSaveComplete] = useState(false);

  const { isLoading } = useQuery(['company', user?.company?.id], () => api.companies.getOneCompany(user?.company?.id || -1), {
    enabled: update,
    onSuccess: (data) => {
      setFormState({
        siret: data.siret === null ? '' : data.siret,
        numFiscal: data.numFiscal === null ? '' : data.numFiscal,
        name: data.name,
        invoicingEmail: data.invoicingEmail,
        phone: data.phone,
      });

      setCompany(data);
      setBeforeImageURI(data.uri);
    },
  });

  const { mutate: createCompany } = useMutation(api.companies.registerCompany, {
    onSuccess: async (data) => {
      if (newImage !== undefined) {
        await api.companies.postBrand(newImage);
      }

      setUser((before) =>
        before === undefined
          ? undefined
          : {
              ...before,
              roles: [...before.roles, ROLE.ROLE_COMPANY_ADMIN],
              company: data,
            }
      );
    },
    onMutate: () => {
      setIsSaveLoading(true);
    },
    onSettled: () => {
      setIsSaveLoading(false);
    },
  });

  const { mutate: updateCompany } = useMutation(api.companies.updateCompany, {
    onSuccess: async (data) => {
      if (newImage !== undefined) {
        await api.companies.postBrand(newImage);
      }

      setIsSaveComplete(true);
    },
    onMutate: () => {
      setIsSaveLoading(true);
    },
    onSettled: () => {
      setIsSaveLoading(false);
    },
  });

  function handleCreateCompany() {
    if (schema.isValidSync(formState)) {
      if (update) {
        updateCompany({
          id: user?.company?.id || -1,
          body: {
            numFiscal: formState.numFiscal,
            name: formState.name,
            invoicingEmail: formState.invoicingEmail,
            phone: formState.phone,
          },
        });
      } else {
        createCompany({
          ...formState,
          siret: formState.siret,
          numFiscal: formState.numFiscal,
        });
      }
    }
  }

  // Changement de l'image
  function onOpenFileChooser() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg';
    input.setAttribute('style', 'display: none');

    input.addEventListener('change', handleChangeFile);

    document.body.appendChild(input);

    input.click();
    document.body.removeChild(input);

    function handleChangeFile(ev: Event) {
      let files = (ev.target as HTMLInputElement).files;

      if (files !== null && files.length !== 0) {
        setNewImage(files[0]);
        getBase64(files[0]).then((base64) => {
          setBeforeImageURI(base64);
        });
      }

      input.removeEventListener('change', handleChangeFile);
    }
  }

  useEffect(() => {
    setVatVerified(
      company?.vatVerified === vatVerifiedEnum.VERIFIED ||
        company?.vatVerified === vatVerifiedEnum.EXONERATED ||
        company?.vatVerified === vatVerifiedEnum.PENDING
    );
  }, [company]);

  return (
    <Utils.Container verticalCenter={true}>
      <Utils.Card title={update ? 'Modifiez votre entreprise' : 'Créez votre entreprise'} width="717px" isLoading={isLoading}>
        <div className={style.twoColumn}>
          <div className={style.leftDiv}>
            <div className={style.imageContainer} onClick={onOpenFileChooser}>
              {beforeImageURI !== null ? (
                <img src={beforeImageURI} alt="Logo de votre entreprise" className={style.logoBefore} />
              ) : (
                <FontAwesomeIcon icon={faCamera} className={style.noImageIcon} />
              )}

              <div className={style.editIconContainer}>
                <FontAwesomeIcon icon={faPen} className={style.editIcon} />
              </div>
            </div>
            {beforeImageURI === null && <p>Vous n'avez pas encore de logo...</p>}
            <p>Le logo de votre entreprise sera affiché sur la page de connexion dédié à votre entreprise</p>
          </div>
          <div>
            <p className={style.infoText}>Tous les champs marqués d'une étoile sont obligatoires</p>

            <Winylo.TextInput
              className={style.formElement}
              label="SIREN"
              required
              placeholder="exemple : 000000000"
              name="siret"
              value={formState.siret}
              onChange={handleInput}
              error={!update && !(schema as any).fields.siret.isValidSync(formState.siret)}
              readOnly={update}
              description={
                !update ? (
                  <span style={{ color: 'var(--unselected-icon)', fontWeight: 500 }}>
                    Retrouvez votre n°SIREN sur{' '}
                    <a href="https://societe.com" target="_blank" rel="noreferrer">
                      Societe.com
                    </a>
                  </span>
                ) : undefined
              }
            />

            <Winylo.TextInput
              className={style.formElement}
              label="Nom de l'entreprise"
              required
              name="name"
              value={formState.name}
              readOnly={update}
              onChange={handleInputName}
              error={!schema.fields.name.isValidSync(formState.name)}
              description={update ? <>Contactez nous si le nom de votre entreprise doit être modifié.</> : undefined}
              style={{ marginTop: '1rem' }}
            />

            <Winylo.TextInput
              className={style.formElement}
              label="Numéro de TVA intracommunautaire"
              required
              placeholder="exemple : FR32123456789 "
              name="numFiscal"
              value={formState.numFiscal}
              onChange={handleInput}
              error={
                (!(update && !!company?.numFiscal) && !(schema as any).fields.numFiscal.isValidSync(formState.numFiscal)) || (update && !vatVerified)
              }
              readOnly={update && !!company?.numFiscal && vatVerified}
              description={
                !(update && !!company?.numFiscal) ? (
                  <span style={{ color: 'var(--unselected-icon)', fontWeight: 500 }}>
                    Retrouvez votre n°TVA intracommunautaire sur{' '}
                    <a href="https://societe.com" target="_blank" rel="noreferrer">
                      Societe.com
                    </a>
                    <br />
                    <i>D'autres sites peuvent être nécéssaires pour les entreprises externes à la France.</i>
                    <br />
                    <i>
                      Si vous êtes éxonéré de TVA, veuillez-nous contacter à <a href="mailto:contact@c-koya.tech">contact@c-koya.tech</a>.
                    </i>
                  </span>
                ) : update ? (
                  !vatVerified ? (
                    <span style={{ color: 'red' }}>Votre numéro de TVA n'a pas pu être vérifié, veuillez le mettre à jour.</span>
                  ) : (
                    <>Contactez-nous si le numéro de TVA intracommunautaire doit être modifié.</>
                  )
                ) : undefined
              }
            />

            {/* <Winylo.Select
              label="Période des factures"
              name="billingPeriod"
              data={[
                {
                  label: '3 Derniers Mois',
                  value: 'LAST_3_MONTHS',
                },
                {
                  label: '6 Derniers Mois',
                  value: 'LAST_6_MONTHS',
                },
                {
                  label: 'Cette année',
                  value: 'LAST_YEAR',
                },
              ]}
            /> */}

            {/* <Utils.Input
              label="Code*"
              subtext="Préfix utilisé lors de la connexion des utilisateurs. Cela peut être le nom ou un diminutif de l'entreprise, ou autre chose. Lors de la connexion, l'utilisateur se connectera code\identifiant. Exemple : inari\jeant"
              name="code"
              value={formState.code}
              onChange={handleInputCode}
              isInvalid={!schema.fields.code.isValidSync(formState.code)}
            /> */}

            <Winylo.TextInput
              className={style.formElement}
              label="Email de facturation"
              type="email"
              name="invoicingEmail"
              value={formState.invoicingEmail}
              onChange={handleInput}
              error={!schema.fields.invoicingEmail.isValidSync(formState.invoicingEmail)}
            />

            <Winylo.TextInput
              className={style.formElement}
              label="Numero de téléphone"
              required
              type="string"
              name="phone"
              value={formState.phone}
              onChange={handleInput}
              error={!schema.fields.phone.isValidSync(formState.phone)}
            />

            <Winylo.Button
              className={style.formElement}
              disabled={!schema.isValidSync(formState) || isSaveLoading}
              onClick={handleCreateCompany}
              loading={isSaveLoading}
              fullWidth={true}
              size="md"
              style={{
                backgroundColor: '#00953B',
              }}
            >
              {update ? "Modifier l'entreprise" : "Créer l'entreprise"}
            </Winylo.Button>
          </div>
        </div>
      </Utils.Card>

      <Utils.ConfirmModal
        isOpen={isSaveComplete}
        closable={true}
        onClose={() => setIsSaveComplete(false)}
        title="Modification terminée"
        text="Les modifications ont été apportées à votre entreprise"
      />
    </Utils.Container>
  );
}
