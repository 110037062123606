import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { Check } from 'react-bootstrap-icons';
import { User } from '../../../utils/api/_type';
import { dateToString } from '../../../utils/utils';

export default function Unconfirmed() {
  const queryClient = useQueryClient();

  const { data: consultants, isLoading } = useQuery(['consultantsUnconfirmed'], api.consultants.getUnconfirmed);

  const { mutate: confirmConsultant } = useMutation(api.consultants.confirm, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<User[] | undefined>(['consultantsUnconfirmed'], (before) =>
        before === undefined ? undefined : before.filter((b) => b.id !== variable)
      );
    },
  });

  return (
    <>
      <Container>
        <h1>Consultants non confirmés</h1>
      </Container>

      {isLoading || consultants === undefined ? (
        <Spinner animation="border" />
      ) : (
        <Table responsive>
          <thead>
            <tr>
              <th>Prénom Nom</th>
              <th>Email</th>
              <th>Date de création</th>
              <th>Valider</th>
            </tr>
          </thead>
          <tbody>
            {consultants.map((consu) => (
              <tr key={consu.id}>
                <td>
                  {consu.firstname} {consu.lastname}
                </td>
                <td>{consu.email}</td>
                <td>{dateToString(consu.createdAt)}</td>
                <td>
                  <Button onClick={() => confirmConsultant(consu.id)} variant="success">
                    <Check />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}
