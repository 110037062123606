import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';
import TableComponent, { Column } from '../../../components/Table';
import Button from '../../../components/Utils/Button/Button';
import Container from '../../../components/Utils/Container/Container';
import Spinner from 'react-bootstrap/Spinner';

import style from './css/PatchnotesList.module.css';
import { dateToString } from '../../../utils/utils';

interface PatchnotesListProps {}

export default function PatchnotesList(props: PatchnotesListProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Récupération des données
  const { data: patchnotes, isLoading } = useQuery('patchnotes', api.patchNotes.getAllPatchnotes);

  const { mutate: deletePatchnote } = useMutation(api.patchNotes.deletePatchnote, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries(['patchnotes']);
    },
    onError: () => {},
  });

  const columns: Column[] = [
    { key: 'id', name: 'ID' },
    { key: 'title', name: 'Titre' },
    { key: 'content', name: 'Contenu' },
    { key: 'type', name: 'Type' },
    { key: 'hidden', name: 'Visible', render: (val) => (val ? 'Non' : 'Oui') },
    {
      key: 'scheduledAt',
      name: 'Date',
      render: (val, r) => {
        return dateToString(r.scheduledAt);
      },
    },
    {
      key: 'edit',
      name: 'Modifier',
      style: { width: '10px' },
      render: (val, r) => {
        return <Button onClick={() => navigate('/a/patchnotes/edit/' + r.id)}>Modifier</Button>;
      },
      filterSort: false,
    },
    {
      key: 'delete',
      name: 'Supprimer',
      style: { width: '10px' },
      render: (val, r) => {
        return (
          <Button variant="red" onClick={() => deletePatchnote(r.id)}>
            Supprimer
          </Button>
        );
      },
      filterSort: false,
    },
  ];

  return (
    <>
      <Container>
        <h1>Liste des patchnotes</h1>
        <Button onClick={() => navigate('/a/patchnotes/create')}>Créer une nouvelle patchnote</Button>
        <p>Nombre de patchnotes: {patchnotes?.length}</p>
      </Container>

      {isLoading || patchnotes === undefined ? <Spinner animation="border" /> : <TableComponent columns={columns} rows={patchnotes} />}
    </>
  );
}
