import { checkStatus, host } from '../api';
import { SmallCompany, User } from './_type';

interface CompanyRefered {
  id: number;
  company: SmallCompany;
  firstname: string;
  lastname: string;
}

const consultants = {
  register: (body: {
    email: string;
    plainPassword: string;
    firstname: string;
    lastname: string;
  }): Promise<any> => {
    return fetch(`${host}/api/consultant/register`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getRefered: (): Promise<CompanyRefered[]> => {
    return fetch(`${host}/api/consultant/refered`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getUnconfirmed: (): Promise<User[]> => {
    return fetch(`${host}/api/consultant/unconfirmed`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  confirm: (id: number): Promise<string> => {
    return fetch(`${host}/api/consultant/confirm/${id}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default consultants;
