import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import Utils from '../../components/Utils';
import Divider from '../../components/Utils/Divider/Divider';
import api from '../../utils/api';
import { CompanyUser, SubmitModalParams, User, UserFilter } from '../../utils/api/_type';
import { UserContextType, useUser } from '../../utils/context/User';
import { useDebounce } from '../../utils/utils';
import UserCreateModal from './UserCreateModal';
import UserItem from './UserItem';
import UserSettingModal from './UserSettingModal';
import StatusModal from '../../components/Utils/StatusModal/StatusModal';
import BugsModal from '../../components/BugsModal/BugsModal';
import { getErrorSubmitType, getSuccessSubmitType } from './utils/submitModalTypes';
import Winylo from '../../react_components';
import sharedStyle from './css/Shared.module.css';

import style from './css/UserList.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UserListProps {}

const successSubmitType: SubmitModalParams = getSuccessSubmitType({
  title: 'Utilisateur supprimé',
  verb: 'supprimé',
});

const errorSubmitType = (
  setState: (value: React.SetStateAction<SubmitModalParams>) => void,
  setIsOpen: (value: React.SetStateAction<boolean>) => void
) =>
  getErrorSubmitType({
    title: 'Problème dans la création',
    verb: 'la suppression',
    setState: setState,
    setIsOpen: setIsOpen,
  });

const initialSubmitModal: SubmitModalParams = { title: '', content: <></>, checked: false, isOpen: false };

export default function UserList(props: UserListProps) {
  const [isUserCreateModalOpen, setIsUserCreateModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CompanyUser | undefined>(undefined);
  const [users, setUsers] = useState<CompanyUser[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [modalSubmitType, setModalSubmitType] = useState<SubmitModalParams>(initialSubmitModal);
  const [isBugOpen, setIsBugOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<UserFilter>({
    global: '',
    firstname: null,
    lastname: null,
    username: null,
    email: null,
    role: null,
  });

  const queryClient = useQueryClient();
  const [user] = useUser() as UserContextType;

  const [searchValue, setSearchValue] = useDebounce('', (content) => {
    setFilters((before) => ({ ...before, global: content }));
  });

  const { data: applications } = useQuery('applications', api.applications.getAllApplications);

  const { data: company } = useQuery('company_in_user_list', () => api.companies.getOneCompany(user!.company!.id), {
    enabled: user?.company !== null,
  });

  /**
   * Pagination de la liste des utilisateurs
   */
  const { isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ['users_company', JSON.stringify(filters)],
    ({ pageParam = 1 }) => api.companies.getCompanyUsers({ page: pageParam, filters }),
    {
      onSuccess: (data) => {
        setUsers(data.pages.map((p) => p.items).flat());
        setTotalCount(data.pages[0].pagination.totalCount);
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  /**
   * Valide l'inscription de l'utilisateur
   */
  const { mutate: confirmRegistration } = useMutation(api.companies.confirmCompanyUserRegistration, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('users_company');
      queryClient.invalidateQueries('waiting_validation_count');
    },
  });

  /**
   * Refuse l'inscription de l'utilisateur
   */
  const { mutate: denyRegistration } = useMutation(api.companies.denyCompanyUserRegistration, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('users_company');
      queryClient.invalidateQueries('waiting_validation_count');
    },
  });

  /**
   * Supprime l'utilisateur donné
   */
  const { mutate: deleteUser } = useMutation(api.companies.deleteCompanyUser, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries(['users_company', JSON.stringify(filters)]);
      setModalSubmitType(successSubmitType);
    },
    onError: () => {
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Ouvre la modal de modification d'un utilisateur
   *
   * @param selectedUser utilisateur selectionné
   */
  function openSettingModal(selectedUser: CompanyUser) {
    setSelectedUser(selectedUser);
  }

  /**
   * Capte quand on scroll en bas de la page pour refetch des utilisateurs
   */
  useEffect(() => {
    window.addEventListener('scroll', eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      window.removeEventListener('scroll', eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Gestion des utilisateurs" isLoading={false} width="100%">
        <div className={style.topElements}>
          <Winylo.TextInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
            placeholder="Rechercher"
            width="200px"
          />

          <Utils.ImportantNumber word="Utilisateur" number={totalCount} style={{ marginLeft: '20px' }} />
          <div className={style.topButton}>
            <Winylo.Button
              id="onboarding-createuser-next-2nd"
              className="onboarding-createuser-2nd"
              format="square"
              size="md"
              onClick={() => setIsUserCreateModalOpen(true)}
            >
              Créer des utilisateurs
            </Winylo.Button>
          </div>
        </div>

        <div className={classNames(style.midElements, 'onboarding-edituser-2nd')}>
          <div className={classNames(sharedStyle.gridRow, style.gridRow)}>
            <span className={sharedStyle.importantText}>Prénom</span>
            <span className={sharedStyle.importantText}>Nom</span>
            <span className={sharedStyle.importantText}>Nom d'utilisateur</span>
            <span className={sharedStyle.importantText}>Email</span>
            <span></span>
            <span></span>
          </div>
          {users.map((u) => (
            <>
              <Divider variant="gray" />
              <UserItem
                user={u}
                handleOpenSetting={(selectedUser) => openSettingModal(selectedUser)}
                handleConfirmRegistration={confirmRegistration}
                handleDenyRegistration={denyRegistration}
                handleDeleteUser={(id) => deleteUser(id)}
                setUsers={setUsers}
              />
            </>
          ))}
        </div>
      </Utils.Card>

      <UserSettingModal
        applications={applications}
        companyApplications={company?.companyProducts.filter((cp) => cp.type === 'application')}
        selectedUser={selectedUser}
        onClose={() => setSelectedUser(undefined)}
      />

      <UserCreateModal applications={applications} company={company} isOpen={isUserCreateModalOpen} onClose={() => setIsUserCreateModalOpen(false)} />

      <StatusModal
        title={modalSubmitType.title}
        content={modalSubmitType.content}
        check={modalSubmitType.checked}
        isOpen={modalSubmitType.isOpen}
        onClose={() => {
          setModalSubmitType(initialSubmitModal);
        }}
        errorCode={modalSubmitType.errorCode}
      />

      <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />
    </Utils.Container>
  );
}
