import React, { useState, useEffect, CSSProperties } from 'react';
import { Patchnote, Tag } from '../../utils/api/_type';

import style from './css/Patchnote.module.css';

class TagType {
  static readonly NEW: Tag = { label: 'Nouveauté', color: 'var(--green)' };
  static readonly UPDATE: Tag = { label: 'Amélioration', color: 'var(--primary-color)' };
  static readonly CORRECTED: Tag = { label: 'Correction', color: 'var(--orange)' };
}

interface PatchnoteProps {
  patchnote: Patchnote;
  short?: boolean;
}

export default function PatchnoteItem({ patchnote, short }: PatchnoteProps) {
  function getTagType(tag: string): Tag {
    switch (tag) {
      case 'new':
        return TagType.NEW;
      case 'update':
        return TagType.UPDATE;
      case 'corrected':
        return TagType.CORRECTED;
      default:
        return TagType.NEW;
    }
  }

  return (
    <>
      <Nametag title={patchnote.title} type={getTagType(patchnote.type)} lightFormat={short} />
      {!short && <div style={{ marginTop: '0.5rem' }} dangerouslySetInnerHTML={{ __html: patchnote.content }}></div>}
    </>
  );
}

const Nametag = ({ title, type, lightFormat }: { title: string; type: Tag; lightFormat?: boolean }) => {
  const tagStyle: CSSProperties = lightFormat ? newsLightStyle : newsStyle;
  return (
    <span style={lightFormat ? { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' } : {}}>
      <span style={{ backgroundColor: type.color, ...tagStyle }}>{lightFormat ? ' ' : type.label}</span>{' '}
      <span style={lightFormat ? {} : titleStyle}>{title}</span>
    </span>
  );
};

const newsStyle: CSSProperties = {
  padding: '0.2em 0.6em 0.3em',
  borderRadius: '12px',
  fontWeight: 500,
  color: 'var(--background)',
};

const newsLightStyle: CSSProperties = {
  height: '25px',
  width: '25px',
  borderRadius: '50%',
  display: 'inline-block',
};

const ulStyle: CSSProperties = {
  listStyle: 'none',
  padding: 0,
  margin: '1rem',
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
};
