import { checkStatus, host } from '../api';
import { ApplicationRole, CompanyUser, PageItem, User } from './_type';

import qs from 'qs';

export interface CreateUserBody {
  username?: string;
  email: string;
  plainPassword?: string;
  company?: number;
  firstname: string;
  lastname: string;
  roles: string[];
}

export interface UpdateUserBody extends SmallUpdateUserBody {
  canConnect: boolean;
  roles: string[];
}

export interface SmallUpdateUserBody {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  plainPassword?: string;
}

const users = {
  getOneUser: (id: number): Promise<User> => {
    return fetch(`${host}/api/users/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getUsers: ({
    page,
    filters,
    hidden,
  }: {
    page: number;
    filters?: {
      [key: string]: {
        value: string;
      };
    };
    hidden: boolean;
  }): Promise<PageItem<User>> => {
    let f: any = {};

    if (filters !== undefined) {
      Object.keys(filters).forEach((k) => {
        if (filters[k].value !== '') {
          f[k] = filters[k];
        }
      });
    }

    return fetch(`${host}/api/users?${qs.stringify({ page, ...f, hidden })}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  createUser: (body: CreateUserBody): Promise<User> => {
    return fetch(`${host}/api/users`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        email: body.email || null,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateUser: ({ id, body }: { id: number; body: UpdateUserBody }): Promise<User> => {
    return fetch(`${host}/api/users/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        email: body.email || null,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteUser: (id: number) => {
    return fetch(`${host}/api/users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    }).then(checkStatus);
  },
  updateMe: (body: SmallUpdateUserBody): Promise<User> => {
    return fetch(`${host}/api/me`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        email: body.email || null,
      }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateUserCompanyRole: ({ userId, applicationId, roles }: { userId: number; applicationId: number; roles: string[] }): Promise<User> => {
    return fetch(`${host}/api/users/${userId}/roles/${applicationId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ roles: roles }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateUserCompanyRoles: ({ userId, applicationRoles }: { userId: number; applicationRoles: ApplicationRole[] }): Promise<User> => {
    return fetch(`${host}/api/users/${userId}/roles`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ applicationRoles }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateMultipleUserRoles: ({ appId, body }: { appId: number; body: { users: number[]; role: string; toggle: boolean } }): Promise<string> => {
    return fetch(`${host}/api/users/roles/${appId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  toggleCompanyAdmin: (idUser: number): Promise<CompanyUser> => {
    return fetch(`${host}/api/users/${idUser}/company-admin`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  toggleCompanyAccountant: (idUser: number): Promise<CompanyUser> => {
    return fetch(`${host}/api/users/${idUser}/company-accountant`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  toggleAdmin: (idUser: number): Promise<User> => {
    return fetch(`${host}/api/users/${idUser}/admin`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getJWT: (idUser: number): Promise<{ jwt: string; user: User }> => {
    return fetch(`${host}/api/users/${idUser}/admin`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default users;
