import { useState } from 'react';
import UserForm from '../UserForm';
import * as Yup from 'yup';
import { CompanyProduct, CreateCompanyUserBody, MidApplication, SubmitModalParams, User, UserFormValue } from '../../../utils/api/_type';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../utils/api';
import StatusModal from '../../../components/Utils/StatusModal/StatusModal';
import BugsModal from '../../../components/BugsModal/BugsModal';
import { getErrorSubmitType, getSuccessSubmitType } from '../utils/submitModalTypes';

import sharedStyle from '../css/Shared.module.css';
import classNames from 'classnames';

interface FormTabProps {
  applications?: MidApplication[];
  companyApplications?: CompanyProduct[];
  closeModal: () => void;
}

const successSubmitType: SubmitModalParams = getSuccessSubmitType({
  title: 'Utilisateur créé',
  verb: 'créé',
});

const errorSubmitType = (
  setState: (value: React.SetStateAction<SubmitModalParams>) => void,
  setIsOpen: (value: React.SetStateAction<boolean>) => void
) =>
  getErrorSubmitType({
    title: 'Problème dans la création',
    verb: 'la création',
    setState: setState,
    setIsOpen: setIsOpen,
  });

const initialSubmitModal: SubmitModalParams = { title: '', content: <></>, checked: false, isOpen: false };

export default function FormTab(props: FormTabProps) {
  const [modalSubmitType, setModalSubmitType] = useState<SubmitModalParams>(initialSubmitModal);
  const [isBugOpen, setIsBugOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape(
    {
      username: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
      email: Yup.string()
        .email("L'email doit être valide.")
        .when(['password'], {
          is: (password?: string) => !!!password || password.length <= 0,
          then: (schema) => schema.required('Requis si aucun mot de passe défini.'),
        }),
      firstname: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
      lastname: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
      password: Yup.string()
        .min(6, 'Minimum 6 caractères')
        .when(['email'], {
          is: (email: string) => !email || email.length <= 0,
          then: (schema) => schema.required('Requis si aucune email définie.'),
        }),
      password_confirm: Yup.string().when(['password'], {
        is: (password: string) => password,
        then: (schema) =>
          schema
            .oneOf([Yup.ref('password'), null], 'Les champs doivent correspondre.')
            .min(6, 'Minimum 6 caractères')
            .when(['email'], {
              is: (email: string) => !email || email.length <= 0,
              then: (schema) => schema.required('Requis si aucune email définie.'),
            }),
      }),
    },
    [
      ['email', 'password'],
      ['password_confirm', 'email'],
    ]
  );

  /**
   * Fetch la création de l'utilisateur
   * Au success, si des rôles ont été définis ils sont alors envoyé via une deuxième requête
   */
  const { mutate: createUser, isLoading: isCreating } = useMutation(api.companies.addUserToCompany, {
    onSuccess: (data, variable) => {
      if (variable.applicationRoles) {
        updateUserRole({ userId: data.id, applicationRoles: variable.applicationRoles });
      } else {
        queryClient.invalidateQueries('users_company');
        setModalSubmitType(successSubmitType);
      }
    },
    onError: () => {
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Met à jour les rôles de l'utilisateur
   */
  const { mutate: updateUserRole } = useMutation(api.users.updateUserCompanyRoles, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('users_company');

      setModalSubmitType(successSubmitType);
    },
    onError: () => {
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Check et envoie le formulaire pour créer l'utilisateur
   *
   * @param values valeurs du formik
   */
  async function handleFormSubmit(values: UserFormValue) {
    await new Promise((r) => setTimeout(r, 500));

    let parsed: CreateCompanyUserBody;
    parsed = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email.length > 0 ? values.email : undefined,
      plainPassword: values.password.length > 0 ? values.password : undefined,
      username: values.username.length > 0 ? values.username : undefined,
    };

    createUser({ body: parsed, applicationRoles: values.application_roles });
  }

  return (
    <div className={classNames(sharedStyle.container, 'onboarding-createuser-5th')}>
      <UserForm
        applications={props.applications}
        companyApplications={props.companyApplications}
        validationSchema={validationSchema}
        handleSubmit={handleFormSubmit}
        isLoading={isCreating}
      />
      <StatusModal
        title={modalSubmitType.title}
        content={modalSubmitType.content}
        check={modalSubmitType.checked}
        isOpen={modalSubmitType.isOpen}
        onClose={() => {
          setModalSubmitType(initialSubmitModal);
          props.closeModal();
        }}
        errorCode={modalSubmitType.errorCode}
      />

      <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />
    </div>
  );
}
