import { checkStatus, host } from '../api';
import { PaymentMethod } from './_type';

interface CreateMethodResponse {
  clientSecret: string;
}

const paymentmethod = {
  method: (): Promise<PaymentMethod[]> => {
    return fetch(`${host}/api/payment-methods`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  postMethod: (): Promise<CreateMethodResponse> => {
    return fetch(`${host}/api/payment-methods`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteMethod: (id: string) => {
    return fetch(`${host}/api/payment-methods/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  changeDefault: (id: string) => {
    return fetch(`${host}/api/payment-methods/${id}/default`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default paymentmethod;
