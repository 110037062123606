import { Application } from '../../../utils/api/_type';

export enum Tabs {
  ALL = 'Toutes les applications',
  SUBS = 'Mes applications',
  TRIALS = "En cours d'essais",
}

export enum GridMode {
  GRID = 'grid',
  ROW = 'row',
}

export interface TopBarRef {
  gridMode: GridMode;
  searchValue: string;
}

export interface ApplicationsListProps {}

export interface TopBarProps {
  applicationsLength: number | undefined;
}

export interface InfoModalObject {
  name: string;
  fileUri: string;
  description: JSX.Element;
  associate: JSX.Element | undefined;
  compatibility: JSX.Element;
  video?: JSX.Element;
}

export enum APPLICATIONS_DENOMINATION {
  capture = 'capture',
  kaizen = 'kaizen',
  catalogue = 'catalogue',
  vizu = 'vizu',
}

export enum MORE_INFO_MODAL_TABS {
  DESCRIPTION = 'description',
  ASSOCIATE = 'associate',
  COMPATIBILITY = 'compatibility',
  VIDEO = 'video',
}
