import React from "react";
import classNames from "classnames";

import style from "../../css/old/Card.module.css";
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title?: string;
  leftElements?: React.ReactNode;
}

export default function Card({ title, leftElements, ...props }: Props) {
  return (
    <div {...props} className={classNames(style.card, props.className)}>
      {(title || leftElements) && (
        <>
          <div className={style.titleContainer}>
            <span className={style.title}>{title}</span>
            {leftElements && <span>{leftElements}</span>}
          </div>
          <div className={style.separator}></div>
        </>
      )}
      <div>{props.children}</div>
    </div>
  );
}
