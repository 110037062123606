import classNames from 'classnames';
import React from 'react';

import style from './Table.module.css';

interface Props
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {}

export default function Table(props: Props) {
  return (
    <table {...props} className={classNames(style.table, props.className)}>
      {props.children}
    </table>
  );
}
