import { checkStatusQuiet, host } from '../api';

interface AuthorizeReponse {
  redirect_uri: string;
  code: string;
}

const auth = {
  authorize: (
    clientId: string,
    redirectUrl: string
  ): Promise<AuthorizeReponse> => {
    return fetch(
      `${host}/api/auth/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}`,
      {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        },
        redirect: 'manual',
      }
    )
      .then(checkStatusQuiet)
      .then((res) => res.json());
  },
};

export default auth;
