import { useContext, createContext } from 'react';
import { MidApplication } from '../api/_type';
import { useLocalStorage } from '../utils';

export interface BasketAddon {
  id: number;
  name: string;
  quantity: number;
  price: number;
  fileUri: string;
}

export interface BasketApplication {
  addedDate: Date;
  application: MidApplication | BasketAddon;
}

export type BasketContextType = [BasketApplication[], React.Dispatch<React.SetStateAction<BasketApplication[]>>];

const BasketContext = createContext<BasketContextType | undefined>(undefined);

export function BasketContextProvider({ children }: { children: React.ReactElement }) {
  const [basket, setBasket] = useLocalStorage<BasketApplication[]>('basket', []);

  return <BasketContext.Provider value={[basket, setBasket]}>{children}</BasketContext.Provider>;
}

export function useBasket() {
  return useContext(BasketContext);
}
