import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, KeyboardEventHandler } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Toastify from 'toastify-js';
import Utils from '../../../components/Utils';
import api from '../../../utils/api';
import { Company, EmailOption, ShareFormValue, SubmitModalParams } from '../../../utils/api/_type';
import { copyStringToClipboard, MY_DOMAIN } from '../../../utils/utils';
import QRCodeSignupLink from './QRCodeSignupLink';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, getIn } from 'formik';
import CreatableSelect from 'react-select/creatable';
import { SHARE_FIELDS } from '../utils/constantes';
import StatusModal from '../../../components/Utils/StatusModal/StatusModal';
import BugsModal from '../../../components/BugsModal/BugsModal';
import { getErrorSubmitType, getSuccessSubmitType } from '../utils/submitModalTypes';
import classNames from 'classnames';

import sharedStyle from '../css/Shared.module.css';
import style from '../css/ShareTab.module.css';

interface ShareTabProps {
  company?: Company;
}

const successSubmitType: SubmitModalParams = getSuccessSubmitType({
  title: 'Lien partagé',
  text: 'Le lien a été envoyé avec succès !',
});

const errorSubmitType = (
  setState: (value: React.SetStateAction<SubmitModalParams>) => void,
  setIsOpen: (value: React.SetStateAction<boolean>) => void
) =>
  getErrorSubmitType({
    title: 'Problème lors du partage',
    text: 'Il y a eu un problème lors du partage ! Vérifiez si le formulaire est correct et réessayez.',
    setState: setState,
    setIsOpen: setIsOpen,
  });

const initialSubmitModal: SubmitModalParams = { title: '', content: <></>, checked: false, isOpen: false };

const initialValues: ShareFormValue = {
  addresses: [],
  subject: '',
  message: '',
};

export default function ShareTab(props: ShareTabProps) {
  const signupUrl = MY_DOMAIN + '/register/companies/' + props.company?.signupLink;

  const [selectInputValue, setSelectInputValue] = useState<string>('');
  const [selectedEmailsValue, setSelectedEmailsValue] = useState<readonly EmailOption[]>([]);
  const [modalSubmitType, setModalSubmitType] = useState<SubmitModalParams>(initialSubmitModal);
  const [isBugOpen, setIsBugOpen] = useState<boolean>(false);

  const qrRef = useRef<HTMLDivElement | null>(null);

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    addresses: Yup.array().of(
      Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required()
    ),
    subject: Yup.string(),
    message: Yup.string(),
  });

  const emailValidationSchema = Yup.string().email();

  /**
   * Envoie le lien d'invitation par mail aux adresses données
   */
  const { mutate: sendSignupLink, isLoading: isLoadingSending } = useMutation(api.companies.shareCompanySignupLink, {
    onSuccess: (data) => {
      setModalSubmitType(successSubmitType);
    },
    onError: () => {
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Fetch le back pour obtenir un nouveau lien d'invitation pour la company
   * Met à jour le queryClient
   */
  const { mutate: resetSignuplink, isLoading: isLoadingGenerate } = useMutation(api.companies.generateSignupLink, {
    onSuccess: (data) => {
      queryClient.setQueryData<Company | undefined>('company_in_user_list', (before) => {
        return before === undefined ? undefined : data;
      });
    },
  });

  /**
   * Ajoute le lien dans le presse papier et affiche un message de confirmation
   */
  function handleCopy() {
    copyStringToClipboard(signupUrl);

    Toastify({
      text: 'Lien copié dans le presse-papier !',
      duration: 3000,
      close: true,
      gravity: 'bottom', // `top` or `bottom`
      position: 'center', // `left`, `center` or `right`
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  }

  /**
   * Valide ou non l'adresse mail en cours lors de l'appui sur Enter ou Tab
   *
   * @param event Appuies d'une touche du clavier
   * @returns
   */
  function handleKeyDown(event: any): KeyboardEventHandler | undefined {
    if (!selectInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        emailValidationSchema
          .validate(selectInputValue)
          .then(() => {
            setSelectedEmailsValue((prev) => [...prev, createEmailOption(selectInputValue)]);
            setSelectInputValue('');
          })
          .catch(() => {})
          .finally(() => {
            event.preventDefault();
          });
    }
  }

  /**
   * Constructueur pour un EmailOption
   *
   * @param label adresse mail
   * @returns
   */
  function createEmailOption(label: string) {
    return {
      label,
    };
  }

  /**
   * Formate les valeurs et envoie la requête de partage du lien
   *
   * @param values valeurs de tous les input du formulaire
   * @param functions fonctions du formulaires formik
   */
  function handleFormSubmit(values: ShareFormValue, functions: FormikHelpers<ShareFormValue>) {
    const formatedValues: ShareFormValue = {
      addresses: selectedEmailsValue.map((sev) => sev.label),
      subject: values.subject,
      message: values.message,
    };

    sendSignupLink(formatedValues);
  }

  return (
    <div className={classNames(sharedStyle.container, 'onboarding-createuser-3rd', 'onboarding-createuser-4th')}>
      <div className={style.topElements}>
        <label className={sharedStyle.importantText}>Copier le lien et partager</label>
        <div className={style.shareRow}>
          <div className={style.linkContainer}>
            <div className={style.fullSize}>
              <Utils.Input value={signupUrl} />
            </div>
            <Utils.Button variant="secondary" format="square" onClick={handleCopy} fullWidth={false}>
              Copier le lien
            </Utils.Button>
          </div>
          <div className={style.QRCode}>
            <FontAwesomeIcon icon={faQrcode} />
            <div className={style.QRCodePopover}>
              <QRCodeSignupLink link={signupUrl} qrRef={qrRef} />
            </div>
          </div>
          <span>{`ID : ${props.company?.signupLink?.substring(0, 4)}`}</span>
          <Utils.Button
            format="square"
            style={{ marginRight: '5px' }}
            onClick={() => props.company && resetSignuplink(props.company.id)}
            isLoading={isLoadingGenerate}
          >
            Regénérer le lien
          </Utils.Button>
        </div>
      </div>
      <div className={style.botElements}>
        <label className={sharedStyle.importantText}>Envoyer le lien d'inscription par mail</label>
        <span>Vous pouvez partager le lien à plusieurs adresses mail</span>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={(values, functions) => handleFormSubmit(values, functions)}
        >
          {({ values, errors, handleSubmit, handleChange, setFieldValue, isValid }) => (
            <Form id="share-formik" className={style.form}>
              <CreatableSelect
                value={selectedEmailsValue}
                onChange={(value) => setSelectedEmailsValue(value)}
                inputValue={selectInputValue}
                onInputChange={(value) => setSelectInputValue(value)}
                onKeyDown={handleKeyDown}
                menuIsOpen={false}
                isClearable
                isMulti
                placeholder={SHARE_FIELDS.PLACEHOLDERS.EMAILS}
                classNamePrefix={'react-styled-select'}
                components={{ DropdownIndicator: null }}
                isLoading={isLoadingSending}
              />

              <Utils.Input
                id={SHARE_FIELDS.ACCESSORS.SUBJECT}
                name={SHARE_FIELDS.ACCESSORS.SUBJECT}
                label={SHARE_FIELDS.LABELS.SUBJECT}
                value={values.subject}
                onChange={handleChange}
                isInvalid={!!getIn(errors, SHARE_FIELDS.ACCESSORS.SUBJECT)}
                disabled={isLoadingSending}
              />

              <Utils.Textarea
                id={SHARE_FIELDS.ACCESSORS.MESSAGE}
                name={SHARE_FIELDS.ACCESSORS.MESSAGE}
                label={SHARE_FIELDS.LABELS.MESSAGE}
                value={values.message}
                onChange={handleChange}
                isInvalid={!!getIn(errors, SHARE_FIELDS.ACCESSORS.MESSAGE)}
                rows={5}
                disabled={isLoadingSending}
              />
            </Form>
          )}
        </Formik>
      </div>
      <Utils.Button form="share-formik" disabled={isLoadingSending} additionalClassName={style.submitButton} fullWidth={false} type="submit">
        Envoyer
      </Utils.Button>

      <StatusModal
        title={modalSubmitType.title}
        content={modalSubmitType.content}
        check={modalSubmitType.checked}
        isOpen={modalSubmitType.isOpen}
        onClose={() => {
          setModalSubmitType(initialSubmitModal);
        }}
        errorCode={modalSubmitType.errorCode}
      />

      <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />
    </div>
  );
}
