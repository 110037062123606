import classNames from 'classnames';
import style from './Textarea.module.css';

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  isInvalid?: boolean;
}

export default function Textarea(props: Props) {
  return (
    <div>
      {!!props.label ? (
        <label className={style.label}>{props.label}</label>
      ) : null}
      <textarea
        {...props}
        className={classNames(style.textarea, props.className, {
          [style.isInvalid]: props.isInvalid,
        })}
      >
        {props.children}
      </textarea>
    </div>
  );
}
