import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, getIn } from 'formik';
import { useState } from 'react';
import { Assign, ObjectShape } from 'yup/lib/object';
import Utils from '../../components/Utils';
import { ApplicationRole, CompanyProduct, CompanyUser, MidApplication, SubmitModalParams, UserFormValue } from '../../utils/api/_type';
import { USERFORM_FIELDS } from './utils/constantes';
import * as Yup from 'yup';
import classNames from 'classnames';
import ApplicationRoleItem from './ApplicationRoleItem';
import Winylo from '../../react_components';
import sharedStyle from './css/Shared.module.css';

import style from './css/UserForm.module.css';

interface UserFormProps {
  user?: CompanyUser;
  initialValues?: UserFormValue;
  applications?: MidApplication[];
  companyApplications?: CompanyProduct[];
  validationSchema: Yup.ObjectSchema<Assign<ObjectShape, any>>;
  handleSubmit: (values: UserFormValue) => void;
  isLoading?: boolean;
}

const initialValues: UserFormValue = {
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  password: '',
  password_confirm: '',
  application_roles: [],
};

export default function UserForm(props: UserFormProps) {
  const [userNameChanged, setUserNameChanged] = useState<boolean>(false);

  return (
    <Formik
      initialValues={props.initialValues || initialValues}
      validationSchema={props.validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={(values, functions) => props.handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form id="user-formik" className={style.twoColumns}>
          <div className={classNames(style.leftElements, 'onboarding-edituser-5th')}>
            <Winylo.TextInput
              id={USERFORM_FIELDS.ACCESSORS.FIRSTNAME}
              className={style.textInput}
              name={USERFORM_FIELDS.ACCESSORS.FIRSTNAME}
              label={
                <Winylo.InputLabel
                  label={USERFORM_FIELDS.LABELS.FIRSTNAME}
                  accessor={USERFORM_FIELDS.ACCESSORS.FIRSTNAME}
                  errors={errors}
                  icon={faExclamationCircle}
                  color="var(--red)"
                />
              }
              placeholder={USERFORM_FIELDS.PLACEHOLDERS.REQUIRED}
              value={values.firstname}
              onChange={handleChange}
              error={!!getIn(errors, USERFORM_FIELDS.ACCESSORS.FIRSTNAME)}
              disabled={props.isLoading}
            />

            <Winylo.TextInput
              id={USERFORM_FIELDS.ACCESSORS.LASTNAME}
              className={style.textInput}
              name={USERFORM_FIELDS.ACCESSORS.LASTNAME}
              label={
                <Winylo.InputLabel
                  label={USERFORM_FIELDS.LABELS.LASTNAME}
                  accessor={USERFORM_FIELDS.ACCESSORS.LASTNAME}
                  errors={errors}
                  icon={faExclamationCircle}
                  color="var(--red)"
                />
              }
              placeholder={USERFORM_FIELDS.PLACEHOLDERS.REQUIRED}
              value={values.lastname}
              onChange={handleChange}
              error={!!getIn(errors, USERFORM_FIELDS.ACCESSORS.LASTNAME)}
              disabled={props.isLoading}
            />

            <Winylo.TextInput
              id={USERFORM_FIELDS.ACCESSORS.EMAIL}
              className={style.textInput}
              name={USERFORM_FIELDS.ACCESSORS.EMAIL}
              label={
                <Winylo.InputLabel
                  label={USERFORM_FIELDS.LABELS.EMAIL}
                  accessor={USERFORM_FIELDS.ACCESSORS.EMAIL}
                  errors={errors}
                  icon={faExclamationCircle}
                  color="var(--red)"
                />
              }
              placeholder={USERFORM_FIELDS.PLACEHOLDERS.OPTIONAL}
              value={values.email}
              onChange={handleChange}
              error={!!getIn(errors, USERFORM_FIELDS.ACCESSORS.EMAIL)}
              type={'email'}
              disabled={props.isLoading}
            />

            <Winylo.TextInput
              id={USERFORM_FIELDS.ACCESSORS.USERNAME}
              className={style.textInput}
              name={USERFORM_FIELDS.ACCESSORS.USERNAME}
              label={
                <Winylo.InputLabel
                  label={USERFORM_FIELDS.LABELS.USERNAME}
                  accessor={USERFORM_FIELDS.ACCESSORS.USERNAME}
                  errors={errors}
                  icon={faExclamationCircle}
                  color="var(--red)"
                />
              }
              placeholder={USERFORM_FIELDS.PLACEHOLDERS.REQUIRED}
              value={values.username}
              onChange={(e) => {
                handleChange(e);
                if (!userNameChanged) {
                  setUserNameChanged(true);
                  setFieldValue(USERFORM_FIELDS.ACCESSORS.FIRSTNAME, values.firstname + e.target.value.substring(0, 1));
                }
              }}
              error={!!getIn(errors, USERFORM_FIELDS.ACCESSORS.USERNAME)}
              autoComplete={'off'}
              disabled={props.isLoading}
            />

            <Winylo.TextInput
              id={USERFORM_FIELDS.ACCESSORS.PASSWORD}
              className={style.textInput}
              name={USERFORM_FIELDS.ACCESSORS.PASSWORD}
              label={
                <Winylo.InputLabel
                  label={USERFORM_FIELDS.LABELS.PASSWORD}
                  accessor={USERFORM_FIELDS.ACCESSORS.PASSWORD}
                  errors={errors}
                  icon={faExclamationCircle}
                  color="var(--red)"
                />
              }
              placeholder={USERFORM_FIELDS.PLACEHOLDERS.OPTIONAL}
              value={values.password}
              onChange={handleChange}
              error={!!getIn(errors, USERFORM_FIELDS.ACCESSORS.PASSWORD)}
              type={'password'}
              autoComplete={'new-password'}
              disabled={props.isLoading}
            />

            <Winylo.TextInput
              id={USERFORM_FIELDS.ACCESSORS.PASSWORD_CONFIRM}
              className={style.textInput}
              name={USERFORM_FIELDS.ACCESSORS.PASSWORD_CONFIRM}
              label={
                <Winylo.InputLabel
                  label={USERFORM_FIELDS.LABELS.PASSWORD_CONFIRM}
                  accessor={USERFORM_FIELDS.ACCESSORS.PASSWORD_CONFIRM}
                  errors={errors}
                  icon={faExclamationCircle}
                  color="var(--red)"
                />
              }
              placeholder={USERFORM_FIELDS.PLACEHOLDERS.OPTIONAL}
              value={values.password_confirm}
              onChange={handleChange}
              error={!!getIn(errors, USERFORM_FIELDS.ACCESSORS.PASSWORD_CONFIRM)}
              type={'password'}
              autoComplete={'new-password'}
              disabled={props.isLoading}
            />
          </div>
          <div className={classNames(style.rightElements, 'onboarding-edituser-6th')}>
            <div className={classNames(sharedStyle.gridRow, style.gridRow)}>
              <span className={sharedStyle.importantText}>Application</span>
              <span className={sharedStyle.importantText}>Accès</span>
              <span className={sharedStyle.importantText}>Administrateur</span>
              <span></span>
            </div>
            {props.companyApplications?.map((companyApp) => {
              const appRole: ApplicationRole = values.application_roles.find((ar) => ar.application.id === companyApp.product.id) || {
                application: { id: companyApp.product.id },
                roles: [],
              };
              const app: MidApplication | undefined = props.applications?.find((app) => app.id === companyApp.product.id);
              if (!app) return;

              return (
                <>
                  <Utils.Divider variant="gray" />
                  <ApplicationRoleItem
                    id={USERFORM_FIELDS.ACCESSORS.APPLICATION_ROLES}
                    name={USERFORM_FIELDS.ACCESSORS.APPLICATION_ROLES}
                    application={app}
                    applicationRole={appRole}
                    onChange={(value) => {
                      let updatedAppRoles: ApplicationRole[] = [...values.application_roles];
                      if (updatedAppRoles.some((ar) => ar.application.id === appRole.application.id)) {
                        updatedAppRoles = updatedAppRoles.map((ar) => {
                          if (ar.application.id !== appRole.application.id) return ar;
                          return { ...appRole, roles: value };
                        });
                      } else {
                        updatedAppRoles.push({ ...appRole, roles: value });
                      }

                      setFieldValue(USERFORM_FIELDS.ACCESSORS.APPLICATION_ROLES, updatedAppRoles);
                    }}
                  />
                </>
              );
            })}
          </div>

          <div className={style.bottomElements}>
            <Winylo.Button className={style.submitButton} fullWidth={false} form={'user-formik'} type="submit" loading={props.isLoading} size="md">
              {props.user ? `Modifier ${props.user.firstname}` : 'Créer un utilisateur'}
            </Winylo.Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
