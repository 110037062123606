import classNames from 'classnames';

import style from '../css/CSVItem.module.css';
import sharedStyle from '../css/Shared.module.css';

interface CSVItemProps {
  row: string[];
}

export default function CSVItem(props: CSVItemProps) {
  return (
    <div className={classNames(style.gridRow, sharedStyle.gridRow)}>
      {props.row.map((col) => {
        return <span>{col}</span>;
      })}
    </div>
  );
}
