import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FormControlProps } from 'react-bootstrap';

import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

export default function ShowHideInput(props: FormControlProps) {
  const [show, setShow] = useState(false);

  return (
    <InputGroup>
      <Form.Control {...props} type={show ? 'text' : 'password'} />
      <InputGroup.Append>
        <Button variant="outline-secondary">
          {show ? (
            <EyeSlashFill onClick={() => setShow(false)} />
          ) : (
            <EyeFill onClick={() => setShow(true)} />
          )}
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
