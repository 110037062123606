import Page from 'react-bootstrap/Pagination';
import { Pagination as PaginationType } from '../utils/api/_type';

interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pagination?: PaginationType;
}

export default function Pagination({ page, setPage, pagination }: Props) {
  if (pagination === undefined) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Page>
          <Page.First disabled />
          <Page.Last disabled />
        </Page>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Page>
        {/* Si il y a plus de page que l'interval */}
        {pagination.last > pagination.pageRange ? (
          <Page.First onClick={() => setPage(1)} />
        ) : null}

        <Page.Prev
          onClick={() => setPage((before) => before - 1)}
          disabled={page === 1}
        />

        {/* Si il y a moins de X pages dans l'écart */}
        {pagination.last <= pagination.pageRange ? (
          Array.from({ length: pagination.pageRange }, (v, k) => k + 1).map(
            (k) => (
              <Page.Item key={k} active={k === page} onClick={() => setPage(k)}>
                {k}
              </Page.Item>
            )
          )
        ) : (
          <>
            {!pagination.pagesInRange.includes(1) ? (
              <Page.Item active={1 === page} onClick={() => setPage(1)}>
                1
              </Page.Item>
            ) : null}

            {!pagination.pagesInRange.includes(2) ? (
              <Page.Ellipsis disabled />
            ) : null}

            {Array.from(
              { length: pagination.pageRange },
              (v, k) => k + pagination.pagesInRange[0]
            ).map((k) => (
              <Page.Item key={k} active={k === page} onClick={() => setPage(k)}>
                {k}
              </Page.Item>
            ))}

            {!pagination.pagesInRange.includes(pagination.last - 1) ? (
              <Page.Ellipsis disabled />
            ) : null}

            {!pagination.pagesInRange.includes(pagination.last) ? (
              <Page.Item
                active={pagination.last === page}
                onClick={() => setPage(pagination.last)}
              >
                {pagination.last}
              </Page.Item>
            ) : null}
          </>
        )}

        <Page.Next
          onClick={() => setPage((before) => before + 1)}
          disabled={page === pagination.last}
        />

        {/* Si il y a plus de page que l'interval */}
        {pagination.last > pagination.pageRange ? (
          <Page.Last onClick={() => setPage(pagination.last)} />
        ) : null}
      </Page>
    </div>
  );
}
