import Container from 'react-bootstrap/Container';
import { useQuery } from 'react-query';

import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import InfoText, { InfoTextContainer } from '../../components/InfoText';
import api from '../../utils/api';

import style from './Commissions.module.css';

// interface Rows {
//   id: number;
//   date: null | Date;
//   companyName: string;
//   billingDate: Date;
//   firstPayment: Date;
//   billingPayed: 'YES' | 'WAITING';
//   amountCommission: number;
//   askCommission: number;
// }

export default function Commissions() {
  const { data: refered, isLoading: isLoadingRefered } = useQuery(
    ['consultant', 'refered'],
    api.consultants.getRefered
  );
  // const rows: Rows[] = [
  //   {
  //     id: 1,
  //     date: new Date(),
  //     companyName: 'Winylo',
  //     billingDate: new Date(),
  //     firstPayment: new Date(),
  //     billingPayed: 'YES',
  //     amountCommission: 99.5,
  //     askCommission: 5.12,
  //   },
  //   {
  //     id: 2,
  //     date: null,
  //     companyName: 'Taskokil',
  //     billingDate: new Date(),
  //     firstPayment: new Date(),
  //     billingPayed: 'WAITING',
  //     amountCommission: 6666,
  //     askCommission: 25.8,
  //   },
  // ];

  // function formatBillingPayed(status: 'YES' | 'WAITING') {
  //   if (status === 'YES') {
  //     return (
  //       <div className={style.statusContainer}>
  //         <div
  //           className={style.statusBubble}
  //           style={{ backgroundColor: '#20CB27' }}
  //         ></div>
  //         Oui
  //       </div>
  //     );
  //   } else if (status === 'WAITING') {
  //     return (
  //       <div className={style.statusContainer}>
  //         <div
  //           className={style.statusBubble}
  //           style={{ backgroundColor: '#F8990A' }}
  //         ></div>
  //         En attente
  //       </div>
  //     );
  //   }
  // }

  return (
    <Container>
      <h1>Mes commissions</h1>

      <InfoTextContainer>
        <InfoText text={refered?.length || 0} description="Total fillieuls" />
        <InfoText text="-" description="Total fillieuls abonnés" />
        <InfoText text="-" description="Total commissions" />
        <InfoText text="-" description="Commissions demandées" />
        <InfoText text="-" description="Rémunération à demander" />
      </InfoTextContainer>

      <h2 className={style.subtitle}>Liste des fileuls</h2>
      {isLoadingRefered || refered === undefined ? (
        <Spinner animation="border" />
      ) : (
        <Table responsive>
          <thead>
            <tr>
              <th>Nom de l'entreprise</th>
              <th>Prénom</th>
              <th>Nom</th>
            </tr>
          </thead>
          <tbody>
            {refered.map((r) => (
              <tr key={r.id}>
                <td>{r.company.name}</td>
                <td>{r.firstname}</td>
                <td>{r.lastname}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {/* <Table responsive>
        <thead>
          <tr>
            <th>Date d'expiration de votre commission</th>
            <th>Nom entreprise filleul</th>
            <th>Date facturation</th>
            <th>Date 1er paiment</th>
            <th>Facture réglée</th>
            <th>Montant commission</th>
            <th>Rémunération à demander</th>
          </tr>
        </thead>

        <tbody>
          {rows.map((r) => (
            <tr key={r.id}>
              <td>
                {r.date !== null
                  ? dateToString(r.date)
                  : 'Commission en attente de validation'}
              </td>
              <td>{r.companyName}</td>
              <td>{dateToString(r.billingDate)}</td>
              <td>{dateToString(r.firstPayment)}</td>
              <td>{formatBillingPayed(r.billingPayed)}</td>
              <td>{numberToPrice(r.amountCommission)}</td>
              <td>{numberToPrice(r.askCommission)}</td>
            </tr>
          ))}
        </tbody>
      </Table> */}
    </Container>
  );
}
