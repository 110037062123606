import style from './ButtonDelete.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onDelete: () => void;
}

export default function ButtonDelete({ onDelete }: Props) {
  return (
    <button className={style.button} onClick={onDelete}>
      <FontAwesomeIcon icon={faTimes} className={style.buttonIcon} />
    </button>
  );
}
