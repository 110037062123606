import React, { useState, useEffect, useCallback } from 'react';
import Utils from '../../components/Utils';
import classNames from 'classnames';
import { Company, CompanyProduct, MidApplication, PRODUCT_STATUS } from '../../utils/api/_type';
import { ApplicationsListProps, GridMode, Tabs, TopBarRef } from './utils/types';
import { TopBar } from './components/TopBar';
import ApplicationItem from './components/ApplicationItem';
import api from '../../utils/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserContextType, useUser } from '../../utils/context/User';
import { PRODUCT_TYPE } from '../../utils/constantes';
import MoreInfoModal from '../../components/MoreInfoModal/MoreInfoModal';
import { isAdmin, isFilter } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { BasketContextType, useBasket } from '../../utils/context/Basket';
import AskTrialModal from './components/AskTrialModal';

import style from './css/ApplicationsList.module.css';

const GridListStyles = {
  [GridMode.GRID]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
    gap: '1.25rem',
  } as React.CSSProperties,
  [GridMode.ROW]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.625rem',
  } as React.CSSProperties,
};

const RESPONSIVE_LIMIT = 1280;

export function ApplicationsList(props: ApplicationsListProps) {
  const [filteredApplications, setFilteredApplications] = useState<MidApplication[]>([]);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.ALL);
  const [selectedApplication, setSelectedApplication] = useState<MidApplication | undefined>();
  const [selectedTrialApplication, setSelectedTrialApplication] = useState<MidApplication | undefined>();
  const [gridMode, setGridMode] = useState<GridMode>(GridMode.GRID);
  const [searchValue, setSearchValue] = useState<string>('');
  const [companyApplications, setCompanyApplications] = useState<CompanyProduct[]>([]);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [user] = useUser() as UserContextType;
  const [basket, setBasket] = useBasket() as BasketContextType;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const topBarRef = useCallback((ref: TopBarRef) => {
    if (!ref) return;
    setGridMode(ref.gridMode);
    setSearchValue(ref.searchValue);
  }, []);

  const { data: applications, isLoading: isLoadingApplications } = useQuery('applications', api.applications.getAllApplications, {
    onSuccess: (data) => {
      setFilteredApplications(filterApplications(data, selectedTab) || []);
    },
  });

  /**
   * Update le query company et récupère les applications de la companie pour cette page
   */
  const {} = useQuery(['company', user?.company?.id], () => api.companies.getOneCompany(user?.company?.id || -1), {
    enabled: user !== undefined && user.company !== null,
    onSuccess: (data) => {
      setCompanyApplications(data.companyProducts.filter((cP) => cP.type === PRODUCT_TYPE.APPLICATION));
    },
  });

  const { mutate: tryApplication } = useMutation(api.applications.tryApplication, {
    onSuccess: (data) => {
      queryClient.setQueryData<Company | undefined>(['company', user?.company?.id], (before) => {
        if (before === undefined) return before;

        return {
          ...before,
          companyProducts: [...before.companyProducts, data],
        };
      });
    },
  });

  function filterApplications(applications: MidApplication[] | undefined, selectedTab: Tabs) {
    let applicationsFiltered = applications || [];
    if (selectedTab === Tabs.SUBS) {
      const subs = companyApplications.filter((cA) => cA.active && cA.status === PRODUCT_STATUS.SUBSCRIBED);
      applicationsFiltered = applications?.filter((application: MidApplication) => subs.find((cA) => cA.product.id === application.id)) || [];
    }
    if (selectedTab === Tabs.TRIALS) {
      const trials = companyApplications.filter((cA) => cA.active && cA.status === PRODUCT_STATUS.TRIAL);
      applicationsFiltered = applications?.filter((application: MidApplication) => trials.find((cA) => cA.product.id === application.id)) || [];
    }
    return applicationsFiltered.filter((app) => isFilter(app.name, searchValue));
  }

  function handleTryApplication(application: MidApplication) {
    if (!user) {
      navigate('/login');
      return;
    }

    if (isAdmin(user)) {
      tryApplication(application.id);
    } else {
      setSelectedTrialApplication(application);
    }
  }

  /**
   * Ajoute une application au panier
   * @param app
   */
  function toggleAppInBasket(app: MidApplication) {
    if (!user) {
      navigate('/login');
      return;
    }

    if (!basket.map((basketApp) => basketApp.application.id).includes(app.id)) {
      setBasket((before) => [...before, { addedDate: new Date(), application: app }]);
    } else {
      setBasket((before) => before.filter((basketApp) => basketApp.application.id !== app.id));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFilteredApplications(filterApplications(applications, selectedTab) || []);
  }, [selectedTab, searchValue]);

  return (
    <>
      <Utils.Container verticalCenter={false}>
        <Utils.Card title="Applications" isLoading={isLoadingApplications} width="100%">
          <Utils.CustomTabs
            tabs={user ? Object.values(Tabs) : [Tabs.ALL]}
            onChangeSelectedTab={(tab) => setSelectedTab(tab as Tabs)}
            selectedTab={selectedTab}
            additionnalContainerClassNames={style.additionnalContainerClassNames}
            additionnalChildrenClassNames={style.additionnalChildrenClassNames}
            responsive
          >
            <div className={classNames(style.tabContainer, 'onboarding-discovercategories-2nd')}>
              <TopBar ref={topBarRef} applicationsLength={filteredApplications.length} />

              <div
                className={style.applicationsContainer}
                style={dimensions.width >= RESPONSIVE_LIMIT ? GridListStyles[gridMode] : GridListStyles[GridMode.ROW]}
              >
                {!isLoadingApplications &&
                  filteredApplications.map((application) => (
                    <ApplicationItem
                      application={application}
                      gridMode={dimensions.width >= RESPONSIVE_LIMIT ? gridMode : GridMode.ROW}
                      companyApplication={companyApplications.find((cA) => cA.product.id === application.id)}
                      handleTryApplication={handleTryApplication}
                      toggleAppInBasket={toggleAppInBasket}
                      setSelectedApplication={setSelectedApplication}
                    />
                  ))}
              </div>
            </div>
          </Utils.CustomTabs>
        </Utils.Card>
      </Utils.Container>

      <MoreInfoModal
        isOpen={selectedApplication !== undefined}
        onClose={() => setSelectedApplication(undefined)}
        application={selectedApplication as MidApplication}
        setAppOpened={(denomination) => setSelectedApplication(applications?.find((app) => app.denomination === denomination))}
        companyApplication={companyApplications.find((cA) => cA.product.id === selectedApplication?.id)}
        handleTryApplication={handleTryApplication}
        toggleAppInBasket={toggleAppInBasket}
      />

      <AskTrialModal
        isOpen={selectedTrialApplication !== undefined}
        onClose={() => setSelectedTrialApplication(undefined)}
        application={selectedTrialApplication}
      />
    </>
  );
}
