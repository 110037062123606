import React from 'react';

import ReactModal from 'react-modal';

import style from './Modal.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  closable?: boolean;
  onClose?: () => void;
  width?: string;
  title?: string;
  verticalCenter?: boolean;
}

export default function Modal({ children, isOpen = true, closable = true, onClose, width, title, verticalCenter }: Props) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classNames(style.blackDiv, {
        [style.verticalCenter]: verticalCenter !== false,
      })}
      className={style.modal}
      onRequestClose={closable ? onClose : undefined}
      style={{ content: { width } }}
    >
      {closable ? <FontAwesomeIcon id="close-modal-icon" className={style.closeIcon} icon={faTimesCircle} onClick={onClose} /> : null}
      {title !== undefined ? <p className={style.modalTitle}>{title}</p> : null}

      {children}
    </ReactModal>
  );
}
