import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import style from './SortMenuRow.module.css';

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function SortMenuRow({ title, children }: Props) {
  const [isRowOpen, setIsRowOpen] = useState(false);

  return (
    <div
      className={classNames(style.sortMenuRow, { [style.isOpen]: isRowOpen })}
    >
      <div className={style.titleRow} onClick={() => setIsRowOpen((b) => !b)}>
        <p>{title}</p>
        <FontAwesomeIcon icon={isRowOpen ? faMinus : faPlus} />
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
}
