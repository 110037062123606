import React from 'react';
import classNames from 'classnames';

import style from './Container.module.css';

interface Props {
  children: React.ReactNode;
  verticalCenter?: boolean;
}

export default function Container({ children, verticalCenter = true }: Props) {
  return (
    <div
      className={classNames(style.container, {
        [style.verticalCenter]: verticalCenter,
      })}
    >
      {children}
    </div>
  );
}
