import React, { ReactElement, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import style from './Input.module.css';

import classNames from 'classnames';
import PopOver from '../PopOver/PopOver';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type?: string;
  label?: string | ReactElement;
  placeholder?: string;
  subtext?: React.ReactNode;
  icon?: IconDefinition;
  hidable?: boolean;
  isInvalid?: boolean;
  isInvalidMessage?: string;
  inverted?: boolean;
  width?: string;
  colorReverse?: boolean;
  clear?: () => void;
  inputContainerProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  helpIcon?: IconDefinition;
  helpContent?: string;
  helpStyle?: React.CSSProperties;
}

export default function IconInputHidable(props: Props) {
  const [isInputShown, setIsInputShown] = useState(false);

  return (
    <div>
      {!!props.label ? (
        <div className={style.labelContainer}>
          <label className={style.label}>{props.label}</label>
          {props.helpIcon !== undefined && (
            <PopOver style={props.helpStyle} content={props.helpContent}>
              <FontAwesomeIcon icon={props.helpIcon} className={style.helpIcon} />
            </PopOver>
          )}
        </div>
      ) : null}
      <div
        {...props.inputContainerProps}
        className={classNames(
          style.iconInputContainer,
          {
            [style.isInvalid]: props.isInvalid,
            [style.haveLabel]: !!props.label,
            [style.haveSubText]: !!props.subtext,
            [style.inverted]: props.inverted === true,
            [style.colorReverse]: props.colorReverse === true,
          },
          props.inputContainerProps?.className
        )}
        style={{ ...props.inputContainerProps?.style, width: props.width }}
      >
        {props.icon !== undefined ? <FontAwesomeIcon icon={props.icon} color="#B4B4B4" className={style.icon} /> : null}
        <input {...props} type={isInputShown || props.hidable !== true ? props.type : 'password'} className={style.iconInput} />
        {props.clear !== undefined && props.value && props.value.toString().length > 0 && (
          <i className={style.clearableCross} onClick={props.clear}>
            ×
          </i>
        )}
        {props.hidable === true ? (
          <FontAwesomeIcon
            icon={isInputShown ? faEyeSlash : faEye}
            color="#B4B4B4"
            className={style.iconEye}
            onClick={() => setIsInputShown((before) => !before)}
          />
        ) : null}
      </div>
      {!!props.isInvalidMessage ? <p className={style.isInvalidText}>{props.isInvalidMessage}</p> : null}
      {!!props.subtext ? <p className={style.subtext}>{props.subtext}</p> : null}
    </div>
  );
}
