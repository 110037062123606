import React, { CSSProperties } from "react";
import classNames from "classnames";
import style from "../../css/old/ImportantNumber.module.css";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  number: number;
  text: string;
  textPlural?: string;
  numberStyle?: CSSProperties | undefined;
  textStyle?: CSSProperties | undefined;
}

export default function ImportantNumber({
  number,
  text,
  textPlural,
  numberStyle,
  textStyle,
  ...props
}: Props) {
  return (
    <p {...props} className={classNames(style.container, props.className)}>
      <span className={style.number} style={numberStyle}>
        {number}
      </span>{" "}
      <span className={style.text} style={textStyle}>
        {textPlural === undefined ? text : number > 1 ? textPlural : text}
      </span>
    </p>
  );
}
