import { Link } from 'react-router-dom';

import { List, PlusSquare, Person, PersonPlus, Building, Check, ConeStriped } from 'react-bootstrap-icons';

import style from './SidePannel.module.css';
import classNames from 'classnames';
import { useEffect } from 'react';
import { TITLES } from '../../utils/constantes';

export default function SidePannelAdmin({ children }: { children: React.ReactElement }) {
  useEffect(() => {
    document.title = TITLES.ADMIN;
  }, []);
  return (
    <div className={style.widthContainer}>
      <div className={classNames(style.left, style.opened)} style={{ justifyContent: 'normal' }}>
        <p>Applications</p>
        <Link to="/a/app">
          <List /> Liste des applications
        </Link>
        <Link to="/a/app/create">
          <PlusSquare /> Créer une application
        </Link>

        <p>Utilisateurs</p>
        <Link to="/a/user">
          <Person /> Utilisateurs
        </Link>
        <Link to="/a/user/create">
          <PersonPlus /> Ajouter un utilisateur
        </Link>

        <p>Entreprises</p>
        <Link to="/a/company">
          <Building /> Entreprises
        </Link>
        <Link to="/a/company/create">
          <PlusSquare /> Créer une entreprise
        </Link>
        <Link to="/a/company/testing">
          <ConeStriped /> Suivi des tests
        </Link>

        <p>Consultants</p>
        <Link to="/a/consultants/unconfirmed">
          <Check /> Non confirmés
        </Link>
        <p>Patchnotes</p>
        <Link to="/a/patchnotes">
          <List /> Patchnotes
        </Link>
      </div>
      <div className={classNames(style.right, style.opened)}>{children}</div>
    </div>
  );
}
