import classNames from 'classnames';
import { useEffect, useState } from 'react';
import style from './Tabs.module.css';

interface Item {
  title: string;
  renderFunction: any;
  key: any;
}

interface Props {
  items: Item[];
  itemsDependencies: any;
  selectedItem?: number;
  updateSelectedItem?: (index: number) => void;
}

export default function Tabs(props: Props) {
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(props.items.length > 0 ? props.items[0] : undefined);

  useEffect(() => {
    props.selectedItem !== undefined && props.items[props.selectedItem] !== undefined && setSelectedItem(props.items[props.selectedItem]);
  }, [props.selectedItem]);

  useEffect(() => {
    props.updateSelectedItem !== undefined && selectedItem && props.updateSelectedItem(props.items.indexOf(selectedItem));
  }, [selectedItem]);

  return (
    <div className={style.tabContainer}>
      <div className={style.tabItemsContainer}>
        {props.items.map((item) => (
          <div
            key={item.title}
            className={classNames(style.tabItem, selectedItem?.title === item.title && style.selected)}
            onClick={() => setSelectedItem(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className={style.tabContent}>{selectedItem?.renderFunction(...props.itemsDependencies[selectedItem.key])}</div>
    </div>
  );
}
