import Card from './Card/Card';
import Container from './Container/Container';
import Input from './Input/Input';
import Button from './Button/Button';
import LinkButton from './Button/LinkButton';
import Divider from './Divider/Divider';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import Modal from './Modal/Modal';
import ConfirmModal from './Modal/ConfirmModal';
import Spinner from './Spinner/Spinner';
import TopBar from './TopBar/TopBar';
import PopOver from './PopOver/PopOver';
import TabsContainer from './Tabs/TabsContainer';
import Tabs from './Tabs/Tabs';
import CustomTabs from './Tabs/CustomTabs';
import Table from './Table/Table';
import ImportantNumber from './ImportantNumber/ImportantNumber';
import Select from './Select/Select';
import GoBack from './GoBack/GoBack';
import Switch from './Switch/Switch';
import ButtonDelete from './Button/ButtonDelete';
import Radio from './Radio/Radio';
import Sort from './Sort/Sort';
import Textarea from './Textarea/Textarea';
import AppPresentation from './AppPresentation/AppPresentation';
import SortMenu from './SortMenu/SortMenu';
import SortMenuRow from './SortMenu/SortMenuRow';
import HorizontalContainer from './HorizontalContainer/HorizontalContainer';
import Checkbox from './Checkbox/Checkbox';

const Utils = {
  Card,
  Container,
  Input,
  LinkButton,
  Divider,
  Button,
  ErrorMessage,
  Modal,
  ConfirmModal,
  Spinner,
  TopBar,
  PopOver,
  TabsContainer,
  Tabs,
  CustomTabs,
  Table,
  ImportantNumber,
  Select,
  GoBack,
  Switch,
  ButtonDelete,
  Radio,
  Sort,
  Textarea,
  AppPresentation,
  SortMenu,
  SortMenuRow,
  HorizontalContainer,
  Checkbox,
};

export default Utils;
