import { useState, useContext, createContext } from 'react';
import { User } from '../api/_type';

export type UserContextType = [
  User | undefined,
  React.Dispatch<React.SetStateAction<User | undefined>>
];

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserContextProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [user, setUser] = useState<User | undefined>(undefined);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
