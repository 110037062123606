import React from "react";
import classNames from "classnames";
import * as Mantine from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { WINYLO_THEME, getMantineColors } from "..";

interface IButtonProps
  extends PolymorphicComponentProps<"button", Mantine.ButtonProps> {
  btnRef?: React.Ref<HTMLButtonElement>;
  color?: Mantine.DefaultMantineColor | "custom";
  format?: "round" | "square";
  customColor?: Record<"custom", Mantine.Tuple<string, 10>>;
  textStyles?: React.CSSProperties;
}

export default function Button({
  btnRef,
  color = "blue",
  format = "round",
  customColor,
  textStyles,
  children,
  ...props
}: IButtonProps) {
  return (
    <Mantine.MantineProvider
      theme={{
        ...WINYLO_THEME,
        colors: {
          ...WINYLO_THEME.colors,
          ...customColor,
        },
      }}
    >
      <Mantine.Button
        ref={btnRef}
        color={color}
        radius={format === "round" ? "2.5rem" : "0.25rem"}
        {...props}
      >
        <span
          style={{
            color:
              color === "blue" || color === "red" || color === "green"
                ? WINYLO_THEME.colors.gray[0]
                : WINYLO_THEME.colors.dark[6],
            ...textStyles,
          }}
        >
          {children}
        </span>
      </Mantine.Button>
    </Mantine.MantineProvider>
  );
}
