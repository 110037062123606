import style from './GoBack.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  to: string;
}

export default function GoBack({ text, to }: Props) {
  return (
    <div className={style.goBack}>
      <FontAwesomeIcon icon={faArrowLeft} />
      <Link to={to}>{text}</Link>
    </div>
  );
}
