import React, { useState, useEffect, useRef } from 'react';
import Winylo from '../../react_components';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile, logger, parseHash } from '../../utils/utils';
import api from '../../utils/api';
import { JWT_PERIOD } from '../../utils/constantes';
import { UserContextType, useUser } from '../../utils/context/User';
import { HasCheckLoginContextType, useHasCheckLogin } from '../../utils/context/HasCheckLogin';
import Utils from '../../components/Utils';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import style from './Login.module.css';
import { UserSchema } from '../../utils/schemas';

interface LoginFormValue {
  login: string;
  password: string;
}

// const initialValues: LoginFormValue = {
//   login: '',
//   password: '',
// };

const initialValues: LoginFormValue = {
  login: '',
  password: '',
};

const LoginSchema = Yup.object().shape({
  login: Yup.string().required('Ce champ est obligatoire'),
  password: Yup.string().required('Ce champ est obligatoire'),
});

export default function Login(): React.ReactElement {
  const [redirectUrl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formik = useRef<FormikProps<LoginFormValue>>(null);
  const navigate = useNavigate();
  const [user, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  useEffect(() => {
    let hash = parseHash();

    setRedirectUrl(hash.get('redirect') === null ? '/apps' : decodeURIComponent(hash.get('redirect') as string));
  }, []);

  function onSubmit() {
    if (!formik.current) return;
    setIsLoading(true);

    api.loginApi
      .startConnection({ login: formik.current.values.login, password: formik.current.values.password })
      .then((data) => {
        localStorage.setItem('jwt', data.jwt);

        setUser(data.user);
        setHasCheckLogin(true);

        setIsLoading(false);

        navigate(redirectUrl);

        setInterval(refetchMe, JWT_PERIOD);
      })
      .catch((err) => {
        logger(err);
        setErrorMessage(err.message);
        formik.current?.setValues({ login: formik.current.values.login, password: initialValues.password });
        setIsLoading(false);
      });
  }

  function refetchMe() {
    api.loginApi
      .getMe()
      .then((data) => {
        logger('%cAcualisation du JWT', 'color: blue');

        localStorage.setItem('jwt', data.jwt);

        setUser(data.user);

        setInterval(refetchMe, JWT_PERIOD);
      })
      .catch((err) => {
        logger(err);
        setUser(undefined);
      });
  }

  if (user !== undefined) {
    navigate('/apps');
    return <></>;
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Veuillez vous connecter</h1>

      <Utils.Card style={{ marginTop: 0, marginBottom: 0, maxWidth: '450px', width: '100%' }}>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />

        <Formik
          innerRef={formik}
          initialValues={initialValues}
          validationSchema={LoginSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form id="login-formik" className={style.simpleForm}>
              <Winylo.TextInput
                name="login"
                placeholder="Identifiant"
                icon={<FontAwesomeIcon icon={faUser} />}
                value={values.login}
                onChange={handleChange}
                error={!LoginSchema.fields.password.isValidSync(values.login)}
                style={{ width: '100%' }}
              />

              <Winylo.TextInput
                name="password"
                placeholder="Mot de passe"
                icon={<FontAwesomeIcon icon={faLock} />}
                value={values.password}
                onChange={handleChange}
                error={!LoginSchema.fields.password.isValidSync(values.password)}
                type={'password'}
                style={{ marginTop: '0.5rem', width: '100%' }}
              />

              <div className={style.twoColumns} style={{ width: '100%' }}>
                <Winylo.Button disabled={!values.login || !values.password} loading={isLoading} type="submit">
                  Connexion
                </Winylo.Button>
                <Link className={style.link} to="/forget-password">
                  Mot de passe oublié ?
                </Link>
              </div>
            </Form>
          )}
        </Formik>

        {redirectUrl.startsWith('/appLogin') || isMobile() ? null : (
          <>
            <Utils.Divider variant="secondary" />

            <Winylo.Button style={{ marginBottom: '0.5rem' }} fullWidth color="green" onClick={() => navigate('/register')}>
              S'inscrire
            </Winylo.Button>

            <Link to="/resend-email" className={style.link}>
              Email de confirmation non reçu?
            </Link>
          </>
        )}
      </Utils.Card>
      {errorMessage !== '' ? <Utils.ErrorMessage>{errorMessage}</Utils.ErrorMessage> : null}
    </Utils.Container>
  );
}
