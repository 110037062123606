import React from "react";
import * as Mantine from "@mantine/core";
import { WINYLO_THEME } from "..";

interface ITextInputProps extends Mantine.TextInputProps {
    txtInputRef?: React.Ref<HTMLInputElement>;
    color?: Mantine.DefaultMantineColor;
    format?: "round" | "square";
    customColor?: Record<"grape", Mantine.Tuple<string, 10>>;
}

export default function TextInput({
    txtInputRef,
    color = "blue",
    format = "square",
    customColor,
    ...props
}: ITextInputProps) {
    return (
        <Mantine.MantineProvider
            theme={{
                ...WINYLO_THEME,
                colors: {
                    ...WINYLO_THEME.colors,
                    ...customColor,
                },
                primaryColor: color,
            }}
        >
            <Mantine.TextInput
                ref={txtInputRef}
                radius={format === "round" ? "2.5rem" : "0.25rem"}
                {...props}
            />
        </Mantine.MantineProvider>
    );
}
