import React from "react";
import * as Mantine from "@mantine/core";
import { ControlsEnum, WINYLO_THEME } from "..";
import { RichTextEditor, RichTextEditorProps } from "@mantine/tiptap";

interface IRichTextProps extends Omit<RichTextEditorProps, "children"> {
  color?: Mantine.DefaultMantineColor;
  format?: "round" | "square";
  customColor?: Record<"grape", Mantine.Tuple<string, 10>>;
  controlsGroups?: ControlsEnum[][];
}

export default function RichText({
  color = "blue",
  format = "square",
  customColor,
  controlsGroups = [],
  ...props
}: IRichTextProps) {
  function getOption(control: ControlsEnum): React.ReactNode {
    switch (control) {
      case ControlsEnum.bold:
        return <RichTextEditor.Bold />;
      case ControlsEnum.italic:
        return <RichTextEditor.Italic />;
      case ControlsEnum.underline:
        return <RichTextEditor.Underline />;
      case ControlsEnum.strikethrough:
        return <RichTextEditor.Strikethrough />;
      case ControlsEnum.clearformatting:
        return <RichTextEditor.ClearFormatting />;
      case ControlsEnum.highlight:
        return <RichTextEditor.Highlight />;
      case ControlsEnum.code:
        return <RichTextEditor.Code />;
      case ControlsEnum.h1:
        return <RichTextEditor.H1 />;
      case ControlsEnum.h2:
        return <RichTextEditor.H2 />;
      case ControlsEnum.h3:
        return <RichTextEditor.H3 />;
      case ControlsEnum.h4:
        return <RichTextEditor.H4 />;
      case ControlsEnum.blockquote:
        return <RichTextEditor.Blockquote />;
      case ControlsEnum.hr:
        return <RichTextEditor.Hr />;
      case ControlsEnum.bulletlist:
        return <RichTextEditor.BulletList />;
      case ControlsEnum.orderedlist:
        return <RichTextEditor.OrderedList />;
      case ControlsEnum.subscript:
        return <RichTextEditor.Subscript />;
      case ControlsEnum.superscript:
        return <RichTextEditor.Superscript />;
      case ControlsEnum.link:
        return <RichTextEditor.Link />;
      case ControlsEnum.unlink:
        return <RichTextEditor.Unlink />;
      case ControlsEnum.alignleft:
        return <RichTextEditor.AlignLeft />;
      case ControlsEnum.aligncenter:
        return <RichTextEditor.AlignCenter />;
      case ControlsEnum.alignjustify:
        return <RichTextEditor.AlignJustify />;
      case ControlsEnum.alignright:
        return <RichTextEditor.AlignRight />;
    }
  }

  return (
    <Mantine.MantineProvider
      theme={{
        ...WINYLO_THEME,
        colors: {
          ...WINYLO_THEME.colors,
          ...customColor,
        },
        primaryColor: color,
      }}
    >
      <RichTextEditor {...props}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          {controlsGroups.map((controlGroup, index) => (
            <RichTextEditor.ControlsGroup key={index}>
              {controlGroup.map((control) => getOption(control))}
            </RichTextEditor.ControlsGroup>
          ))}
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </Mantine.MantineProvider>
  );
}
