import Modal from '../../components/Utils/Modal/Modal';
import { ApplicationRole, CompanyProduct, CompanyUser, MidApplication, SubmitModalParams, User, UserFormValue } from '../../utils/api/_type';
import * as Yup from 'yup';
import { useState } from 'react';
import UserForm from './UserForm';
import { UserUpdateBody } from '../../utils/api/companies';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../utils/api';
import StatusModal from '../../components/Utils/StatusModal/StatusModal';
import BugsModal from '../../components/BugsModal/BugsModal';
import { getErrorSubmitType, getSuccessSubmitType } from './utils/submitModalTypes';
import Winylo from '../../react_components';
import sharedStyle from './css/Shared.module.css';

interface UserSettingModalProps {
  applications?: MidApplication[];
  companyApplications?: CompanyProduct[];
  selectedUser?: CompanyUser;
  onClose: () => void;
}

const successSubmitType: SubmitModalParams = getSuccessSubmitType({
  title: 'Utilisateur modifié',
  verb: 'modifié',
});

const errorSubmitType = (
  setState: (value: React.SetStateAction<SubmitModalParams>) => void,
  setIsOpen: (value: React.SetStateAction<boolean>) => void
) =>
  getErrorSubmitType({
    title: 'Problème dans la modification',
    verb: 'la modification',
    setState: setState,
    setIsOpen: setIsOpen,
  });

const initialSubmitModal: SubmitModalParams = { title: '', content: <></>, checked: false, isOpen: false };

export default function UserSettingModal(props: UserSettingModalProps) {
  const [modalSubmitType, setModalSubmitType] = useState<SubmitModalParams>(initialSubmitModal);
  const [isBugOpen, setIsBugOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape(
    {
      username: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
      email: Yup.string().email("L'email doit être valide."),
      firstname: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
      lastname: Yup.string().min(2, 'Minimum 2 caractères.').max(255, 'Maximum 255 caractères.').required('Requis.'),
      password: Yup.string().min(6, 'Minimum 6 caractères'),
      password_confirm: Yup.string().when(['password'], {
        is: (password: string) => password,
        then: (schema) => schema.oneOf([Yup.ref('password'), null], 'Les champs doivent correspondre.').min(6, 'Minimum 6 caractères'),
      }),
    },
    [
      ['email', 'password'],
      ['password_confirm', 'email'],
    ]
  );

  /**
   * Fetch la modification de l'utilisateur donné
   * Au success, si des rôles ont été définis ils sont alors envoyé via une deuxième requête
   */
  const { mutate: updateUser, isLoading: isUpdating } = useMutation(api.companies.updateCompanyUser, {
    onSuccess: (data, variable) => {
      if (variable.applicationRoles) {
        updateUserRole({ userId: variable.id, applicationRoles: variable.applicationRoles });
      } else {
        queryClient.invalidateQueries('users_company');

        setModalSubmitType(successSubmitType);
      }
    },
    onError: () => {
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Met à jour les rôles de l'utilisateur
   */
  const { mutate: updateUserRole } = useMutation(api.users.updateUserCompanyRoles, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('users_company');

      setModalSubmitType(successSubmitType);
    },
    onError: () => {
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Envoie le formulaire à l'utilisateur selectionné
   *
   * @param updated nouvelles valeurs du formulaire
   * @param selected utilisateur selectionné
   */
  function handleUpdateUser(updated: UserFormValue, selected: CompanyUser) {
    const values: { id: number; body: UserUpdateBody; applicationRoles: ApplicationRole[] } = {
      id: selected.id,
      body: {
        username: updated.username,
        email: updated.email.length > 0 ? updated.email : null,
        firstname: updated.firstname,
        lastname: updated.lastname,
        plainPassword: updated.password.length > 0 ? updated.password : undefined,
      },
      applicationRoles: updated.application_roles,
    };

    updateUser(values);
  }

  /**
   * Convertit le props.selectedUser en un objet utilisable dans "initialValues"
   */
  function castToUserFormValues(companyUser: CompanyUser): UserFormValue {
    return {
      firstname: companyUser.firstname,
      lastname: companyUser.lastname,
      email: companyUser.email || '',
      username: companyUser.username,
      password: '',
      password_confirm: '',
      application_roles: companyUser.applicationRoles,
    };
  }

  /**
   * Check et envoie le formulaire pour mettre à jour l'utilisateur
   *
   * @param values valeurs du formik
   */
  async function handleFormSubmit(values: UserFormValue) {
    await new Promise((r) => setTimeout(r, 500));
    props.selectedUser && handleUpdateUser(values, props.selectedUser);
  }

  return (
    <Modal
      width="80%"
      verticalCenter
      title={`Paramètres de ${props.selectedUser?.firstname}`}
      isOpen={!!props.selectedUser}
      onClose={() => props.onClose()}
      closable
    >
      <div className={sharedStyle.container}>
        <UserForm
          user={props.selectedUser}
          initialValues={props.selectedUser && castToUserFormValues(props.selectedUser)}
          applications={props.applications}
          companyApplications={props.companyApplications}
          validationSchema={validationSchema}
          handleSubmit={handleFormSubmit}
          isLoading={isUpdating}
        />

        <StatusModal
          title={modalSubmitType.title}
          content={modalSubmitType.content}
          check={modalSubmitType.checked}
          isOpen={modalSubmitType.isOpen}
          onClose={() => {
            setModalSubmitType(initialSubmitModal);
            props.onClose();
          }}
          errorCode={modalSubmitType.errorCode}
        />

        <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />
      </div>
    </Modal>
  );
}
