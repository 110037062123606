import style from './Switch.module.css';

interface Props {
  checked?: boolean;
  defaultChecked?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

export default function Switch({ checked, defaultChecked, onClick }: Props) {
  return (
    <label className={style.switch}>
      <input
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        onClick={onClick}
      />
      <span className={style.slider}></span>
    </label>
  );
}
