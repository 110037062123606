import Container from 'react-bootstrap/Container';

export default function Privacy() {
  return (
    <Container>
      <h1>Confidentialité</h1>
      <h2> Introduction</h2>
      <p>
        La vie privée est importante pour chacun de nous, et c'est pour ça que C-Koya Tech s'engage à faire le nécessaire pour protéger toutes les
        informations que vous nous confiez.
      </p>
      <p>Vous trouverez ci-dessous les informations relatives aux données que nous sommes susceptibles de recueillir.</p>
      <p>
        Si vous avez des questions, merci de les envoyer à <a href="mailto:contact@c-koya.tech">contact@c-koya.tech</a>
      </p>
      <h2>Données personnelles que nous recueillons</h2>
      <p>Au moment de votre inscription, nous récupérons votre nom, votre prénom ainsi que votre adresse mail.</p>
      <p>
        Lors de l’utilisation de CKTools, votre adresse IP est également enregistrée, sans être directement corrélée avec les autres données
        personnelles.
      </p>
      <p>Les informations que vous mettez dans CKTools sont traitées comme des données personnelles et sont soumises aux mêmes contraintes.</p>
      <h2>Utilisation des données personnelles</h2>
      <p>Votre nom et votre prénom sont exclusivement utilisés pour que vos collaborateurs et l’application puissent identifier votre compte.</p>
      <p>
        L’adresse mail est utilisée à des fins techniques pour vous authentifier, et afin de vous envoyer certaines informations concernant
        exclusivement CKTools.
      </p>
      <p>L’adresse IP est exclusivement utilisée à des fins techniques.</p>
      <p>Vos données ne sont en aucun cas utilisées à des fins commerciales ni transmises à des organismes tiers.</p>
      <h2> Données techniques</h2>
      <p>
        Pour des raisons exclusivement techniques, nous sommes susceptibles de récupérer des informations non personnelles, comme le navigateur
        utilisé, le langage utilisé, ou encore la date d’utilisation.
      </p>
      <h2>Protection des données personnelles</h2>
      <p>
        L’ensemble des transactions avec C-Koya Tech sont protégées à l’aide du cryptage TLS (Transport Layer Security), reconnu comme standard de sécurité.
      </p>
      <p>L’endroit où sont stockées les données n’est accessible que par un nombre très restreint d’administrateurs.</p>
      <p>
        Les données sensibles sont <a href="https://fr.wikipedia.org/wiki/Fonction_de_hachage">hashées</a>.
      </p>
      <p>
      C-Koya Tech s’engage à ne pas accéder à vos informations quelque soit leur nature en dehors d’accès raisonnable afin d’assurer un service de
        qualité.
      </p>
      <h2>Conservation des données personnelles</h2>
      <p>Les données personnelles sont conservées pendant toute la durée d’activité du compte.</p>
      <p>
        Une fois le compte désactivé, les informations sont conservées pendant une durée de 5 ans, avant d’être anonymisées, ce afin de pouvoir garder
        une trace statistique des activités.
      </p>
      <h2>Accès et suppression des données personnelles</h2>
      <p>
        Vous pouvez à tout moment nous contacter afin de voir l’ensemble des informations que nous avons à votre sujet, ainsi que demander la
        suppression totale de vos données personnelles.
      </p>
    </Container>
  );
}
