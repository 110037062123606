import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface ConditionalPortalProps {
  condition: boolean;
  refA: React.RefObject<HTMLElement>;
  refB: React.RefObject<HTMLElement>;
  children: React.ReactNode;
}

export default function ConditionalPortal({ condition, refA, refB, children }: ConditionalPortalProps) {
  const [target, setTarget] = useState<HTMLElement>();

  useEffect(() => {
    if (refA.current === null || refB.current === null) return;
    setTarget(condition ? refA.current : refB.current);
  }, [condition, refA, refB]);

  return target ? createPortal(children, target) : null;
}
