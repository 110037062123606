import React from "react";
import * as Mantine from "@mantine/core";
import { WINYLO_THEME } from "..";

interface IPasswordInputProps
  extends Mantine.PasswordInputProps {
  pwdInputRef?: React.Ref<HTMLInputElement>;
  color?: Mantine.DefaultMantineColor | "custom";
  format?: "round" | "square";
  customColor?: Record<"custom", Mantine.Tuple<string, 10>>;
}

export default function PasswordInput({
  pwdInputRef,
  color = "blue",
  format = "square",
  customColor,
  ...props
}: IPasswordInputProps) {
  return (
    <Mantine.MantineProvider
      theme={{
        ...WINYLO_THEME,
        colors: {
          ...WINYLO_THEME.colors,
          ...customColor,
        },
        primaryColor: color,
      }}
    >
      <Mantine.PasswordInput
        ref={pwdInputRef}
        radius={format === "round" ? "2.5rem" : "0.25rem"}
        {...props}
      />
    </Mantine.MantineProvider>
  );
}
