import { useEffect, useState } from 'react';

import { ChatFill } from 'react-bootstrap-icons';
import style from './style.module.css';
import { UserContextType, useUser } from '../../utils/context/User';

import * as Yup from 'yup';
import { useMutation } from 'react-query';
import api from '../../utils/api';
import Toastify from 'toastify-js';
import Utils from '../Utils';
import { useLocation } from 'react-router-dom';
import { useFormState } from '../../utils/utils';

export default function ContactBubble() {
  const [user] = useUser() as UserContextType;
  const [isModalContactOpen, setIsModalContactOpen] = useState(false);
  const location = useLocation();

  const { mutate: sendContact } = useMutation(api.loginApi.contact, {
    onSuccess: (data, variable) => {
      setIsModalContactOpen(false);

      Toastify({
        text: 'Question envoyée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  useEffect(() => {
    setFormState((before) => ({
      ...before,
      from: user !== undefined ? user.email : '',
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [formState, handleForm, setFormState] = useFormState({
    from: '',
    subject: '',
    message: '',
  });

  const ContactSchema = Yup.object().shape({
    from: Yup.string().email(),
    subject: Yup.string().min(2),
    message: Yup.string().min(2),
  });

  function handleSendContact() {
    if (!ContactSchema.isValidSync(formState)) return;

    sendContact(formState);
  }

  function showBubble() {
    return !(location.pathname.startsWith('/appLogin') || location.pathname.startsWith('/login'));
  }

  return showBubble() ? (
    <>
      <div className={style.container} onClick={() => setIsModalContactOpen(true)}>
        <ChatFill size={45} color="white" />
      </div>

      <Utils.Modal isOpen={isModalContactOpen} closable onClose={() => setIsModalContactOpen(false)} title="Nous contacter">
        <Utils.Input
          label="Votre email"
          value={formState.from}
          onChange={handleForm}
          name="from"
          type="email"
          isInvalid={!ContactSchema.fields.from.isValidSync(formState.from)}
        />
        <Utils.Input
          label="Sujet"
          value={formState.subject}
          onChange={handleForm}
          name="subject"
          isInvalid={!ContactSchema.fields.subject.isValidSync(formState.subject)}
        />
        <Utils.Textarea
          label="Message"
          rows={5}
          value={formState.message}
          onChange={handleForm}
          name="message"
          isInvalid={!ContactSchema.fields.message.isValidSync(formState.message)}
        />
        <Utils.HorizontalContainer>
          <Utils.Button variant="gray" onClick={() => setIsModalContactOpen(false)}>
            Fermer
          </Utils.Button>
          <Utils.Button onClick={handleSendContact} disabled={!ContactSchema.isValidSync(formState)}>
            Envoyer
          </Utils.Button>
        </Utils.HorizontalContainer>
      </Utils.Modal>
    </>
  ) : (
    <></>
  );
}
