import classNames from "classnames";
import React from "react";
import { ReactSVG } from "react-svg";

import style from "../../css/old/SortMenu.module.css";

interface Props
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    title: string;
    children?: React.ReactNode;
    onClear?: () => void;
}

export default function SortMenu(props: Props) {
    return (
        <div {...props} className={classNames(style.menu, props.className)}>
            <div className={style.titleContainer}>
                <p>{props.title}</p>
                {props.onClear !== undefined && (
                    <ReactSVG
                        src={"../../svg/eraser-solid.svg"}
                        className={style.eraser}
                        title={"Réinitialiser les filtres"}
                        onClick={props.onClear}
                    />
                )}
            </div>
            {props.children}
        </div>
    );
}
