import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

import style from "../../css/old/SidePannel.module.css";

interface Action {
    text: string;
    path?: string;
    onClick?: () => void;
}
interface ActionGroup {
    text: string;
    actions: Action[];
}

interface Props {
    children?: React.ReactElement;
    actions?: ActionGroup[];
}

export default function SidePannel(props: Props) {
    // const [user] = useUser() as UserContextType;
    const location = useLocation();
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(true);
    // const [selectedAction, setSelectedAction] = useState(null);

    function onClick(action: Action) {
        if (action.path !== undefined) navigate(action.path);
        if (action.onClick !== undefined) action.onClick();
    }

    function renderActionGroup() {
        return props.actions?.map((group) => (
            <>
                <p className={style.actionTitle}>{group.text}</p>
                {group.actions.map((action) => {
                    return (
                        <div
                            className={classNames(style.action, {
                                [style.selectedRow]:
                                    location.pathname === action.path,
                            })}
                            onClick={() => onClick(action)}
                        >
                            <span className={style.iconContainer}>
                                <i
                                    className={classNames(
                                        "fa fa-edit fa-1x",
                                        style.icon
                                    )}
                                />
                            </span>
                            <span className={style.actionText}>
                                {action.text}
                            </span>
                        </div>
                    );
                })}
            </>
        ));
    }

    return (
        <div className={style.container}>
            <div
                className={classNames(style.left, { [style.open]: isMenuOpen })}
            >
                {isMenuOpen ? (
                    <ReactSVG
                        src={"../../svg/burger-close.svg"}
                        title="Fermer"
                        className={style.openCloseIcon}
                        onClick={() => setIsMenuOpen((before) => !before)}
                    />
                ) : (
                    <ReactSVG
                        src={"../../svg/burger-open.svg"}
                        title="Ouvrir"
                        className={style.openCloseIcon}
                        onClick={() => setIsMenuOpen((before) => !before)}
                    />
                )}
                {renderActionGroup()}
            </div>
            <div
                className={classNames(style.right, {
                    [style.open]: isMenuOpen,
                })}
            >
                {props.children}
            </div>
        </div>
    );
}
