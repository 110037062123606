import { SmallApplication } from '../../../utils/api/_type';

import style from './AppPresentation.module.css';

interface Props {
  application: SmallApplication;
}

export default function AppPresentation({ application }: Props) {
  return (
    <div className={style.appPresentation}>
      {application.fileUri !== null ? (
        <img src={application.fileUri} alt={`Logo de ${application.fileUri}`} />
      ) : null}
      <p>{application.name}</p>
    </div>
  );
}
