import { checkStatus, host } from '../api';
import { BasketApplication } from '../context/Basket';

export interface PreviewSubscriptionResponse {
  id: string | null;
  number: string | null;
  created: number;
  amount_due: number;
  status: string;
  items: any[];
  applicationsIds: number[];
}

interface BuyResponse {
  subscriptionId: string;
  paid: boolean;
  clientSecret: string;
  paymentMethodType: 'card' | 'sepa_debit' | 'unknown';
}

const payment = {
  previewSubscription: (ids: number[]): Promise<PreviewSubscriptionResponse> => {
    return fetch(`${host}/api/payment/preview`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ applicationsIds: ids }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  buyApplication: (body: { products: { id: number; quantity: number }[] }): Promise<BuyResponse> => {
    return fetch(`${host}/api/payment/subscribe`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  unsubscribe: (id: number): Promise<any> => {
    return fetch(`${host}/api/payment/unsubscribe`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ product: id }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateAddonQuantity: ({ id, quantity }: { id: number; quantity: number }): Promise<any> => {
    return fetch(`${host}/api/payment/addon/${id}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ quantity }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  createCheckoutSession: ({ basket }: { basket: BasketApplication[] }): Promise<any> => {
    return fetch(`${host}/api/payment/checkout`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(
        basket.map((basketItem) => ({
          id: basketItem.application.id,
          quantity: (basketItem.application as any).quantity === undefined ? 1 : (basketItem.application as any).quantity,
        }))
      ),
    })
      .then(checkStatus)
      .then(async (res) => {
        const body: any = await res.json();
        return body.url;
      });
  },
  createPortalSession: ({}: {}): Promise<any> => {
    return fetch(`${host}/api/payment/portal`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(async (res) => {
        const body: any = await res.json();
        return body.url;
      });
  },
};

export default payment;
