import { useContext, useEffect, useRef, useState } from 'react';

import {
  faBullhorn,
  faCaretDown,
  faCheck,
  faExclamationTriangle,
  faHome,
  faHourglassHalf,
  faPowerOff,
  faQuestion,
  faShoppingCart,
  faSignInAlt,
  faThLarge,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasketAddon, BasketContextType, useBasket } from '../../../utils/context/Basket';
import { UserContextType, useUser } from '../../../utils/context/User';

import { useStripe } from '@stripe/react-stripe-js';
import Winylo from '../../../react_components';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Utils from '..';
import { createUserSteps } from '../../../onboardingtours/create-user.steps';
import { discoverApplicationsSteps } from '../../../onboardingtours/discover-applications.steps';
import { editUserSteps } from '../../../onboardingtours/edit-user.steps';
import { firstConnectionSteps } from '../../../onboardingtours/first-connection.steps';
import { storageManagementSteps } from '../../../onboardingtours/storage-management.steps';
import CheckoutLoadingModal from '../../../pages/payment/CheckoutLoadingModal/CheckoutLoadingModal';
import api from '../../../utils/api';
import { ROLE } from '../../../utils/api/_type';
import { formatUserRoleToString, isAdmin, numberToPrice, startOnBoardingTour } from '../../../utils/utils';
import BugsModal from '../../BugsModal/BugsModal';

import { Tooltip } from '@mantine/core';
import { vatVerifiedEnum } from '../../../utils/enums/vatVerifiedEnum';
import style from './TapBar.module.css';
import { ShepherdOptionsWithType, ShepherdTourContext } from 'react-shepherd';

export default function TopBar() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const stripe = useStripe();

  const hideTopbar = ['/appLogin'];
  const notLoggedRoute = ['/login', '/register', '/'];
  const showEvenWhenNotLoggedRoutes = ['/', '/apps', '/appsTemp'];

  const [user, setUser] = useUser() as UserContextType;
  const [vatVerified, setVatVerified] = useState<boolean>(false);
  const [basket, setBasket] = useBasket() as BasketContextType;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBugOpen, setIsBugOpen] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState<boolean>(false);

  const onboarding = useRef<any>(null);
  const onboardingButton = useRef<any>(null);
  const [isOnboardingOpen, setIsOnBoardingOpen] = useState<boolean>(false);
  const tour = useContext(ShepherdTourContext);

  const menu = useRef<any>(null);
  const buttonMenu = useRef<any>(null);
  const [hasEventListener, setHasEventListener] = useState(false);

  function logout() {
    localStorage.removeItem('jwt');
    setUser(undefined);
    setBasket([]);
    setIsMenuOpen(false);
    setIsOnBoardingOpen(false);
    queryClient.clear();

    if (!notLoggedRoute.includes(location.pathname)) {
      navigate('/login#redirect=' + location.pathname);
    }
  }

  function toggleMenu() {
    if (!isMenuOpen && !hasEventListener) {
      document.addEventListener('click', handleOutsideClick, false);
      setHasEventListener(true);
    }
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  }

  function toggleOnboarding() {
    if (!hasEventListener) {
      document.addEventListener('click', handleOutsideClick, false);
      setHasEventListener(true);
    }
    setIsOnBoardingOpen((before) => !before);
  }

  const handleOutsideClick = (e: Event) => {
    // Gestion menu
    const menuButton = document.getElementById('topbar-menu-button') as HTMLElement;
    const menuContainer = document.getElementById('topbar-menu-container') as HTMLElement;

    // Gestion onBoarding
    const onBoardingButton = document.getElementById('topbar-onboarding-button') as HTMLElement;
    const onBoardingContainer = document.getElementById('topbar-onboarding-container') as HTMLElement;

    // Gestion basket
    const basketButton = document.getElementById('topbar-basket-button') as HTMLElement;
    const basketContainer = document.getElementById('topbar-basket-container') as HTMLElement;

    const discoverCategoriesNextButton = document.getElementsByClassName('onboarding-discovercategories-button-next-4th')[0];

    if (discoverCategoriesNextButton !== undefined && discoverCategoriesNextButton.contains(e.target as HTMLElement)) {
      return;
    }

    if (basketContainer && !basketContainer.contains(e.target as Node) && !basketButton.contains(e.target as Node)) {
      toggleBasket();
    }

    if (menuContainer && !menuContainer.contains(e.target as Node) && !menuButton.contains(e.target as Node)) {
      toggleMenu();
    }
    if (onBoardingContainer && !onBoardingContainer.contains(e.target as Node) && !onBoardingButton.contains(e.target as Node)) {
      toggleOnboarding();
    }
  };

  function redirectTo(route: string) {
    setIsMenuOpen(false);
    navigate(route);
  }

  // Gestion du panier
  const [isBasketOpen, setIsBasketOpen] = useState(false);

  function toggleBasket() {
    if (!isBasketOpen && !hasEventListener) {
      document.addEventListener('click', handleOutsideClick, false);
      setHasEventListener(true);
    }
    setIsBasketOpen((isMenuOpen) => !isMenuOpen);
  }

  function handleStartOnboarding(steps: ShepherdOptionsWithType[]) {
    toggleMenu();
    startOnBoardingTour(tour, steps);
  }

  function createCheckoutSession() {
    if (!stripe) {
      return;
    }

    setIsCheckoutLoading(true);
    api.payment
      .createCheckoutSession({ basket })
      .then((url) => {
        setBasket([]);
        window.location.href = url;
      })
      .finally(() => setIsCheckoutLoading(false));
  }

  useEffect(() => {
    setVatVerified(user?.company?.vatVerified === vatVerifiedEnum.VERIFIED || user?.company?.vatVerified === vatVerifiedEnum.EXONERATED);
  }, [user]);

  return (user !== undefined || showEvenWhenNotLoggedRoutes.includes(location.pathname)) && !hideTopbar.includes(location.pathname) ? (
    <>
      <div className={style.topbarSpace}></div>
      <div className={style.topbar}>
        <img
          onClick={() => navigate('/apps')}
          className={classNames(style.thickLogo, style.logoWinylo)}
          src={process.env.PUBLIC_URL + '/img/topbar.png'}
          alt="Logo d'CKTools"
        />

        <img
          onClick={() => navigate('/apps')}
          className={classNames(style.lightLogo, style.logoWinylo)}
          src={process.env.PUBLIC_URL + '/img/topbar-light.png'}
          alt="Logo d'CKTools"
        />

        <div className={classNames(style.firstSeparator, style.separator)} />

        <div className={style.navLightButtons}>
          <div className={style.category}>
            <Link
              to={'/'}
              className={classNames(
                {
                  [style.selectedRow]: location.pathname === '/',
                },
                style.menuRow
              )}
            >
              <span className={style.iconContainer}>
                <FontAwesomeIcon icon={faHome} className={style.icon} />
              </span>
            </Link>
          </div>

          <div className={classNames(style.category, 'onboarding-firstconnection-3rd')}>
            <Link
              to={'/apps'}
              className={classNames(
                {
                  [style.selectedRow]: location.pathname === '/apps',
                },
                style.menuRow
              )}
            >
              <span className={style.iconContainer}>
                <FontAwesomeIcon icon={faThLarge} className={style.icon} />
              </span>
            </Link>
          </div>

          <div className={style.category}>
            <span className={style.iconContainer} onClick={() => setIsBugOpen(true)}>
              <FontAwesomeIcon icon={faBullhorn} className={style.icon} />{' '}
            </span>
            {/* </Link> */}
          </div>
        </div>
        <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />

        <CheckoutLoadingModal isOpen={isCheckoutLoading} onClose={() => setIsCheckoutLoading(false)} />

        <div className={style.separator} />

        {user !== undefined ? (
          <>
            {isAdmin(user) && (
              <div
                id="topbar-onboarding-button"
                className={classNames(style.iconContainer, style.basketContainer, style.onboarding, 'onboarding-firstconnection-7th')}
                onClick={toggleOnboarding}
              >
                <FontAwesomeIcon className={style.icon} icon={faQuestion} />
              </div>
            )}

            {user.roles.includes(ROLE.ROLE_COMPANY_ADMIN) ? (
              <div
                id="topbar-basket-button"
                className={classNames(
                  style.iconContainer,
                  style.basketContainer,
                  'onboarding-discovercategories-4th',
                  'onboarding-storagemanagement-4th'
                )}
                onClick={(e) => {
                  toggleBasket();
                }}
              >
                <p className={style.iconNumber}>{basket.length}</p>
                <FontAwesomeIcon className={style.icon} icon={faShoppingCart} />
              </div>
            ) : null}

            <div id="topbar-menu-button" className={classNames(style.userContainer, 'onboarding-firstconnection-8th')} onClick={toggleMenu}>
              <div className={style.userInfoContainer}>
                <p className={style.userInfoName}>
                  {user.firstname} {user.lastname.substring(0, 1)}.
                </p>
                <p className={style.userInfoRole}>{formatUserRoleToString(user)}</p>
              </div>

              <Winylo.Avatar
                unstyled
                size={'40px'}
                className={style.userIconContainer}
                imageProps={{ style: { width: '100%', height: '100%' } }}
                src={user.company?.uri || './logo_entreprise.png'}
              >
                <FontAwesomeIcon icon={faUser} className={style.userIcon} />
              </Winylo.Avatar>
              <FontAwesomeIcon icon={faCaretDown} className={style.caretDown} />
            </div>
          </>
        ) : (
          <>
            <Utils.Button
              containerClassName={style.registerButton}
              format="square"
              fullWidth={false}
              variant={'secondary'}
              onClick={() => navigate('/register')}
              style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem', marginRight: '.625rem' }}
            >
              S'inscrire
            </Utils.Button>

            <Utils.Button
              containerClassName={style.thickLogin}
              format="square"
              fullWidth={false}
              onClick={() => navigate('/login')}
              style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}
            >
              Se connecter
            </Utils.Button>

            <FontAwesomeIcon
              className={classNames(style.lightLogin, style.icon)}
              icon={faSignInAlt}
              onClick={() => navigate('/login')}
              style={{ marginLeft: '.625rem', marginRight: '1.25rem' }}
            />
          </>
        )}

        {isMenuOpen ? (
          <div id="topbar-menu-container" className={style.menu} ref={menu}>
            <p className={style.menuProfile} onClick={() => redirectTo('/me')}>
              Mon profil
            </p>

            {user?.roles.includes(ROLE.ROLE_ADMIN) ? (
              <p className={style.menuProfile} onClick={() => redirectTo('/a/app')}>
                Administration
              </p>
            ) : null}

            {user?.roles.includes(ROLE.ROLE_ADMIN) || user?.roles.includes(ROLE.ROLE_INVOICING) ? (
              <p className={style.menuProfile} onClick={() => redirectTo('/a/invoicing')}>
                Facturation
              </p>
            ) : null}

            <p className={style.menuDisconnect} onClick={logout}>
              <FontAwesomeIcon className={style.menuDisconnectIcon} icon={faPowerOff} />
              Déconnexion
            </p>
          </div>
        ) : null}

        {isOnboardingOpen && (
          <div id="topbar-onboarding-container" className={classNames([style.onboardingMenu])}>
            <span className={style.textContent}>Découvrez les tutoriels</span>
            <div className={style.itemList}>
              <div className={style.listItem} onClick={() => handleStartOnboarding(firstConnectionSteps(navigate))}>
                <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                Première connexion
              </div>
              <div className={style.listItem} onClick={() => handleStartOnboarding(discoverApplicationsSteps(navigate))}>
                <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                Découvrir, tester et souscrire à une application
              </div>
              <div className={style.listItem} onClick={() => handleStartOnboarding(createUserSteps(navigate))}>
                <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                Ajouter un utilisateur
              </div>
              <div className={style.listItem} onClick={() => handleStartOnboarding(editUserSteps(navigate))}>
                <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                Modifier un utilisateur
              </div>
              <div className={style.listItem} onClick={() => handleStartOnboarding(storageManagementSteps(navigate))}>
                <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                Gestion du stockage
              </div>
            </div>
          </div>
        )}

        {isBasketOpen ? (
          <div id="topbar-basket-container" className={classNames(style.basket, 'onboarding-discovercategories-5th')}>
            <div className={style.basketHeader}>
              <p>Panier</p>
              {basket.length > 0 && <p onClick={() => setBasket([])}>Vider le panier</p>}
            </div>
            <div className={style.basketBody}>
              {basket.map((basketItem) => (
                <div key={basketItem.application.id} className={style.basketItem}>
                  {basketItem.application.fileUri !== null ? (
                    <img src={basketItem.application.fileUri} alt={'Logo de ' + basketItem.application.name} />
                  ) : null}
                  <div className={style.basketItemTitle}>
                    <p>
                      {(basketItem.application as BasketAddon).quantity !== undefined && (basketItem.application as BasketAddon).quantity + ' x '}

                      {basketItem.application.name}
                    </p>
                    <p>{numberToPrice(basketItem.application.price || 0)}</p>
                  </div>

                  <div
                    className={style.basketItemSquare}
                    onClick={() => setBasket((before) => before.filter((bI) => bI.application.id !== basketItem.application.id))}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              ))}

              <div className={style.totalDiv}>
                <p>Total HT:</p>
                <p>
                  {numberToPrice(basket.map((basketItem) => basketItem.application.price || 0).reduce((previous, current) => previous + current, 0))}
                </p>
              </div>
              <Tooltip
                label={
                  user?.company?.vatVerified === vatVerifiedEnum.PENDING
                    ? 'Numéro de TVA en cours de validation, veuillez-patienter quelque minutes.'
                    : 'Numéro de TVA invalide, veuillez-le mettre à jour ou nous contacter.'
                }
                disabled={vatVerified}
              >
                <p
                  id="onboarding-discovercategories-next-5th"
                  className={classNames(
                    style.seeBasket,
                    (user?.company?.vatVerified === vatVerifiedEnum.PENDING || !vatVerified) && style.vatUnverified
                  )}
                  style={basket.length <= 0 || !user?.company?.numFiscal ? { cursor: 'not-allowed', opacity: 0.6 } : {}}
                  onClick={() => {
                    if (basket.length <= 0 || !user?.company?.numFiscal || user.company.vatVerified === vatVerifiedEnum.PENDING || !vatVerified)
                      return;
                    createCheckoutSession();
                  }}
                >
                  Voir le panier
                  {user?.company?.vatVerified === vatVerifiedEnum.PENDING ? (
                    <FontAwesomeIcon icon={faHourglassHalf} className={style.vatIcon} />
                  ) : !vatVerified ? (
                    <FontAwesomeIcon icon={faExclamationTriangle} className={style.vatIcon} />
                  ) : null}
                </p>
              </Tooltip>
            </div>
          </div>
        ) : null}
      </div>
    </>
  ) : null;
}
