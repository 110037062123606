import { useEffect } from 'react';
import { useQuery } from 'react-query';
import ChartComponent from '../../../components/ChartComponent';
import Utils from '../../../components/Utils';
import api from '../../../utils/api';
import { TITLES } from '../../../utils/constantes';
import { UserContextType, useUser } from '../../../utils/context/User';

import style from './UserStats.module.css';

export default function UserStats() {
  const [user] = useUser() as UserContextType;

  const { data: stats, isLoading } = useQuery('companyStats', api.companies.getCompanyStats, {
    enabled: user?.company !== null,
  });

  useEffect(() => {
    document.title = TITLES.STATISTICS;
  }, []);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Statistiques utilisateurs" width="100%" isLoading={isLoading}>
        <>
          <Utils.ImportantNumber number={stats?.userCount || 0} word="Utilisateur" style={{ marginTop: '10px', marginBottom: '10px' }} />

          <div className={style.chartContainer}>
            <ChartComponent
              nbUser={stats?.userCount || 0}
              nbUserActive={parseInt(stats?.activeUserCount || '0')}
              title="Nombre d'utilisateurs actifs"
            />

            <ChartComponent
              nbUser={stats?.userCount || 0}
              nbUserActive={parseInt(stats?.activeLastWeek || '0')}
              title="Nombre d'utilisateurs actifs cette semaine"
            />
          </div>
        </>
      </Utils.Card>
    </Utils.Container>
  );
}
