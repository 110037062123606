import React from 'react';

import { useNavigate, useLocation } from 'react-router';

import { ROLE } from '../utils/api/_type';
import { HasCheckLoginContextType, useHasCheckLogin } from '../utils/context/HasCheckLogin';
import { UserContextType, useUser } from '../utils/context/User';

interface Props {
  children: any;
  role?: ROLE;
  roles?: ROLE[];
  needCompany?: boolean;
}

export default function NeedLogin({ children, role, roles, needCompany = false }: Props): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [user] = useUser() as UserContextType;
  const [hasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  if (hasCheckLogin) {
    if (user !== undefined) {
      if (needCompany && user.company === null) {
        navigate('/company/create');
        return <></>;
      }

      if (role === undefined && roles === undefined) {
        return children;
      } else {
        if ((role !== undefined && user.roles.includes(role)) || (roles !== undefined && roles.some((r) => user.roles.includes(r)))) {
          return children;
        } else {
          navigate('/');
          return <></>;
        }
      }
    } else {
      navigate('/login#redirect=' + location.pathname);
      return <React.Fragment></React.Fragment>;
    }
  } else {
    return <React.Fragment></React.Fragment>;
  }
}
