import { checkStatus, host } from '../api';
import { ScreenLicence, StorageExtension } from './_type';

const addons = {
  getStorages: (): Promise<StorageExtension[]> => {
    return fetch(`${host}/api/addons/storage-extensions`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getScreenLicences: (): Promise<ScreenLicence[]> => {
    return fetch(`${host}/api/addons/screen-licences`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default addons;
