import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import styleClass from './Card.module.css';

interface Props {
  children: React.ReactNode;
  title?: string;
  width?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

export default function Card({ children, title, width, isLoading, style }: Props) {
  return (
    <div className={styleClass.card} style={{ width: width, ...style }}>
      {isLoading ? (
        <div className={styleClass.loaderContainer}>
          <FontAwesomeIcon className={styleClass.icon} icon={faCircleNotch} />
        </div>
      ) : null}
      {title !== undefined ? (
        <div className={styleClass.titleContainer}>
          <h1 className={styleClass.title}>{title}</h1>
        </div>
      ) : null}
      {children}
    </div>
  );
}
