import classNames from 'classnames';
import React, { useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';

import style from './PopOver.module.css';

interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function PopOver(props: Props) {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  return (
    <div
      className={classNames(props.className, style.popOver)}
      onMouseEnter={() => setIsPopOverOpen(true)}
      onMouseLeave={() => setIsPopOverOpen(false)}
    >
      {isPopOverOpen ? (
        <div className={style.popOverContent} style={props.style}>
          {props.content}
        </div>
      ) : null}
      {props.children}
    </div>
  );
}
