import React, { useRef, useState } from 'react';
import * as Mantine from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import Winylo, { WINYLO_THEME } from '..';

interface IFileButtonProps extends Omit<PolymorphicComponentProps<'button', Mantine.FileButtonProps>, 'children' | 'multiple'> {
  fileBtnRef?: React.ForwardedRef<HTMLButtonElement>;
  color?: Mantine.DefaultMantineColor | 'custom';
  format?: 'round' | 'square';
  customColor?: Record<'custom', Mantine.Tuple<string, 10>>;
  textStyles?: React.CSSProperties;
  loading?: boolean;
  loaderPosition?: 'left' | 'right' | 'center';
  loaderProps?: Mantine.LoaderProps;
  text?: string;
  multiple?: boolean;
}

export default function FileButton({
  fileBtnRef,
  color = 'blue',
  format = 'round',
  customColor,
  textStyles,
  loading,
  loaderPosition,
  loaderProps,
  text,
  multiple,
  ...props
}: IFileButtonProps) {
  return (
    <Mantine.Group position="center">
      <Mantine.FileButton multiple={multiple} {...props}>
        {(btnProps) => (
          <Winylo.Button
            color={color}
            customColor={customColor}
            textStyles={textStyles}
            radius={format === 'round' ? '2.5rem' : '0.25rem'}
            loading={loading}
            loaderPosition={loaderPosition}
            loaderProps={loaderProps}
            {...btnProps}
          >
            {text}
          </Winylo.Button>
        )}
      </Mantine.FileButton>
    </Mantine.Group>
  );
}
