import React from "react";

import style from "../../css/old/Breadcrumb.module.css";

export interface ItemProps {
    children?: string;
    active?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function Item(props: ItemProps) {
    return (
        <span
            className={props.active ? style.active : ""}
            onClick={props.onClick}
        >
            {props.children}
        </span>
    );
}

export interface ContainerProps {
    children: React.ReactElement | React.ReactElement[];
}

export const Breadcrumb = ({ children }: ContainerProps) => {
    function getChildren(): React.ReactElement[] {
        return Array.isArray(children) ? children : [children];
    }

    return (
        <div className={style.container}>
            {getChildren().map((child) => {
                if (child?.type === Item) {
                    return (
                        <>
                            <div className={style.item}>{child}</div>
                            <div className={style.separator}></div>
                        </>
                    );
                }

                return null;
            })}
        </div>
    );
};

export default Object.assign(Breadcrumb, {
    Item: Item,
});
