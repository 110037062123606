import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import style from './Spinner.module.css';

interface Props {
  className?: string;
  variant?: 'primary' | 'secondary';
  center?: boolean;
  size?: string;
}

export default function Spinner({
  variant = 'primary',
  className,
  center,
  size,
}: Props) {
  return (
    <FontAwesomeIcon
      className={classNames(style.icon, [style[variant]], className, {
        [style.center]: center,
      })}
      style={{ fontSize: size }}
      icon={faCircleNotch}
    />
  );
}
