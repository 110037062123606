import { useState } from 'react';
import { stringToSize } from '../../../utils/utils';
import Utils from '../../../components/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import StyledSelect from '../../../components/Utils/StyledSelect/StyledSelect';
import { CSVOption, SubmitModalParams } from '../../../utils/api/_type';
import classNames from 'classnames';
import papaparse from 'papaparse';
import CSVItem from './CSVItem';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../utils/api';
import StatusModal from '../../../components/Utils/StatusModal/StatusModal';
import BugsModal from '../../../components/BugsModal/BugsModal';
import { getErrorSubmitType, getSuccessSubmitType } from '../utils/submitModalTypes';

import sharedStyle from '../css/Shared.module.css';
import style from '../css/CSVTab.module.css';

interface CSVTabProps {}

const selectValues: CSVOption[] = [
  { label: 'Prénom', value: 'firstname', required: true },
  { label: 'Nom', value: 'lastname', required: true },
  { label: 'Email', value: 'email', required: true },
  { label: "Nom d'utilisateur", value: 'username' },
  { label: 'Mot de passe', value: 'password' },
];

const successSubmitType: SubmitModalParams = getSuccessSubmitType({
  title: 'Utilisateurs créés',
  text: 'Les utiliateurs ont été créés avec succès !',
});

const errorSubmitType = (
  setState: (value: React.SetStateAction<SubmitModalParams>) => void,
  setIsOpen: (value: React.SetStateAction<boolean>) => void
) =>
  getErrorSubmitType({
    title: 'Problème dans la création',
    text: 'Il y a eu un problème lors de la création de vos utilisateurs ! Vous pouvez vérifier la conformité de votre CSV et réessayer.',
    setState: setState,
    setIsOpen: setIsOpen,
  });

const initialSubmitModal: SubmitModalParams = { title: '', content: <></>, checked: false, isOpen: false };

export default function CSVTab(props: CSVTabProps) {
  const [csvParsed, setCsvParsed] = useState<string[][]>([]);
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [ignoreFirstRow, setIgnoreFirstRow] = useState<boolean>(false);
  const [csvError, setCsvError] = useState<boolean>(false);
  const [selectedCSVOptions, setSelectedCSVOptions] = useState<Map<number, CSVOption>>(new Map());
  const [modalSubmitType, setModalSubmitType] = useState<SubmitModalParams>(initialSubmitModal);
  const [isBugOpen, setIsBugOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { mutate: createUserFromCSV, isLoading: isCreating } = useMutation(api.companies.importUsers, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('users_company');
      setModalSubmitType(successSubmitType);
    },
    onError: (error) => {
      queryClient.invalidateQueries('users_company');
      setModalSubmitType(errorSubmitType(setModalSubmitType, setIsBugOpen));
    },
  });

  /**
   * Ouvre la sélection de fichier et le place dans un state
   * Parse le CSV en tableau de string[][]
   */
  function onOpenFileChooser() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.setAttribute('style', 'display: none');

    input.addEventListener('change', handleChangeFile);

    document.body.appendChild(input);

    input.click();
    document.body.removeChild(input);

    /**
     * Evenement de l'input temporaire caché qui permet d'ouvrir le sélecteur de fichiers
     *
     * @param ev event de l'input
     */
    function handleChangeFile(ev: Event) {
      let files = (ev.target as HTMLInputElement).files;

      if (files !== null && files.length !== 0) {
        const file = files[0];

        papaparse.parse<string[]>(file, {
          complete: function (results) {
            if (results.data.some((row) => row.length > 5)) {
              setCsvParsed([]);
              setCsvFile(undefined);
              setCsvError(true);
              return;
            }

            setCsvParsed(results.data);
            setCsvFile(file);
            setCsvError(false);
          },
        });
      }

      input.removeEventListener('change', handleChangeFile);
    }
  }

  /**
   * Actualise la valeur du select et adapte la liste des valeurs possibles
   *
   * @param e nouvelle valeur du select
   * @param key index de la colonne (0 à 4)
   */
  function handleHeaderSelectChange(e: any, key: number) {
    if (selectedCSVOptions.has(key)) {
      selectedCSVOptions.delete(key);
    }

    setSelectedCSVOptions((old) => new Map(old.set(key, e as CSVOption)));
  }

  /**
   * Récupère la liste des options possibles pour le select
   *
   * @param key index de la colonne (0 à 4)
   * @returns options restantes
   */
  function getAvailableOptions(key: number): CSVOption[] {
    const values = selectedCSVOptions.size > 0 ? Array.from(selectedCSVOptions.values()) : [];
    return selectValues.filter((v) => values && !values.some((t) => t && t.value === v.value));
  }

  /**
   * Config les colonnes et envoie le csv au back pour la création
   */
  function handleCSVSubmit() {
    let association = { hasHeader: ignoreFirstRow };

    for (const [key, value] of selectedCSVOptions) {
      if (!!value) {
        association = { ...association, [value.value]: key };
      }
    }

    createUserFromCSV({ format: JSON.stringify(association), file: csvFile });
  }

  /**
   * Check si le csv est valide
   *
   * @returns true si oui, false sinon
   */
  function isCsvValid() {
    let res = true;

    if (selectValues.length === selectedCSVOptions.size) return true;

    const optionsValues = Array.from(selectedCSVOptions.values());

    selectValues.some((sv) => {
      if (sv.required && !optionsValues.some((t) => t && t.value === sv.value)) {
        res = false;
        return true;
      }
    });

    return res;
  }

  return (
    <div className={classNames(sharedStyle.container, 'onboarding-createuser-6th')}>
      <div className={style.topElements}>
        <div className={style.uploadButton}>
          <Utils.Button isLoading={isCreating} format="square" fullWidth={false} onClick={onOpenFileChooser}>
            <FontAwesomeIcon icon={faUpload} /> Choisir un fichier
          </Utils.Button>
          {csvFile && (
            <span style={{ marginLeft: '0.5rem' }}>
              {csvFile.name} ({stringToSize(csvFile.size.toString())})
            </span>
          )}
          {csvError && <span className={sharedStyle.errorText}>Il y a une erreur dans le format de votre fichier.</span>}
        </div>
        <Utils.Button
          isLoading={isCreating}
          fullWidth={false}
          variant={isCsvValid() ? 'green' : 'gray'}
          onClick={() => isCsvValid() && handleCSVSubmit()}
        >
          <FontAwesomeIcon icon={faCheck} /> Créer les utilisateurs
        </Utils.Button>
      </div>
      <div className={style.botElements}>
        <label className={sharedStyle.importantText}>Sélectionnez les entêtes de chaque colonnes</label>
        <Utils.Checkbox
          label="Ignorer la première ligne du CSV"
          checked={ignoreFirstRow}
          onChange={(e: any) => setIgnoreFirstRow(e.target.checked)}
          type="checkbox"
        />
        <div className={classNames(sharedStyle.gridRow, style.gridRow)}>
          {selectValues.map((v, i) => {
            return (
              <StyledSelect
                value={selectedCSVOptions.get(i)}
                options={getAvailableOptions(i)}
                onChange={(e) => handleHeaderSelectChange(e, i)}
                className={sharedStyle.importantText}
                isClearable
              />
            );
          })}
        </div>
        {csvParsed.map((row, i) => {
          if (i <= 0 && ignoreFirstRow) return;
          return (
            <>
              <Utils.Divider variant="gray" />
              <CSVItem row={row} />
            </>
          );
        })}
      </div>

      <StatusModal
        title={modalSubmitType.title}
        content={modalSubmitType.content}
        check={modalSubmitType.checked}
        isOpen={modalSubmitType.isOpen}
        onClose={() => {
          setModalSubmitType(initialSubmitModal);
        }}
        errorCode={modalSubmitType.errorCode}
      />

      <BugsModal isOpen={isBugOpen} onClose={() => setIsBugOpen(false)} />
    </div>
  );
}
