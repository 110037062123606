import React from 'react';
import { BasketContextProvider } from './Basket';
import { HasCheckLoginContextProvider } from './HasCheckLogin';
import { UserContextProvider } from './User';

export default function ContextProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <HasCheckLoginContextProvider>
      <UserContextProvider>
        <BasketContextProvider>{children}</BasketContextProvider>
      </UserContextProvider>
    </HasCheckLoginContextProvider>
  );
}
